import { Box, CardContent, Grid, Typography, TextField, FormControl, RadioGroup, Radio, FormControlLabel, Divider } from '@mui/material';
import { gridSpacing } from 'config.js';
import { formatDate } from 'utils/FormatUtils';
const AircraftPolicyDetails = ({ row }) => {
  return (
    <Box margin={3}>
      <CardContent>
        <Grid container spacing={gridSpacing}>
          <Grid item sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="div" variant="h5">
                  Aircraft Information
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component="div" variant="subtitle1">
                  Registration Number: &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="registrationNo"
                      value={row?.AircraftDetails?.registrationNo || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
                <Typography component="div" variant="subtitle1" sx={{ mt: 1 }}>
                  Hangered : &nbsp;
                </Typography>
                <Typography component="span" variant="subtitle2">
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="gender" name="hangered" value={row?.AircraftDetails?.hangered || false}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <FormControlLabel value={true} control={<Radio color="default" />} label="Yes" />
                        </Grid>
                        <Grid item>
                          <FormControlLabel value={false} control={<Radio color="default" />} label="No" />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component="div" variant="subtitle1">
                  Seats : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      type="number"
                      name="seats"
                      value={row?.AircraftDetails?.seats || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
                <Typography component="div" variant="subtitle1" sx={{ mt: 1 }}>
                  Leased : &nbsp;
                </Typography>
                <Typography component="span" variant="subtitle2">
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="gender" name="isLeased" value={row?.AircraftDetails?.isLeased || false}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <FormControlLabel value={true} control={<Radio color="default" />} label="Yes" />
                        </Grid>
                        <Grid item>
                          <FormControlLabel value={false} control={<Radio color="default" />} label="No" />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} sx={{ mt: 4.3 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography component="div" variant="subtitle1">
                  Type : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="type"
                      value={row?.AircraftDetails?.type || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
                <Typography component="div" variant="subtitle1" sx={{ mt: 1 }}>
                  Value : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      type="number"
                      name="value"
                      value={row?.AircraftDetails?.value || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component="div" variant="subtitile1">
                  Year : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      type="text"
                      name="Year"
                      value={row?.AircraftDetails?.year || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
                <Typography component="div" variant="subtitile1" sx={{ mt: 1 }}>
                  Make :
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="make"
                      value={row?.AircraftDetails?.make || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="div" variant="h5">
                  Airport Information
                </Typography>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Typography component="div" variant="subtitle1">
                  Airport Code : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="airportCode"
                      value={row?.AircraftDetails?.airportCode || ''}
                      InputProps={{
                        readOnly: true
                      }}
                      variant="outlined"
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Typography component="div" variant="subtitle1">
                  Airport Name : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="airportName"
                      value={row?.AircraftDetails?.airportName || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Typography component="div" variant="subtitle1">
                  Airport Type : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="airportType"
                      value={row?.AircraftDetails?.airportType || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item sm={3} xs={12}>
                <Typography component="div" variant="subtitle1">
                  Country Name : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="countryName"
                      value={row?.AircraftDetails?.countryName || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item sm={3} xs={12}>
                <Typography component="div" variant="subtitle1">
                  State Code : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="stateCode"
                      value={row?.AircraftDetails?.stateCode || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item sm={3} xs={12}>
                <Typography component="div" variant="subtitle1">
                  City Name : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      value={row?.AircraftDetails?.cityName || ''}
                      name="cityName"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item sm={3} xs={12}>
                <Typography component="div" variant="subtitle1">
                  County Name : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      value={row?.AircraftDetails?.county || ''}
                      name="county"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography component="div" variant="subtitle1">
                  Longitude : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      value={row?.AircraftDetails?.longitude || ''}
                      name="longitude"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography component="div" variant="subtitle1">
                  Latitude : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      value={row?.AircraftDetails?.latitude || ''}
                      name="latitude"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Typography component="div" variant="subtitle1">
                  Manager Name : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      value={row?.AircraftDetails?.airportManager || ''}
                      name="airportManager"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Typography component="div" variant="subtitle1">
                  Manager Phone : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      value={row?.AircraftDetails?.airportManagerPhone || ''}
                      name="airportManagerPhone"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Typography component="div" variant="subtitle1">
                  Effective Date : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="effectiveDate"
                      value={formatDate(row?.AircraftDetails?.effectiveDate) || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="div" variant="h5">
                  Registered Information
                </Typography>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Typography component="div" variant="subtitile1" sx={{ mt: 1 }}>
                  Registered Owner : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="registeredOwner"
                      value={row?.AircraftDetails?.registeredOwner || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Typography component="div" variant="subtitile1" sx={{ mt: 1 }}>
                  Registered Email : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="registeredEmail"
                      value={row?.AircraftDetails?.registeredEmail || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Typography component="div" variant="subtitile1" sx={{ mt: 1 }}>
                  Registered Phone : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="registeredPhone"
                      value={row?.AircraftDetails?.registeredPhone || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component="div" variant="subtitile1" sx={{ mt: 1 }}>
                  Registered Address 1 : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="registeredAddress1"
                      value={row?.AircraftDetails?.registeredAddress1 || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component="div" variant="subtitile1" sx={{ mt: 1 }}>
                  Registered Address 2 : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="registeredAddress2"
                      value={row?.AircraftDetails?.registeredAddress2 || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography component="div" variant="subtitile1" sx={{ mt: 1 }}>
                  Registered City : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="registeredCity"
                      value={row?.AircraftDetails?.registeredCity || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography component="div" variant="subtitile1" sx={{ mt: 1 }}>
                  Registered State: &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="registeredState"
                      value={row?.AircraftDetails?.registeredState || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography component="div" variant="subtitile1" sx={{ mt: 1 }}>
                  Registered Zipcode: &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="registeredZip"
                      value={row?.AircraftDetails?.registeredZip || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  );
};

export default AircraftPolicyDetails;
