import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import initializeAxios from 'utils/axios';

// material-ui
import { Grid, Typography, IconButton, Tooltip } from '@mui/material';
import useAuth from 'hooks/useAuth';
import CustomTable from 'component/CustomTable';
// project import
import Breadcrumb from 'component/Breadcrumb';
import { gridSpacing } from 'config.js';

// assets
import DeleteIcon from '@mui/icons-material/Delete';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import AircraftDialog from 'component/AircraftDialog';
import LoadingOverlay from 'component/LoadingOverlay';

// ==============================|| CONTACT LIST ||============================== //

const Aircrafts = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const axios = initializeAxios(auth);

  const columns = [
    {
      id: 'registrationNo',
      label: 'Registration Number'
    },
    {
      id: 'airportCode',
      label: 'Airport Code'
    },
    {
      id: 'customerOrganization',
      label: 'Customer Organization'
    },
    {
      id: 'registeredOwner',
      label: 'Registered Owner'
    }
  ];

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    count: 0,
    page: 0,
    rowsPerPage: 25
  });
  const [customerOrg, setCustomerOrg] = useState([]);
  const [actionType, setActionType] = useState('');
  const [aircrafts, setAircrafts] = useState([]);
  const [aircraftId, setAircraftId] = useState(null);
  const { companyId } = useParams();
  const [aircraft, setAircraft] = useState({});
  const handleClickOpendialog = () => {
    setActionType('Create');
    setOpen(true);
  };
  const handleClosedialog = () => {
    setOpen(false);
    setAircraft({});
  };

  const handleChangePage = async (event, page) => {
    setPagination({ ...pagination, page });
  };

  const handleSave = async (aircraftDialog) => {
    switch (actionType) {
      case 'Create': {
        const response = await axios.post(`/api/aircraft/companyId/${companyId}`, {
          ...aircraftDialog,
          detailsFlag: false
        });
        handleGoToDetail(response.data);

        break;
      }

      case 'Edit': {
        const response = await axios.put(`/api/aircraft/${aircraftId}/companyId/${companyId}`, {
          ...aircraftDialog,
          detailsFlag: false
        });
        const tempArray = [...aircrafts];
        const index = aircrafts.findIndex((e) => e.id === aircraftId);
        tempArray[index] = {
          ...response.data,
          pilotAircraft: response.data.PilotAircraft
        };
        setAircrafts(tempArray);

        break;
      }

      case 'Delete': {
        await axios.delete(`/api/aircraft/${aircraftId}/companyId/${companyId}`);
        setPagination({ ...pagination, page: 0, count: pagination.count - 1 });
        const tempArray = [...aircrafts];
        const index = aircrafts.findIndex((e) => e.id === aircraftId);
        tempArray.splice(index, 1);
        setAircrafts(tempArray);
        handleChangePage(null, 0);
        break;
      }
    }
    handleClosedialog();
  };

  const handleEdit = (editAircraft) => {
    const newAircraft = {
      registrationNo: editAircraft.registrationNo,
      hangered: editAircraft.hangered,
      make: editAircraft.make,
      seats: +editAircraft.seats,
      type: editAircraft.type,
      value: +editAircraft.value,
      year: +editAircraft.year,
      airportCode: editAircraft.airportCode,
      idAgent: editAircraft.idAgent,
      idAgency: editAircraft?.idAgency,
      idCustomerOrganization: editAircraft.idCustomerOrganization,
      registeredOwner: editAircraft.registeredOwner,
      registeredEmail: editAircraft.registeredEmail,
      registeredPhone: editAircraft.registeredPhone,
      registeredAddress1: editAircraft.registeredAddress1,
      registeredAddress2: editAircraft.registeredAddress2 || '',
      registeredCity: editAircraft.registeredCity,
      registeredState: editAircraft.registeredState,
      registeredZip: editAircraft.registeredZip,
      pilotAircraft: editAircraft?.PilotAircraft?.map((pilot) => pilot.Pilot.id)
    };
    setAircraft(newAircraft);
    setActionType('Edit');
    setAircraftId(editAircraft.id);
    setOpen(true);
  };

  const handleDelete = (deleteAircraft) => {
    setAircraftId(deleteAircraft.id);
    setActionType('Delete');
    setOpen(true);
  };

  const handleChangeRowsPerChange = (event) => {
    setPagination({ ...pagination, rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  const handleGoToDetail = (aircraftDetails) => {
    navigate(`/company/${companyId}/aircrafts/${aircraftDetails.id}`, {
      state: { customerOrg }
    });
  };

  useEffect(() => {
    const getAircrafts = async () => {
      setIsLoading(true);
      const result = await axios.get(`/api/aircraft/companyId/${companyId}`, {
        params: { page: pagination.page, rowsPerPage: pagination.rowsPerPage }
      });
      setAircrafts(result.data.aircrafts);
      setPagination({ ...pagination, count: result.data.count });
      setIsLoading(false);
    };

    getAircrafts();
  }, [companyId, pagination.rowsPerPage, pagination.page]);

  useEffect(() => {

    const getCustomerOrgs = async () => {
      setIsLoading(true);
      const result = await axios.get(`/api/customer-org/companyId/${companyId}`);
      setCustomerOrg(result.data);
      setIsLoading(false);
    };

    getCustomerOrgs();
  }, [companyId]);

  return (
    <>
      <Breadcrumb title="Aircrafts" sx={{ mt: { lg: 0, xs: 2 } }}>
        <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Aircrafts
        </Typography>
      </Breadcrumb>
      <Grid container wrap="nowrap" justifyContent="flex-end" sx={{ mb: 3 }} spacing={gridSpacing}>
        <Grid item>
          <AircraftDialog
            open={open}
            handleClosedialog={handleClosedialog}
            aircraft={aircraft}
            handleAircraftSave={handleSave}
            customerOrg={customerOrg}
            actionType={actionType}
          />
        </Grid>
      </Grid>
      <LoadingOverlay loading={isLoading} />
      <CustomTable
        title="Aircrafts"
        isLoading={isLoading}
        data={aircrafts}
        columns={columns}
        pagination={pagination}
        hasPagination={true}
        hasCreateButton={true}
        handleOpenCreate={handleClickOpendialog}
        handleChangePage={handleChangePage}
        handleChangeRowsPerChange={handleChangeRowsPerChange}
        isDisabled={!auth.writePrivledge(auth.user, 'Aircraft_Adm')}
      >
        {(row, index) => (
          <>
            <Tooltip title="Edit Aircraft" placement="top">
              <span>
                <IconButton
                  color="primary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Aircraft_Adm')}
                  onClick={() => handleEdit(aircrafts[index])}
                >
                  <EditTwoToneIcon fontSize='large'/>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete Aircraft" placement="top">
              <span>
                <IconButton
                  color="secondary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Aircraft_Adm')}
                  onClick={() => handleDelete(aircrafts[index])}
                >
                  <DeleteIcon fontSize='large'/>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Aircraft Details">
              <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleGoToDetail(aircrafts[index])}>
                <TextSnippetIcon fontSize='large'/>
              </IconButton>
            </Tooltip>
          </>
        )}
      </CustomTable>
    </>
  );
};

export default Aircrafts;
