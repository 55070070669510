import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { List, Typography } from '@mui/material';

// project import
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import useAuth from 'hooks/useAuth';

// ==============================|| NAVGROUP ||============================== //

const NavGroup = ({ item, drawerToggle }) => {
  const { user, writePrivledge } = useAuth();
  const theme = useTheme();
  let items;
  if (user) {
    const companyId = user?.idCompany || user?.user?.idCompany;
    items = item.children.map((menu) => {
      switch (menu.type) {
        case 'group':
          return <NavGroup key={menu.id} item={menu} />;
        case 'collapse':
          return <NavCollapse key={menu.id} menu={menu} level={1} />;
        case 'item':
          return <NavItem key={menu.id} item={menu} level={1} />;
        case 'company-users-admin': {
          menu.url = `/company/${companyId}/users`;
          return <NavItem key={menu.id} item={menu} level={1} drawerToggle={drawerToggle} />;
        }
        case 'company-users-super': {
          if (writePrivledge(user, 'SuperAdmin')) {
            menu.url = `/admin/company/${companyId}/users`;
            return <NavItem key={menu.id} item={menu} level={1} drawerToggle={drawerToggle} />;
          } else {
            break;
          }
        }
        case 'company-super': {
          if (writePrivledge(user, 'SuperAdmin')) {
            return <NavItem key={menu.id} item={menu} level={1} drawerToggle={drawerToggle} />;
          } else {
            break;
          }
        }
        case 'company-admin': {
          return <NavItem key={menu.id} item={menu} level={1} drawerToggle={drawerToggle} />;
        }

        case 'aircraftQuote': {
          menu.url = `/company/${companyId}/workflow/aircraft-quote`;
          if (writePrivledge(user, 'Quote')) {
            return <NavItem key={menu.id} item={menu} level={1} drawerToggle={drawerToggle} />;
          } else {
            break;
          }
        }

        case 'airportQuote': {
          menu.url = `/company/${companyId}/workflow/airport-quote`;
          if (writePrivledge(user, 'Quote')) {
            return <NavItem key={menu.id} item={menu} level={1} drawerToggle={drawerToggle} />;
          } else {
            break;
          }
        }

        case 'cust-org-admin': {
          menu.url = `/company/${companyId}/customer-org`;
          return <NavItem key={menu.id} item={menu} level={1} drawerToggle={drawerToggle} />;
        }

        case 'pilot': {
          menu.url = `/company/${companyId}/pilots`;
          return <NavItem key={menu.id} item={menu} level={1} drawerToggle={drawerToggle} />;
        }
        case 'aircraft': {
          menu.url = `/company/${companyId}/aircrafts`;
          return <NavItem key={menu.id} item={menu} level={1} drawerToggle={drawerToggle} />;
        }

        case 'policy': {
          menu.url = `/company/${companyId}/policy`;
          return <NavItem key={menu.id} item={menu} level={1} drawerToggle={drawerToggle} />;
        }
        case 'airport': {
          menu.url = `/company/${companyId}/airports`;
          return <NavItem key={menu.id} item={menu} level={1} drawerToggle={drawerToggle} />;
        }
        case 'insurance': {
          menu.url = `/company/${companyId}/insurance`;
          return <NavItem key={menu.id} item={menu} level={1} drawerToggle={drawerToggle} />;
        }
        case 'policyOption': {
          menu.url = `/company/${companyId}/policyOptionAircraft`;
          return <NavItem key={menu.id} item={menu} level={1} drawerToggle={drawerToggle} />;
        }

        case 'leinholder': {
          menu.url = `/company/${companyId}/lienholder`;
          return <NavItem key={menu.id} item={menu} level={1} drawerToggle={drawerToggle} />;
        }
        case 'agency': {
          menu.url = `/company/${companyId}/agency`;
          return <NavItem key={menu.id} item={menu} level={1} drawerToggle={drawerToggle} />;
        }
        default:
          return (
            <Typography key={menu.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });
  }

  return (
    <List
      subheader={
        <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
          {item.title}
          {item.caption && (
            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
              {item.caption}
            </Typography>
          )}
        </Typography>
      }
    >
      {items}
    </List>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
  children: PropTypes.object,
  title: PropTypes.string,
  caption: PropTypes.string
};

export default NavGroup;
