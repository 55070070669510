import { SubjectHeader } from 'views/PDFComponents/SubjectHeader';

const { View, Page, Document, Text, StyleSheet, Font } = require('@react-pdf/renderer');
import { formatDate, displayPhoneNumber } from 'utils/FormatUtils';
// PDF styles

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  mainHeader: {
    fontSize: 20,
    marginBottom: 20,
    textAlign: 'center',
    fontWeight: 'bold'
  },
  divider: {
    borderTop: '3px solid black'
  },

  contentDivider: {
    borderTop: '1px solid black'
  },

  content: {
    padding: '16px 8px',
    fontSize: 12,
    gap: '10px'
  },

  infoHeader: {
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },

  areaCode: {
    marginLeft: '60px'
  },

  boldText: {
    marginTop: '4px',
    fontFamily: 'Open Sans',
    fontWeight: 'bold'
  },

  container: {
    display: 'flex',
    marginTop: '8px'
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center'
  },
  total: {
    marginTop: '8px',
    textAlign: 'center'
  },
  textBox: {
    width: '300px',
    height: '100px',
    border: '2px solid black',
    borderRadius: '10px',
    marginBottom: '16px'
  }
});

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
});

// Methods
const calculateTotal = (row) => {
  const calculate = [];
  for (let key in row) {
    if (
      key === 'hullDamage' ||
      key === 'bodilyInjury' ||
      key === 'propertyDamage' ||
      key === 'allocatedReserve' ||
      key === 'adjustmentExpense' ||
      key === 'recovery'
    ) {
      calculate.push(+row[key]);
    }
  }

  return calculate.reduce((total, next) => total + (next ? next : 0), 0);
};

const ClaimPDF = ({ claim, creationDate }) => {
  return (
    <Document>
      <Page size="letter" style={styles.body} wrap>
        <View>
          <Text style={[styles.mainHeader, styles.boldText]}>Claim of Date {formatDate(claim?.claimDate)}</Text>
          <View style={styles.infoHeader}>
            <Text>
              <Text style={styles.boldText}>Agent</Text> : &nbsp; {claim?.['UserProfile_Claim_idAgentToUserProfile']?.displayName}
            </Text>
            <Text>
              <Text style={styles.boldText}>Date of Creation</Text> : &nbsp; {formatDate(creationDate)}
            </Text>
          </View>
        </View>
        <View style={styles.container}>
          <SubjectHeader title="Claim Information" />
          <View style={styles.content}>
            <Text>
              <Text style={styles.boldText}>Aircraft Registration Number</Text> : &nbsp; {claim?.Aircraft?.registrationNo}
            </Text>
            <Text>
              <Text style={styles.boldText}>Pilot Full Name</Text> : &nbsp; {`${claim?.Pilot?.firstname} ${claim?.Pilot?.lastname}`}
            </Text>
            <Text>
              <Text style={styles.boldText}>Location</Text> : &nbsp; {claim?.location}
            </Text>
            <Text>
              <Text style={styles.boldText}>description</Text> : &nbsp; {claim?.description}
            </Text>
          </View>
          <SubjectHeader title="Customer Information" />
          <View style={styles.content}>
            <Text>
              <Text style={styles.boldText}>Customer Contact</Text> : &nbsp; {claim?.Aircraft?.registrationNo}
            </Text>
            <Text>
              <Text style={styles.boldText}>Customer Email</Text> : &nbsp; {claim?.email}
            </Text>
            <Text>
              <Text style={styles.boldText}>Customer Phone</Text> : &nbsp; {displayPhoneNumber(claim?.phone)}
            </Text>
            <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <Text>
                <Text style={styles.boldText}>Notes</Text> :{' '}
              </Text>
              <View style={styles.textBox}>
                <Text style={{ padding: '4px', fontSize: 11 }}>{claim?.notes}</Text>
              </View>
            </View>
          </View>
          <SubjectHeader title="Claim Details" />
          <View style={styles.content}>
            <View style={styles.details}>
              <Text>
                <Text style={styles.boldText}>Hull Damage</Text> : &nbsp; ${claim?.hullDamage || '0.00'}
              </Text>
              <Text>
                <Text style={styles.boldText}>Bodily Injury</Text> : &nbsp; ${claim?.bodilyInjury || '0.00'}
              </Text>
              <Text>
                <Text style={styles.boldText}>Property Damage</Text> : &nbsp; ${claim?.propertyDamage || '0.00'}
              </Text>
              <Text>
                <Text style={styles.boldText}>Allocated Reserve</Text> : &nbsp; ${claim?.allocatedReserve || '0.00'}
              </Text>
              <Text>
                <Text style={styles.boldText}>Adjustment Expense</Text> : &nbsp; ${claim?.adjustmentExpense || '0.00'}
              </Text>
              <Text>
                <Text style={styles.boldText}>Recovery</Text> : &nbsp; ${claim?.recovery || '0.00'}
              </Text>
            </View>
            <Text style={styles.divider} />
            <View style={styles.details}>
            <Text>
                <Text style={[styles.boldText, { fontSize: 16 }]}>Total : &nbsp; ${calculateTotal(claim).toFixed(2)}</Text>
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ClaimPDF;
