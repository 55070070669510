import { Backdrop, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';

const LoadingOverlay = ({ loading }) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);
  return (
    <Backdrop sx={{ color: 'black', zIndex: (theme) => theme.zIndex.fab }} open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoadingOverlay;
