import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { calculateTotalPolicyPremium, calculateTotalPremiumm, calculateTotalPropertyPremium, calculateTotal } from 'utils/MathFunctions';
// import { usePDF } from '@react-pdf/renderer';
// import PDFContent from '../PolicyPDF';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';
import CryptoJS from 'crypto-js';

// material-ui
import { styled } from '@mui/material/styles';
import {
  Grid,
  Typography,
  Button,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Box,
  Card,
  CardContent,
  TextField,
  Divider,
  Tabs,
  Tab,
  MenuItem,
  FormControl,
  Input,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
  Tooltip,
  Autocomplete,
  Collapse,
  InputAdornment,
  Hidden,
  Menu,
  InputLabel
} from '@mui/material';

// third party
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

// project import
import Breadcrumb from 'component/Breadcrumb';
import { gridSpacing } from 'config.js';
import CustomTable from 'component/CustomTable';

// assets
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import InsuranceIcon from '@mui/icons-material/GppGood';
import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingTwoTone';
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import Category from '@mui/icons-material/Category';
import Add from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import FinanceIcon from '@mui/icons-material/RequestQuote';
import { FileDownloadOutlined, FileUploadOutlined } from '@mui/icons-material';
import ReceiptTwoTone from '@mui/icons-material/ReceiptTwoTone';
import MenuIcon from '@mui/icons-material/Menu';

import LoadingOverlay from 'component/LoadingOverlay';
import AircraftDialog from 'component/AircraftDialog';
import PolicyOptions from '../PolicyOptions';
import PolicyClaims from '../PolicyClaims';
import AlertDialogSlide from 'views/Elements/Advance/UIDialog/AlertDialogSlide';
import PolicyInvoices from './Invoices';
import { formatDate } from 'utils/FormatUtils';
import { deactiveArrayUtil } from 'utils/deactiveArrayUtil';
import CompanyUserDetails from './CompanyUserDetails';
import PolicyDuplicateDetails from './PolicyDuplicateDetails';
import AirportPolicyDetails from './AirportPolicyDetails';
import AircraftPolicyDetails from './AircraftPolicyDetails';
import PilotPolicyDetails from './PilotPolicyDetails';
import LeinholderPolicyDetails from './LeinholderPolicyDetails';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import dayjs from 'dayjs';
// import { formatDate } from 'utils/FormatUtils';

// custom style

const FormControlCustom = styled((props) => <FormControl {...props} />)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 160,
  maxWidth: 500,
  width: '100%'
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const AccountTabs = styled((props) => <Tabs {...props} />)(() => ({
  marginBottom: '24px',
  borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
  '.MuiButtonBase-root': {
    minWidth: '120px',
    minHeight: 'auto',
    '.MuiTouchRipple-root': {
      flexDirection: 'row',
      '.MuiSvgIcon-root': {
        marginRight: '10px',
        marginBottom: '0 !important'
      }
    }
  }
}));

function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const columns = [
  {
    id: 'fileName',
    label: 'File Name'
  }
];

function CloneProps(props) {
  const { children, ...other } = props;
  return children(other);
}

// ==============================|| ORDER DETAILS ||============================== //

const PolicyDetails = () => {
  const componentRef = useRef();
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const navigate = useNavigate();
  const { companyId, policyId } = useParams();

  // Reactive States
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [policy, setPolicy] = useState({
    data: {},
    idPolicy: null,
    options: [],
    policyTypes: [],
    policyStatus: [],
    versions: [],
    versionIndex: null,
    editAircraftPolicy: {},
    editAircraftPolicyId: null
  });
  const [aircrafts, setAircrafts] = useState([]);

  const [users, setUsers] = useState([]);
  const [customerOrg, setCustomerOrg] = useState([]);

  const [insurances, setInsurances] = useState([]);
  const [customerContacts, setCustomerContacts] = useState([]);
  const [customerOrgId, setCustomerOrgId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState();
  const [documents, setDocuments] = useState([]);
  const [deletedDocument, setDeletedDocument] = useState({});
  const [agencies, setAgencies] = useState([]);
  const [agents, setAgents] = useState([]);
  const [agencyId, setAgencyId] = useState(null);
  const [airports, setAirports] = useState([]);
  const [relationDelete, setRelationDelete] = useState(false);
  const [deleteObject, setDeleteObject] = useState({ type: '', relation: {} });
  const [versionPolicyId, setVersionPolicyId] = useState(null);
  const [items, setItems] = useState([{ id: 0, idPilot: '', input1: '', input2: '' }]);
  const [pilotSelector, setPilotSelector] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [transitioning, setTransitioning] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  // const creationDate = formatDate(new Date()).format('MM/DD/YYYY');
  // console.log(creationDate);

  // const document = (
  //   <PDFContent
  //     policy={policy?.data}
  //     policyOptions={policy.options.filter((item) => item.accept)}
  //     aircrafts={aircrafts}
  //     //pilots={pilots}
  //     creationDate={creationDate}
  //   />
  // );
  // const [instance, updateInstance] = usePDF({ document });
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const [openAircraft, setOpenAircraft] = useState(false);
  const [open, setOpen] = useState(false);

  const [rowExpand, setRowExpand] = useState({ aircraftStates: [], pilotStates: [], otherStates: [] });

  const toggleExpand = (index, type) => {
    // Create a new array of expand states and toggle the state for the clicked row
    const newRowExpandStates = [...rowExpand[type]];
    newRowExpandStates[index] = !newRowExpandStates[index];
    setRowExpand({
      ...rowExpand,
      [type]: newRowExpandStates
    });
  };

  // Save Policy Details
  const handlePolicySave = async () => {
    setIsLoading(true);
    const pilotRequirementData =
      items?.length > 0
        ? CryptoJS.AES.encrypt(
            JSON.stringify(
              items.map((item) => ({
                idPilot: item?.idPilot,
                [item.input1
                  .toLowerCase() // Convert the entire string to lowercase
                  .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase())]: item.input2
              }))
            ),
            process.env.REACT_APP_SECRET_KEY
          ).toString()
        : '';
    let policyDialog = {
      policy: {
        idCustomerOrganization: policy.data.idCustomerOrganization,
        idAgent: policy.data.idAgent,
        idCompanyUser: policy.data?.idCompanyUser,
        idAgency: policy.data?.idAgency,
        clientNameInsured: policy.data.clientNameInsured,
        endorsements: policy.data?.endorsements || '',
        beginDate: policy.data?.beginDate || null,
        endDate: policy.data?.endDate || null,
        idPolicyType: policy.data?.idPolicyType,
        policyNumber: policy.data?.policyNumber || '',
        totalAnnualPremium: policy.data?.totalAnnualPremium,
        totalHullPremium: policy.data?.totalHullPremium,
        totalLiabilityPremium: policy.data?.totalLiabilityPremium,
        totalOtherPremium: policy.data?.totalOtherPremium,
        idPolicyStatus: policy.data?.idPolicyStatus,
        trainingRequired: policy.data?.trainingRequired,
        idParentPolicy: policy.data?.idParentPolicy || null,
        idInsurance: policy.data?.idInsurance || null,
        insuranceNotes: policy.data?.insuranceNotes || '',
        idCustomerContact: policy.data?.idCustomerContact || null,
        pilotRequirementDescription: policy?.data?.pilotRequirementDescription || '',
        pilotRequirementData
      }
    };

    if (policy.data?.idAirport !== -1 && policy.data?.idAirport) {
      policyDialog.policy = {
        ...policyDialog?.policy,
        idAirport: policy.data?.idAirport,
        airportHullAmount: policy.data?.airportHullAmount,
        airportHullCode: policy.data?.airportHullCode,
        airportLiabilityAmount: policy.data?.airportLiabilityAmount,
        airportLiabilityCode: policy.data?.airportLiabilityCode,
        airportMedicalAmount: policy.data?.airportMedicalAmount,
        airportMedicalCode: policy.data?.airportMedicalCode,
        airportTerritoryAmount: policy.data?.airportTerritoryAmount,
        airportTerritoryCode: policy.data?.airportTerritoryCode,
        airportUseAmount: policy.data?.airportUseAmount,
        airportUseCode: policy.data?.airportUseCode,
        airportWarTriaAmount: policy.data?.airportWarTriaAmount,
        airportWarTriaCode: policy.data?.airportWarTriaCode
      };
    } else {
      policyDialog.policy = {
        ...policyDialog?.policy,
        idAirport: null
      };
    }
    const response = await axios.put(`/api/policy/${policy.data.id}/companyId/${companyId}`, {
      ...policyDialog
    });
    setPolicy((prevPolicy) => ({ ...prevPolicy, data: { ...response.data } }));
    setIsLoading(false);
  };

  // Document Methods

  const handleFileSave = async (blob = null, flag = false) => {
    setIsLoading(true);
    const formData = new FormData();
    if (blob && flag) {
      const date = creationDate.toLocaleDateString().split('/');
      const blobFile = new File([blob], `policy-${policyId}-${date[0]}${date[1]}${date[2].substring(2)}.pdf`);
      formData.append('file', blobFile);
    } else {
      formData.append('file', file);
    }
    const result = await axios.post(`/api/policy/${policy.idPolicy}/file/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    formatDocuments(result.data);
    setIsLoading(false);
  };

  const handleFileDelete = async (deleteDocument) => {
    await axios.delete(`/api/policy/${policy.idPolicy}/file/${deleteDocument.id}`);
    const tempArray = [...documents];
    const index = tempArray.findIndex((e) => e.id === deleteDocument.id);
    tempArray.splice(index, 1);
    setDocuments(tempArray);
  };

  const handleDownload = async (downloadFile) => {
    const response = await axios.get(`/api/policy/${policy.idPolicy}/file/${downloadFile.id}/download`, {
      responseType: 'blob'
    });
    const link = window.document.createElement('a');
    link.href = URL.createObjectURL(response.data);
    link.download = downloadFile.fileName;
    link.click();
    link.remove();
  };

  // Formats Documents for table
  const formatDocuments = (array) => {
    if (Array.isArray(array)) {
      const tempArray = [];
      array.forEach((item) => {
        const obj = {
          id: item.Id,
          fileName: item.fileName
        };
        tempArray.push(obj);
      });
      setDocuments([...tempArray]);
    } else {
      const obj = {
        id: array.Id,
        fileName: array.fileName
      };
      setDocuments([...documents, obj]);
    }
  };

  // Aircraft Relation Methods

  const handleSaveAircraft = async (aircraftDialog) => {
    setIsLoading(true);
    if (policy.editAircraftPolicy?.actionType !== 'Edit') {
      const response = await axios.post(`/api/policy/${policy.idPolicy}/aircraft/companyId/${companyId}`, {
        ...aircraftDialog
      });
      setAircrafts([...aircrafts, response.data]);
    } else {
      const response = await axios.put(`/api/policy/${policy.idPolicy}/aircraft/${policy.editAircraftPolicyId}/companyId/${companyId}`, {
        ...aircraftDialog
      });
      const tempArray = [...aircrafts];
      const index = tempArray.findIndex((e) => e?.id === policy.editAircraftPolicyId);
      tempArray[index] = {
        ...response.data
      };
      setAircrafts(tempArray);
    }
    handleClosedialog();
    setIsLoading(false);
  };

  const handleDeleteAircraftRelation = async (aircraftRelation) => {
    setIsLoading(true);
    await axios.delete(`/api/policy/${policy.idPolicy}/aircraft/${aircraftRelation.id}/companyId/${companyId}`);
    const tempArray = [...aircrafts];
    const index = tempArray.findIndex((e) => e.id === aircraftRelation.id);
    tempArray.splice(index, 1);
    setAircrafts(tempArray);
    setIsLoading(false);
  };

  const handleAircraftEdit = (row) => {
    setPolicy({
      ...policy,
      editAircraftPolicy: {
        ...row,
        actionType: 'Edit'
      },
      editAircraftPolicyId: row.id
    });
    setOpenAircraft(true);
  };

  const handleDeleteRelationRoute = (relation) => {
    if (relation?.type === 'Aircraft') {
      handleDeleteAircraftRelation(relation.relation);
    } else {
      handleDeletePilotRelation(relation.relation);
    }
  };

  const handleDeleteRelationModal = (row, type) => {
    setDeleteObject({ type, relation: { ...row } });
    setRelationDelete(true);
  };

  // Emit and Update Policy Options
  const updatePolicyOption = (item, actionType) => {
    if (actionType !== 'Create') {
      setPolicy({
        ...policy,
        options: item
      });
    } else {
      setPolicy({
        ...policy,
        options: [...policy.options, item]
      });
    }
  };

  // Go to Detail Methods

  const handleGoToPilotDetail = (pilot) => {
    navigate(`/company/${companyId}/pilots/${pilot.PilotDetails.idPilot}`);
  };

  const handleGoToAircraftDetail = (aircraft) => {
    navigate(`/company/${companyId}/aircrafts/${aircraft.Aircraft.id}`);
  };

  // Handle Change Methods
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleBeginDateChange = (date) => {
    setPolicy({
      ...policy,
      data: {
        ...policy.data,
        beginDate: date !== null ? date : null
      }
    });
  };

  const handleEndDateChange = (date) => {
    setPolicy({
      ...policy,
      data: {
        ...policy.data,
        endDate: date !== '' ? date : null
      }
    });
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    let clientNameInsured = policy.data?.clientNameInsured;
    if (name === 'idCustomerOrganization') {
      setCustomerOrgId(value);
      if (!policy.data?.clientNameInsured) {
        clientNameInsured = customerOrg?.filter((item) => item.id === value)[0]?.name;
      }
      setPolicy({
        ...policy,
        data: {
          ...policy.data,
          clientNameInsured,
          idCustomerOrganization: value,
          idCustomerContact: ''
        }
      });
    } else if (name === 'idPolicyStatus' && (policy.data?.idPolicyStatus === 23 || policy.data?.idPolicyStatus === 24) && value === 22) {
      setPolicy({
        ...policy
      });
    } else if (name === 'idAgency') {
      setAgencyId(value);
      setPolicy({
        ...policy,
        data: {
          ...policy.data,
          idAgent: '',
          [name]: value
        }
      });
    } else {
      setPolicy({
        ...policy,
        data: {
          ...policy.data,
          [name]: name.includes('total') ? +value : name.includes('training') ? (value === 'true' ? true : false) : value
        }
      });
    }
  };

  const handleVersionChange = (event) => {
    setIsLoading(true);
    setTimeout(() => {
      const { value } = event.target;
      const foundPolicy = policy.versions.find((item) => item.id === value);
      if (foundPolicy) {
        setPolicy({
          ...policy,
          idPolicy: foundPolicy.id,
          data: foundPolicy,
          versionIndex: value
        });
        setDocuments(foundPolicy.PolicyDocument);
        setAircrafts(foundPolicy.PolicyAircraft);
      } else {
        setPolicy({
          ...policy
        });
      }
      setIsLoading(false);
    }, 2000);
  };

  const handleClickOpenAircraftDialog = () => {
    setOpenAircraft(true);
  };

  // Handle Document Modal Methods
  const handleCloseModal = () => {
    setOpen(false);
    setRelationDelete(false);
    setDeletedDocument({});
    setDeleteObject({ type: '', relation: {} });
  };

  const handleDeleteMiddleware = (row) => {
    setDeletedDocument(row);
    setOpen(true);
  };

  // Closed Dialog Method
  const handleClosedialog = () => {
    // setOpenPilot(false);
    setOpenAircraft(false);
    setPolicy({
      ...policy,
      editAircraftPolicy: {}
    });
    //setEditPilotPolicy({});
  };

  // handle Menu Dialogs
  const handleDrawerOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDrawerClose = () => {
    setAnchorEl(null);
  };

  const handleMenuChange = (value) => {
    setValue(value);
    setAnchorEl(null);
  };

  const setRequirementPilots = (aircrafts) => {
    setPilotSelector([
      ...new Set(
        aircrafts
          ?.map((item) => item?.AircraftPilotDetails)
          .flat(1)
          .map((item) => item.PilotDetails)
      )
    ]);
  }

  const handleRefresh = async () => {
    setIsLoading(true);
    const response = await axios.put(`/api/policy/${versionPolicyId}/companyId/${companyId}/refresh`, {
      data: {
        idCustomerOrganization: policy.data.idCustomerOrganization,
        idCustomerContact: policy.data.idCustomerContact,
        idAgent: policy.data.idAgent,
        idAgency: policy.data.idAgency,
        idInsurance: policy.data.idInsurance,
        idCompanyUser: policy.data.idCompanyUser,
        idAirport: policy.data?.idPolicyType === 28 ? policy.data?.idAirport : null,
        aircrafts:
          policy.data?.idPolicyType !== 28 ? aircrafts?.map((item) => ({ idPolicyAircraft: item.id, idAircraft: item.idAircraft })) : [],
        pilots:
          policy.data?.idPolicyType !== 28
            ? aircrafts.map((item) => ({
                idPolicyAircraft: item.id,
                pilots: item?.Aircraft?.PilotAircraft?.map((pilot) => pilot.idPilot)
              }))
            : [],
        otherInterests:
          policy.data?.idPolicyType !== 28
            ? aircrafts.map((item) => ({
                idPolicyAircraft: item.id,
                interests: item?.Aircraft?.AircraftLienHolder?.map((other) => other.idLienHolder)
              }))
            : []
      }
    });
    setPolicy({
      ...policy,
      data: {
        ...policy.data,
        ...response.data
      }
    });
    setAircrafts([...response.data.PolicyAircraft]);
    setRequirementPilots(response?.data?.PolicyAircraft);
    setIsLoading(false);
  };

  const bindPolicy = async () => {
    setIsLoading(true);
    const response = await axios.put(`/api/policy/${policy.idPolicy}/idImmutable/${policy.data.idImmutable}/companyId/${companyId}/bind`, {
      policy: {
        idPolicyStatus: 23
      }
    });
    const tempArray = [...policy.versions];
    const index = tempArray.findIndex((item) => item?.id === response.data?.id);
    tempArray[index] = {
      ...response.data
    };
    setPolicy({
      ...policy,
      data: {
        ...policy.data,
        ...response.data
      },
      versions: tempArray
    });
    setIsLoading(false);
  };

  const modifyPolicy = async () => {
    setIsLoading(true);
    const response = await axios.post(`/api/policy/${policyId}/companyId/${companyId}/version`, {
      policy: {
        data: {
          ...policy.data,
          idPolicyStatus: 22
        },
        aircrafts
      }
    });
    setPolicy({
      ...policy,
      data: {
        ...policy.data,
        ...response.data
      },
      idPolicy: response.data.id,
      versions: [response.data, ...policy.versions],
      versionIndex: response.data.id
    });
    setAircrafts(response.data.PolicyAircraft);
    setIsLoading(false);
  };

  const decryptPilotRequirements = (data) => {
    if (data?.length > 0) {
      const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_SECRET_KEY);
      const newItems = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setItems(
        newItems.map((item) => ({
          idPilot: item?.idPilot,
          input1: Object.keys(item)[1]
            .replace(/([A-Z])/g, ` $1`)
            .toLowerCase(),
          input2: item[Object.keys(item)[1]]
        }))
      );
    }
  }

  const handleTemplateClick = (event) => {
    const { value } = event.target;

    const description = templates.find((item) => item.id === value).description;
    const newDescription =
      policy?.data?.pilotRequirementDescription?.length > 0 ? `${policy?.data?.pilotRequirementDescription}\n ${description}` : description;
    setPolicy({
      ...policy,
      data: {
        ...policy.data,
        pilotRequirementDescription: newDescription
      }
    });
    setTemplateId(value);
  };

  const handleAddItem = () => {
    const newId = items.length + 1;
    const newItem = { id: newId, idPilot: '', input1: '', input2: '' };
    setItems([...items, newItem]);
    setCurrentItemIndex(items.length); // Move to the new item
  };

  const handleNextItem = () => {
    if (items.length > 0) {
      setTransitioning(true);
      setCurrentItemIndex((prevIndex) => (prevIndex + 1) % items.length);
    }
  };

  const handlePrevItem = () => {
    if (items.length > 0) {
      setTransitioning(true);
      setCurrentItemIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
    }
  };

  // Reset the transitioning state after the animation is done
  useEffect(() => {
    if (transitioning) {
      const timer = setTimeout(() => {
        setTransitioning(false);
      }, 500); // Match the duration of the fade transition
      return () => clearTimeout(timer);
    }
  }, [transitioning]);

  // Use Effect Methods
  // useEffect(() => {
  //   updateInstance(
  //     <PDFContent
  //       policy={policy.data}
  //       policyOptions={policy.options.filter((item) => item.accept)}
  //       aircrafts={aircrafts}
  //       // pilots={pilots}
  //       creationDate={creationDate}
  //     />
  //   );
  // }, [policy.data, aircrafts, creationDate]);

  useEffect(() => {
    const getCustomerContacts = async () => {
      const response = await axios.get(`/api/customer-contact/customerOrgId/${customerOrgId}/companyId/${companyId}`);
      setCustomerContacts([...response.data]);
    };
    if (customerOrgId) {
      getCustomerContacts();
    }
  }, [customerOrgId, companyId]);

  useEffect(() => {
    const getPolicy = async () => {
      setIsLoading(true);
      const array = await axios.get(`/api/policy/${policyId}/companyId/${companyId}`);
      console.log(array);
      const response = array.data[0];
      setPolicy((prevPolicy) => ({
        ...prevPolicy,
        data: {
          ...response
        },
        idPolicy: response?.id,
        versions: [...array.data],
        versionIndex: response?.id,
        options: [...response.PolicyOptions]
      }));
      const stateAircrafts = [...response.PolicyAircraft];
      setVersionPolicyId(response?.id);
      setAircrafts(stateAircrafts);
      setRequirementPilots(stateAircrafts);
      setAgencyId(response?.idAgency);
      setCustomerOrgId(response?.idCustomerOrganization);
      formatDocuments([...response.PolicyDocument]);

      // decrypt the pilot requirement data and set it to the items state
      decryptPilotRequirements(response?.pilotRequirementData);
      setIsLoading(false);
    };
    const getPolicyTypes = async () => {
      try {
        const response = await axios.get(`/api/options/companyId/${companyId}?category=PolicyType`);
        setPolicy((prevPolicy) => ({
          ...prevPolicy,
          policyTypes: response.data
        }));
      } catch (err) {
        console.error('Something went wrong', err);
      }
    };

    const getPolicyStatus = async () => {
      const response = await axios.get(`/api/options/companyId/${companyId}?category=PolicyStatus`);
      setPolicy((prevPolicy) => ({
        ...prevPolicy,
        policyStatus: response.data
      }));
    };

    const getInsurances = async () => {
      const result = await axios.get(`/api/insurance/companyId/${companyId}`);
      setInsurances([...result.data]);
    };

    const getCustomerOrgs = async () => {
      const result = await axios.get(`/api/customer-org/companyId/${companyId}`);
      setCustomerOrg(result.data);
    };

    const getCompanyUserProfiles = async () => {
      const result = await axios.get(`/api/company/${companyId}/users`);
      setUsers(result.data.UserProfile);
    };

    const getAgencies = async () => {
      const response = await axios.get(`/api/agency/companyId/${companyId}`);
      setAgencies([...response.data]);
    };

    const getAgents = async () => {
      const response = await axios.get(`/api/agent/companyId/${companyId}`);
      setAgents([...response.data]);
    };

    const getAirports = async () => {
      const response = await axios.get(`/api/airport/companyId/${companyId}`);
      setAirports([...response.data.airports]);
    };

    const getTemplates = async () => {
      const response = await axios.get(`/api/pilotTemplate/companyId/${companyId}`);
      setTemplates([...response.data]);
    };

    getPolicyTypes();
    getPolicyStatus();
    getPolicy();
    getInsurances();
    getCustomerOrgs();
    getAgencies();
    getAgents();
    getCompanyUserProfiles();
    getAirports();
    getTemplates();
  }, [policyId, companyId]);

  useEffect(() => {

    if (versionPolicyId) {
      handleRefresh();
    }
  }, [versionPolicyId]);

  return (
    <>
      <Breadcrumb title="Policy Details" sx={{ mt: 1.5 }}>
        <Typography component={RouterLink} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography
          component={RouterLink}
          to={`/company/${companyId}/policy`}
          variant="subtitle2"
          color="inherit"
          className="link-breadcrumb"
        >
          Policy
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Policy Details
        </Typography>
      </Breadcrumb>
      <LoadingOverlay loading={isLoading} />
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <Hidden smUp>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start">
                <MenuIcon sx={{ fontSize: '1.75rem' }} />
              </IconButton>
            </Grid>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleDrawerClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center'
              }}
              transformOrigin={{
                horizontal: 'center',
                vertical: 'top'
              }}
              PaperProps={{
                style: {
                  width: '200px'
                }
              }}
              sx={{ mt: 1 }}
            >
              <MenuItem sx={{ gap: '8px', fontSize: '12px' }} selected={value === 0} onClick={() => handleMenuChange(0)}>
                <DescriptionTwoToneIcon /> Policy
              </MenuItem>
              <MenuItem
                sx={{ gap: '8px', fontSize: '12px' }}
                selected={value === 1}
                onClick={() => handleMenuChange(1)}
                disabled={!policy.data?.insurance}
              >
                <InsuranceIcon /> Insurance
              </MenuItem>
              <MenuItem sx={{ gap: '8px', fontSize: '12px' }} selected={value === 2} onClick={() => handleMenuChange(2)}>
                <Category /> Policy Options
              </MenuItem>
              <MenuItem sx={{ gap: '8px', fontSize: '12px' }} selected={value === 3} onClick={() => handleMenuChange(3)}>
                <FinanceIcon /> Finance
              </MenuItem>
              <MenuItem
                sx={{ gap: '8px', fontSize: '12px' }}
                selected={value === 4}
                onClick={() => handleMenuChange(4)}
                disabled={policy.data?.idPolicyStatus !== 23}
              >
                <ReceiptTwoTone /> {policy.data?.idPolicyStatus}
              </MenuItem>
              <MenuItem
                sx={{ gap: '8px', fontSize: '12px' }}
                selected={value === 5}
                onClick={() => handleMenuChange(5)}
                disabled={policy.data?.idPolicyStatus !== 23}
              >
                <LocalShippingTwoToneIcon /> Claims
              </MenuItem>
            </Menu>
          </Hidden>
          <Hidden smDown>
            <Grid container spacing={gridSpacing} sx={{ justifyContent: 'space-between' }}>
              <Grid item>
                <AccountTabs
                  value={value}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                >
                  <Tab icon={<DescriptionTwoToneIcon />} iconPosition="start" label="Policy" {...a11yProps(0)} />
                  <Tab
                    icon={<InsuranceIcon />}
                    iconPosition="start"
                    label="Insurance"
                    {...a11yProps(1)}
                    disabled={!policy.data?.insurance}
                  />
                  <Tab icon={<Category />} iconPosition="start" label="Policy Options" {...a11yProps(2)} />
                  <Tab icon={<FinanceIcon />} iconPosition="start" label="Finance" {...a11yProps(3)} />
                  <Tab
                    icon={<ReceiptTwoTone />}
                    iconPosition="start"
                    label="Invoice"
                    {...a11yProps(4)}
                    disabled={policy.data.idPolicyStatus !== 23}
                  />
                  <CloneProps>
                    {(tabprops) => (
                      <Tooltip title={policy.data?.idPolicyStatus !== 23 ? 'Must be Policy Status of type Policy to access Claims' : ''}>
                        <div>
                          <Tab
                            icon={<LocalShippingTwoToneIcon />}
                            iconPosition="start"
                            label="Claims"
                            {...a11yProps(5)}
                            {...tabprops}
                            disabled={policy.data.idPolicyStatus !== 23}
                          />
                        </div>
                      </Tooltip>
                    )}
                  </CloneProps>
                </AccountTabs>
              </Grid>
              <Grid item sx={{ mr: 2 }}>
                <Typography variant="h6">Version: &nbsp;</Typography>
                <FormControlCustom variant="standard" sx={{ mt: 0.5 }}>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-outlined-label"
                    onChange={handleVersionChange}
                    name="version"
                    input={<Input fullWidth sx={{ width: '20vw' }} />}
                    value={policy.versionIndex || ''}
                    MenuProps={MenuProps}
                  >
                    {policy?.versions.map((item) => (
                      <MenuItem key={item?.id} value={item?.id}>
                        <Box display="flex" justifyContent="space-between" width="100%">
                          <Typography variant="body1">{`${item.id}: ${item?.['Options_Policy_idPolicyStatusToOptions']?.val}`}</Typography>
                          <Typography variant="body1">{dayjs(item.lastModifiedDate).format('MM/DD/YYYY')}</Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControlCustom>
              </Grid>
            </Grid>
          </Hidden>
          <TabPanel value={value} index={0}>
            <Card>
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm zeroMinWidth>
                    <Typography component="div" variant="h5">
                      Company
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item lg={6} xs={12}>
                        <Typography variant="subtitle2">
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            label="Company Name"
                            variant="outlined"
                            value={policy.data?.companyName || ''}
                            InputProps={{
                              readOnly: true
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <Typography variant="subtitle2">
                          <TextField
                            select
                            fullWidth
                            sx={{ mt: 1 }}
                            value={policy.data?.idCompanyUser || ''}
                            onChange={handleChange}
                            name="idCompanyUser"
                            label="Company User"
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22
                            }}
                          >
                            {deactiveArrayUtil(users, {
                              id: policy.data?.idCompanyUser,
                              displayName: policy.data?.['UserProfile_Policy_idCompanyUserToUserProfile']?.displayName
                            }).map((user) => (
                              <MenuItem key={user.id} value={user.id}>
                                {user.displayName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CompanyUserDetails policy={policy.data} />
              <Divider />
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm zeroMinWidth>
                    <Typography component="div" variant="h5">
                      Client
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item lg={4} xs={12}>
                        <Typography variant="subtitle2">
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            label="Client Name Insured"
                            name="clientNameInsured"
                            variant="outlined"
                            onChange={handleChange}
                            value={policy.data?.clientNameInsured || ''}
                            error={!policy.data?.clientNameInsured}
                            helperText={!policy.data?.clientNameInsured ? 'Cannot Be Empty' : ''}
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        {customerOrg?.length > 0 && (
                          <Typography variant="subtitle2">
                            <TextField
                              select
                              fullWidth
                              value={policy.data?.idCustomerOrganization || ''}
                              onChange={handleChange}
                              name="idCustomerOrganization"
                              label="Customer Organization"
                              sx={{ mt: 1 }}
                              InputProps={{
                                readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22
                              }}
                            >
                              {deactiveArrayUtil(customerOrg, {
                                id: policy.data?.idCustomerOrganization,
                                name: policy.data?.CustomerOrganization?.name
                              }).map((org) => (
                                <MenuItem key={org.id} value={org.id}>
                                  {org.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Typography>
                        )}
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        {customerContacts?.length > 0 && (
                          <Typography variant="subtitle2">
                            <TextField
                              select
                              fullWidth
                              sx={{ mt: 1 }}
                              value={policy.data?.idCustomerOrganization ? policy.data?.idCustomerContact || '' : ''}
                              onChange={handleChange}
                              name="idCustomerContact"
                              label="Customer Contact"
                              error={!policy.data?.idCustomerContact}
                              helperText={!policy.data?.idCustomerContact && 'Customer Contact cannot be empty'}
                              InputProps={{
                                readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22
                              }}
                            >
                              {deactiveArrayUtil(customerContacts, {
                                id: policy.data?.idCustomerContact,
                                idCustomerOrganization: policy.data?.CustomerContact?.idCustomerOrganization,
                                name: policy.data?.CustomerContact?.name
                              }).map((cc) => (
                                <MenuItem key={cc.id} value={cc.id}>
                                  {cc.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Typography>
                        )}
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <Typography variant="subtitle2">
                          <TextField
                            value={policy.data?.idAgency || ''}
                            onChange={handleChange}
                            name="idAgency"
                            label="Agency Name"
                            select
                            fullWidth
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22
                            }}
                          >
                            {deactiveArrayUtil(agencies, {
                              id: policy.data?.idAgency,
                              name: policy.data?.Agency?.name
                            }).map((user) => (
                              <MenuItem key={user.id} value={user.id}>
                                {user.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Typography>
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <Typography variant="subtitle2">
                          <TextField
                            value={policy.data?.agencyCode || ''}
                            name="agencyCode"
                            label="Agency Code"
                            fullWidth
                            InputProps={{
                              readOnly: true
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {agents?.length > 0 && (
                          <Typography variant="subtitle2">
                            <TextField
                              value={policy.data?.idAgent || ''}
                              onChange={handleChange}
                              name="idAgent"
                              fullWidth
                              select
                              label="Agent's Display Name"
                              error={!policy.data?.idAgent}
                              helperText={!policy.data?.idAgent && 'Agents cannot be Empty'}
                              InputProps={{
                                readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22
                              }}
                            >
                              {deactiveArrayUtil(agents, {
                                id: policy.data?.idAgent,
                                displayName: policy.data?.Agent?.displayName
                              })
                                ?.filter((item) => item.idAgency === agencyId)
                                ?.map((user) => (
                                  <MenuItem key={user.id} value={user.id}>
                                    {user.displayName}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <PolicyDuplicateDetails policy={policy.data} />
              <Divider />
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm={6} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography component="div" variant="h5">
                          Insurance
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="div" variant="subtitle1">
                          Insurance : &nbsp;
                          <Typography component="span" variant="subtitle2">
                            <Autocomplete
                              fullWidth
                              options={deactiveArrayUtil(insurances, {
                                id: policy.data?.idInsurance,
                                name: policy.data?.insurance?.name || ''
                              })}
                              getOptionLabel={(option) => option?.name}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              readOnly={!auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22}
                              value={
                                policy.data?.idInsurance
                                  ? deactiveArrayUtil(insurances, {
                                      id: policy.data?.idInsurance,
                                      name: policy.data?.insurance?.name
                                    }).find((type) => type.id === policy.data?.idInsurance)
                                  : null
                              }
                              onChange={(_, newValue) => {
                                const selectedInsuranceId = newValue ? newValue.id : null;
                                handleChange({ target: { name: 'idInsurance', value: selectedInsuranceId } });
                              }}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={2} />
                  <Grid item sm={4} xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography component="div" variant="subtitle1">
                          Insurance : &nbsp;
                          <Typography component="span" variant="subtitle2">
                            <TextField
                              sx={{ mt: 1 }}
                              fullWidth
                              name="insuranceNotes"
                              multiline
                              rows={6}
                              value={policy.data?.insuranceNotes || ''}
                              variant="outlined"
                              InputProps={{
                                readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22
                              }}
                              onChange={handleChange}
                            />
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography component="div" variant="h5">
                          Policy Info
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography component="div" variant="subtitle1">
                              Policy Number : &nbsp;
                              <Typography component="span" variant="subtitle2">
                                <TextField
                                  sx={{ mt: 1 }}
                                  fullWidth
                                  name="policyNumber"
                                  variant="outlined"
                                  value={policy.data?.policyNumber || ''}
                                  error={!policy.data?.policyNumber}
                                  helperText={!policy.data?.policyNumber ? 'Cannot Be Empty' : ''}
                                  InputProps={{
                                    readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22
                                  }}
                                  onChange={handleChange}
                                />
                              </Typography>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sx={{ mt: 1 }}>
                            <Typography component="div" variant="subtitle1">
                              Policy Type : &nbsp;
                              <Typography component="span" variant="subtitle2">
                                <TextField
                                  sx={{ mt: 1 }}
                                  fullWidth
                                  select
                                  name="idPolicyType"
                                  variant="outlined"
                                  value={policy.data?.idPolicyType || ''}
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22
                                  }}
                                >
                                  {policy.policyTypes?.map((type) => (
                                    <MenuItem key={type.id} value={type.id}>
                                      {type.val}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Typography>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sx={{ mt: 1 }}>
                            <Typography component="div" variant="subtitle1">
                              Policy Status : &nbsp;
                              <Typography component="span" variant="subtitle2">
                                <TextField
                                  sx={{ mt: 1 }}
                                  fullWidth
                                  select
                                  name="idPolicyStatus"
                                  variant="outlined"
                                  value={policy.data?.idPolicyStatus || ''}
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: true
                                  }}
                                >
                                  {policy.policyStatus?.map((type) => (
                                    <MenuItem key={type.id} value={type.id}>
                                      {type.val}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Typography>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} />
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography component="div" variant="h6">
                              Policy Date Range
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid item xs={12}>
                              <Typography component="div" variant="subtitle1">
                                Begin Date : &nbsp;
                              </Typography>
                            </Grid>

                            <Grid item xs={12}>
                              <Typography component="span" variant="subtitle2">
                                <DateTimePicker
                                  disableToolbar
                                  variant="inline"
                                  inputFormat="MM/dd/yyyy"
                                  margin="normal"
                                  value={policy.data?.beginDate ? formatDate(policy?.data?.beginDate) : null}
                                  readOnly={!auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22}
                                  slotProps={{
                                    textField: {
                                      error: !policy.data?.beginDate,
                                      helperText: !policy.data?.beginDate ? 'Cannot Be Empty' : '',
                                      fullWidth: true
                                    }
                                  }}
                                  onChange={handleBeginDateChange}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                  }}
                                  keyboardIcon={<EventTwoToneIcon />}
                                />
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid item xs={12}>
                              <Typography component="div" variant="subtitle1">
                                End Date : &nbsp;
                              </Typography>
                            </Grid>

                            <Grid item xs={12}>
                              <Typography component="span" variant="subtitle2">
                                <DateTimePicker
                                  disableToolbar
                                  variant="inline"
                                  inputFormat="MM/dd/yyyy"
                                  margin="normal"
                                  value={policy.data?.endDate ? formatDate(policy?.data?.endDate) : null}
                                  readOnly={!auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22}
                                  slotProps={{
                                    textField: {
                                      error: !policy.data?.endDate,
                                      helperText: !policy.data?.endDate ? 'Cannot Be Empty' : '',
                                      fullWidth: true
                                    }
                                  }}
                                  onChange={handleEndDateChange}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                  }}
                                  keyboardIcon={<EventTwoToneIcon />}
                                />
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography component="div" variant="h5" />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography component="div" variant="subtitle1">
                              Endorsements : &nbsp;
                              <Typography component="span" variant="subtitle2">
                                <TextField
                                  sx={{ mt: 3 }}
                                  fullWidth
                                  name="endorsements"
                                  multiline
                                  rows={6}
                                  value={policy.data?.endorsements || ''}
                                  variant="outlined"
                                  InputProps={{
                                    readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22
                                  }}
                                  onChange={handleChange}
                                />
                              </Typography>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography component="div" variant="h5">
                          Training Required
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <FormControl component="fieldset">
                              <RadioGroup
                                aria-label="gender"
                                name="trainingRequired"
                                value={policy.data?.trainingRequired || false}
                                onChange={() => policy.data?.idPolicyStatus !== 22 && handleChange()}
                              >
                                <Grid container spacing={1}>
                                  <Grid item>
                                    <FormControlLabel value={true} control={<Radio color="default" />} label="Yes" />
                                  </Grid>
                                  <Grid item>
                                    <FormControlLabel value={false} control={<Radio color="default" />} label="No" />
                                  </Grid>
                                </Grid>
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {policy.data?.idParentPolicy && (
                      <Grid container spacing={1} sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                          <Typography component="div" variant="h5">
                            Parent Policy : &nbsp;
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={0}>
                            <Grid item xs={12}>
                              <Typography component={RouterLink} to={`/company/${companyId}/policy/${policy.data.idParentPolicy}`}>
                                {policy.data?.Policy?.policyNumber}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item sm zeroMinWidth>
                    <Typography component="div" variant="h6">
                      Newly created policy please click the refresh button to get newest details
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              {policy.data?.idPolicyType === 28 && (
                <>
                  <CardContent>
                    <Grid container spacing={gridSpacing}>
                      <Grid item sm zeroMinWidth>
                        <Typography component="div" variant="h5">
                          Airport Information
                        </Typography>
                        <Typography component="span" variant="subtitle2">
                          Address and Geographic Location will show once you save changes once Airport is Selected
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            {airports?.length > 0 && (
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                select
                                label="Airport Name"
                                name="idAirport"
                                variant="outlined"
                                value={policy.data?.idAirport || ''}
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22
                                }}
                                onChange={handleChange}
                              >
                                {deactiveArrayUtil(airports, {
                                  id: policy.data?.idAirport,
                                  name: policy.data?.Airport?.name
                                }).map((user) => (
                                  <MenuItem key={user.id} value={user.id}>
                                    {user.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          </Grid>
                          <Grid item lg={2} xs={12}>
                            <TextField
                              sx={{ mt: 1 }}
                              fullWidth
                              label="Airport Code"
                              variant="outlined"
                              value={policy.data?.airportCode || ''}
                              disabled
                            />
                          </Grid>
                          <Grid item lg={4} xs={12}>
                            <TextField
                              sx={{ mt: 1 }}
                              fullWidth
                              label="Airport Type"
                              variant="outlined"
                              value={policy.data?.airportType || ''}
                              disabled
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <AirportPolicyDetails policy={policy.data} />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={gridSpacing}>
                      <Grid item sm zeroMinWidth>
                        <Typography component="div" variant="h5">
                          Policy Options Airport
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              sx={{ mt: 1 }}
                              value={policy.data?.airportLiabilityCode || ''}
                              label="Airport Liability"
                              name="airportLiabilityCode"
                              onChange={handleChange}
                              InputProps={{
                                readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              sx={{ mt: 1 }}
                              label="Liability Amount"
                              id="filled-start-adornment1"
                              name="airportLiabilityAmount"
                              value={policy.data?.airportLiabilityAmount || ''}
                              type="number"
                              fullWidth
                              onChange={handleChange}
                              InputProps={{
                                inputProps: { min: 0 },
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22
                              }}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              sx={{ mt: 1 }}
                              value={policy.data?.airportHullCode || ''}
                              label="Airport Hull"
                              name="airportHullCode"
                              onChange={handleChange}
                              InputProps={{
                                readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              sx={{ mt: 1 }}
                              label="Hull Amount"
                              id="filled-start-adornment2"
                              name="airportHullAmount"
                              value={policy.data?.airportHullAmount || ''}
                              type="number"
                              fullWidth
                              onChange={handleChange}
                              InputProps={{
                                inputProps: { min: 0 },
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22
                              }}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              sx={{ mt: 1 }}
                              value={policy.data?.airportMedicalCode || ''}
                              label="Airport Medical"
                              name="airportMedicalCode"
                              onChange={handleChange}
                              InputProps={{
                                readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              sx={{ mt: 1 }}
                              label="Medical Amount"
                              id="filled-start-adornment3"
                              name="airportMedicalAmount"
                              value={policy.data?.airportMedicalAmount || ''}
                              type="number"
                              fullWidth
                              onChange={handleChange}
                              InputProps={{
                                inputProps: { min: 0 },
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22
                              }}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              sx={{ mt: 1 }}
                              value={policy.data?.airportTerritoryCode || ''}
                              label="Airport Territory"
                              name="airportTerritoryCode"
                              onChange={handleChange}
                              InputProps={{
                                readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              sx={{ mt: 1 }}
                              label="Territory Amount"
                              id="filled-start-adornment4"
                              name="airportTerritoryAmount"
                              value={policy.data?.airportTerritoryAmount || ''}
                              type="number"
                              fullWidth
                              onChange={handleChange}
                              InputProps={{
                                inputProps: { min: 0 },
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22
                              }}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              sx={{ mt: 1 }}
                              value={policy.data?.airportWarTriaCode || ''}
                              label="Airport WarTria"
                              name="airportWarTriaCode"
                              onChange={handleChange}
                              InputProps={{
                                readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              sx={{ mt: 1 }}
                              label="WarTria Amount"
                              id="filled-start-adornment5"
                              name="airportWarTriaAmount"
                              value={policy.data?.airportWarTriaAmount || ''}
                              type="number"
                              fullWidth
                              onChange={handleChange}
                              InputProps={{
                                inputProps: { min: 0 },
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22
                              }}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </>
              )}
              <Divider />
              {!(policy.data?.idPolicyType === 28) && (
                <CardContent>
                  <Grid item sm zeroMinWidth>
                    <Typography component="div" variant="h5">
                      Pilot Requirements
                    </Typography>
                  </Grid>
                  <Grid container spacing={2} padding={2} sx={{ mt: 1 }}>
                    {/* Left Column: Ferris Wheel */}
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        textAlign: 'center',
                        marginLeft: 2,
                        border: '1px solid black'
                      }}
                    >
                      <div className="ferris-wheel-container">
                        <Grid item sm zeroMinWidth>
                          <Typography component="div" variant="h5" sx={{ mt: 1, mb: 2, textDecoration: 'underline' }}>
                            Load Requirement Data
                          </Typography>
                        </Grid>
                        <div className={`ferris-wheel-item ${transitioning ? 'fade-out' : 'fade-in'}`}>
                          <FormControl variant="outlined">
                            <InputLabel id="select-label">Pilot</InputLabel>
                            <Select
                              value={items[currentItemIndex].idPilot || ''}
                              label="Pilot"
                              labelId="select-label"
                              displayEmpty
                              onChange={(e) => {
                                const updatedItems = [...items];
                                updatedItems[currentItemIndex].idPilot = e.target.value;
                                setItems(updatedItems);
                              }}
                              variant="outlined"
                              sx={{ width: 150, mr: 2 }}
                            >
                              {pilotSelector.map((item) => (
                                <MenuItem key={item?.idPilot} value={item?.idPilot}>
                                  {`${item?.firstname} ${item?.lastname}`}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <TextField
                            variant="outlined"
                            placeholder="Input 1"
                            label="Field"
                            value={items[currentItemIndex].input1 || ''}
                            onChange={(e) => {
                              const updatedItems = [...items];
                              updatedItems[currentItemIndex].input1 = e.target.value;
                              setItems(updatedItems);
                            }}
                            sx={{ mr: 2 }}
                          />

                          <TextField
                            variant="outlined"
                            type="number"
                            label="Hours"
                            placeholder="total hours"
                            value={items[currentItemIndex].input2 || ''}
                            onChange={(e) => {
                              const updatedItems = [...items];
                              updatedItems[currentItemIndex].input2 = e.target.value;
                              setItems(updatedItems);
                            }}
                            sx={{ mr: 2 }}
                          />
                          <Button onClick={handleAddItem}>New Item</Button>
                        </div>
                      </div>

                      {/* Up and Down Arrows */}
                      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                        <Button onClick={handlePrevItem} disabled={items.length === 0} startIcon={<ArrowUpwardIcon />}>
                          Up
                        </Button>
                        <Button onClick={handleNextItem} startIcon={<ArrowDownwardIcon />}>
                          Down
                        </Button>
                      </div>
                    </Grid>

                    {/* Right Column: Textarea */}
                    <Grid container spacing={2} item xs={12}>
                      {/* Typography for Description */}
                      <Grid item xs={8} sm={8}>
                        <Typography component="div" variant="h5" sx={{ mt: 3 }}>
                          Description
                        </Typography>
                      </Grid>

                      {/* Select Component */}
                      <Grid item xs={4} sm={4}>
                        <Typography component="span" variant="subtitle1">
                          Template:
                        </Typography>
                        <Select
                          variant="outlined"
                          fullWidth // Ensure it takes the full width of the Grid item
                          sx={{ height: '40px' }}
                          value={templateId || ''}
                          onChange={handleTemplateClick}
                        >
                          {templates.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.tag}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>

                      {/* TextField for multiline input */}
                      <Grid item xs={12}>
                        <TextField
                          placeholder="Pilot Requirement Endorsement...."
                          fullWidth
                          name="pilotRequirementDescription"
                          multiline
                          rows={30}
                          variant="outlined"
                          value={policy?.data?.pilotRequirementDescription || ''}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              )}
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={gridSpacing} sx={{ margin: { sm: '0 100px' } }}>
                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handlePolicySave}
                          disabled={
                            !policy.data.idCustomerContact ||
                            !policy.data?.clientNameInsured ||
                            !policy.data?.policyNumber ||
                            !policy.data?.idAgent ||
                            !policy.data?.endDate ||
                            !policy.data?.beginDate ||
                            (policy.data?.idPolicyType === 28 && !policy.data?.idAirport) ||
                            policy.data?.idPolicyStatus === 23 ||
                            !auth.writePrivledge(auth.user, 'Policy_Adm')
                          }
                        >
                          Save Changes
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          color="primary"
                          // disabled={
                          //   policy.versions.reduce((max, obj) => (obj?.id > max ? obj?.id : max), policy.versions[0]?.id) !== policy.idPolicy
                          // }
                          onClick={() => (policy.data?.idPolicyStatus !== 23 ? bindPolicy() : modifyPolicy())}
                        >
                          {policy.data?.idPolicyStatus !== 23 ? 'Bind Policy' : 'Modify Policy'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12} sx={{ display: { lg: 'flex', xs: 'block' }, justifyContent: 'space-between', alignItems: 'center' }}>
                    <Grid item sm={6} md={4}>
                      <Button variant="contained" color="primary" onClick={() => handleFileSave(instance.blob, true)}>
                        Save Policy PDF
                      </Button>
                    </Grid>
                    <Grid item>
                      <TextField
                        variant="standard"
                        type="text"
                        value={file?.name || ''}
                        InputProps={{
                          endAdornment: (
                            <IconButton component="label" disabled={policy.data?.idPolicyStatus !== 22}>
                              <FileUploadOutlined />
                              <input style={{ display: 'none' }} type="file" hidden onChange={handleFileChange} name="[licenseFile]" />
                            </IconButton>
                          ),
                          readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22
                        }}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFileSave}
                        sx={{ ml: 2 }}
                        disabled={!file?.name || !auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22}
                      >
                        Upload File
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTable isLoading={false} columns={columns} data={documents} title="Documents">
                      {(row) => (
                        <>
                          <Tooltip title="Download Document" placement="top">
                            <span>
                              <IconButton color="primary" aria-label="Download" size="large" onClick={() => handleDownload(row)}>
                                <FileDownloadOutlined fontSize="large" />
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip title="Delete File" placement="top">
                            <span>
                              <IconButton
                                color="secondary"
                                aria-label="Edit"
                                size="large"
                                disabled={!auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22}
                                onClick={() => handleDeleteMiddleware(row)}
                              >
                                <DeleteIcon fontSize="large" />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </>
                      )}
                    </CustomTable>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <AlertDialogSlide
              message="Confirm if you want to delete Policy Document"
              handleCloseDialog={handleCloseModal}
              handleResult={handleFileDelete}
              emitValue={deletedDocument}
              open={open}
            />
            {!(policy.data?.idPolicyType === 28) && (
              <Card sx={{ mt: 1 }}>
                <AlertDialogSlide
                  message={`Confirm if you want to delete ${deleteObject.type} Relation`}
                  handleCloseDialog={handleCloseModal}
                  handleResult={handleDeleteRelationRoute}
                  emitValue={deleteObject}
                  open={relationDelete}
                />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography component="div" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography component="div" variant="h5" sx={{ pl: 4 }}>
                          Aircrafts
                        </Typography>
                        <Tooltip title="Add Aircraft" placement="top">
                          <span>
                            <IconButton
                              color="primary"
                              aria-label="Edit"
                              size="large"
                              sx={{ pr: 4 }}
                              disabled={!auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22}
                              onClick={handleClickOpenAircraftDialog}
                            >
                              <Add fontSize="large" />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Typography>
                      <Box sx={{ overflowX: 'auto' }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell />
                              <TableCell>Registration Number</TableCell>
                              <TableCell align="right">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {aircrafts.map((row, index) => (
                              <React.Fragment key={row.id}>
                                <TableRow>
                                  <TableCell>
                                    <IconButton aria-label="expand row" size="small" onClick={() => toggleExpand(index, 'aircraftStates')}>
                                      {rowExpand.aircraftStates[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                  </TableCell>
                                  <TableCell>
                                    <Typography component="div" align="left" variant="subtitle1">
                                      {row?.Aircraft.registrationNo}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="right">
                                    <Tooltip title="Edit Aircraft Relation" placement="top">
                                      <span>
                                        <IconButton
                                          color="primary"
                                          aria-label="Edit"
                                          size="large"
                                          disabled={!auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22}
                                          onClick={() => handleAircraftEdit(row)}
                                        >
                                          <EditTwoToneIcon fontSize="large" />
                                        </IconButton>
                                      </span>
                                    </Tooltip>
                                    <IconButton
                                      color="primary"
                                      ara-label="delete"
                                      size="large"
                                      onClick={() => handleGoToAircraftDetail(row)}
                                    >
                                      <DescriptionTwoToneIcon fontSize="large" />
                                    </IconButton>
                                    <Tooltip title="Delete Aircraft Relation" placement="top">
                                      <span>
                                        <IconButton
                                          color="secondary"
                                          aria-label="Edit"
                                          size="large"
                                          disabled={!auth.writePrivledge(auth.user, 'Policy_Adm') || policy.data?.idPolicyStatus !== 22}
                                          onClick={() => handleDeleteRelationModal(aircrafts[index], 'Aircraft')}
                                        >
                                          <DeleteIcon fontSize="large" />
                                        </IconButton>
                                      </span>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ py: 0 }} colSpan={6}>
                                    <Collapse in={rowExpand.aircraftStates[index]} timeout="auto" unmountOnExit>
                                      <AircraftPolicyDetails
                                        row={row?.PolicyAircraftDetails?.length === 1 ? row?.PolicyAircraftDetails[0] : {}}
                                      />
                                      <Box margin={3}>
                                        <Table>
                                          <TableHead>
                                            <TableRow>
                                              <TableCell>Description</TableCell>
                                              <TableCell align="center">Short Code</TableCell>
                                              <TableCell align="center">Amount</TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            <TableRow>
                                              <TableCell>Liability</TableCell>
                                              <TableCell align="center">{row?.PolicyOptionAircraftLiability?.shortCode}</TableCell>
                                              <TableCell align="center">$ {row?.idLiabilityAmount || 0}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell>Medical</TableCell>
                                              <TableCell align="center">{row?.PolicyOptionAircraftMedical?.shortCode}</TableCell>
                                              <TableCell align="center">$ {row?.idMedicalAmount || 0}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell>Territory</TableCell>
                                              <TableCell align="center">{row?.PolicyOptionAircraftTerritory?.shortCode}</TableCell>
                                              <TableCell align="center">$ {row?.idTerritoryAmount || 0}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell>WarTria</TableCell>
                                              <TableCell align="center">{row?.PolicyOptionAircraftWarTria?.shortCode}</TableCell>
                                              <TableCell align="center">$ {row?.idWarTriaAmount || 0}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell>Hull</TableCell>
                                              <TableCell align="center">{row?.PolicyOptionAircraftHull?.shortCode}</TableCell>
                                              <TableCell align="center">$ {row?.idHullAmount || 0}</TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                        <Table sx={{ mt: 2 }}>
                                          <TableHead>
                                            <TableRow>
                                              <TableCell></TableCell>
                                              <TableCell></TableCell>
                                              <TableCell align="center">Amount</TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            <TableRow>
                                              <TableCell>{"Taxes and Fee's"}</TableCell>
                                              <TableCell></TableCell>
                                              <TableCell align="center">$ {row?.taxesFees || 0}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell>Hull NIM Deductible</TableCell>
                                              <TableCell></TableCell>
                                              <TableCell align="center">$ {row?.idHullNIMDeductible || 0}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell>Hull IM Deductible</TableCell>
                                              <TableCell></TableCell>
                                              <TableCell align="center">$ {row?.idHullIMDeductible || 0}</TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                        <Table sx={{ mt: 2 }}>
                                          <TableHead>
                                            <TableRow>
                                              <TableCell></TableCell>
                                              <TableCell align="center">Amount</TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            <TableRow>
                                              <TableCell>Total</TableCell>
                                              <TableCell align="center">$ {calculateTotal(row).toFixed(2)}</TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                      </Box>
                                      <Box margin={3}>
                                        <Grid container spacing={gridSpacing}>
                                          <Grid item sm zeroMinWidth>
                                            <Typography component="div" variant="h3">
                                              Pilots
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12}>
                                            <Table>
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell></TableCell>
                                                  <TableCell>Full Name</TableCell>
                                                  <TableCell>Actions</TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {row?.AircraftPilotDetails?.map((pilot, pilotIndex) => (
                                                  <React.Fragment key={pilot.PilotDetails.id}>
                                                    <TableRow>
                                                      <TableCell>
                                                        <IconButton
                                                          aria-label="expand row"
                                                          size="small"
                                                          onClick={() => toggleExpand(pilotIndex, 'pilotStates')}
                                                        >
                                                          {rowExpand.pilotStates[pilotIndex] ? (
                                                            <KeyboardArrowUpIcon />
                                                          ) : (
                                                            <KeyboardArrowDownIcon />
                                                          )}
                                                        </IconButton>
                                                      </TableCell>
                                                      <TableCell>{pilot?.PilotDetails.firstname}</TableCell>
                                                      <TableCell>
                                                        <IconButton
                                                          color="primary"
                                                          ara-label="details"
                                                          size="large"
                                                          onClick={() => handleGoToPilotDetail(pilot)}
                                                        >
                                                          <DescriptionTwoToneIcon fontSize="large" />
                                                        </IconButton>
                                                      </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                      <TableCell sx={{ py: 0 }} colSpan={6}>
                                                        <Collapse in={rowExpand.pilotStates[pilotIndex]} timeout="auto" unmountOnExit>
                                                          <PilotPolicyDetails pilot={pilot.PilotDetails} />
                                                        </Collapse>
                                                      </TableCell>
                                                    </TableRow>
                                                  </React.Fragment>
                                                ))}
                                              </TableBody>
                                            </Table>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                      <Box margin={3}>
                                        <Grid container spacing={gridSpacing}>
                                          <Grid item sm zeroMinWidth>
                                            <Typography component="div" variant="h3">
                                              Other Interests
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12}>
                                            <Table>
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell></TableCell>
                                                  <TableCell>Name</TableCell>
                                                  <TableCell>Actions</TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {row?.AircraftDetailsLienholder?.map((other, otherIndex) => (
                                                  <React.Fragment key={other.id}>
                                                    <TableRow key={other.id}>
                                                      <TableCell>
                                                        <IconButton
                                                          aria-label="expand row"
                                                          size="small"
                                                          onClick={() => toggleExpand(otherIndex, 'otherStates')}
                                                        >
                                                          {rowExpand.otherStates[otherIndex] ? (
                                                            <KeyboardArrowUpIcon />
                                                          ) : (
                                                            <KeyboardArrowDownIcon />
                                                          )}
                                                        </IconButton>
                                                      </TableCell>
                                                      <TableCell>{other?.LienholderDetails?.name}</TableCell>
                                                      <TableCell>
                                                        <IconButton
                                                          color="primary"
                                                          ara-label="details"
                                                          size="large"
                                                          onClick={() => handleGoToPilotDetail(pilot)}
                                                        >
                                                          <DescriptionTwoToneIcon fontSize="large" />
                                                        </IconButton>
                                                      </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                      <TableCell sx={{ py: 0 }} colSpan={6}>
                                                        <Collapse in={rowExpand.otherStates[otherIndex]} timeout="auto" unmountOnExit>
                                                          <LeinholderPolicyDetails other={other?.LienholderDetails} />
                                                        </Collapse>
                                                      </TableCell>
                                                    </TableRow>
                                                  </React.Fragment>
                                                ))}
                                              </TableBody>
                                            </Table>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </TabPanel>
          <AircraftDialog
            open={openAircraft}
            handleClosedialog={handleClosedialog}
            handleAircraftSave={handleSaveAircraft}
            aircraft={policy?.editAircraftPolicy}
            actionType="Details"
            users={users}
            customerOrg={customerOrg}
            customerOrgId={policy.data?.idCustomerOrganization}
            aircraftRelations={aircrafts}
            isPolicyAircraft={true}
          />

          <TabPanel value={value} index={1}>
            <Card
              sx={{
                maxWidth: 850,
                mx: 'auto',
                mb: 2.5,
                mt: 0,
                '& .MuiCardContent-root': {
                  py: { md: 5.5, xs: 3.75 },
                  px: { md: 5, xs: 2.5 }
                }
              }}
              ref={componentRef}
            >
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                      <Grid item>
                        <Typography component="div" variant="h4">
                          {policy.data?.insuranceName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <Typography component="div" variant="subtitle1">
                          Insurance Code : &nbsp;
                        </Typography>
                        <Typography component="span" variant="subtitle2">
                          {policy.data?.insuranceCode}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                      <Grid item sm={5}></Grid>
                      <Grid item sm={4}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography component="div" variant="h6">
                              Insurance Notes :
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={0}>
                              <Grid item xs={12}>
                                <Typography component="div" variant="body2">
                                  <TextField
                                    sx={{ mt: 1 }}
                                    fullWidth
                                    name="endorsements"
                                    multiline
                                    rows={6}
                                    value={policy.data?.insuranceNotes || ''}
                                    variant="outlined"
                                  />
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Grid item xs={12}>
              <PolicyOptions
                isLoading={isLoading}
                data={policy.options}
                policyId={policy.data.id}
                companyId={companyId}
                updatePolicyOption={updatePolicyOption}
              />
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Card>
              <CardContent>
                <Grid item sm={12}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                      <Typography component="div" variant="h5">
                        {policy.data?.idAirport ? 'Airport Total Premium' : 'Aircraft Total Premium'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        {policy.data?.idAirport ? (
                          <Grid item xs={12}>
                            <Typography component="div" variant="h6">
                              Airport Name : &nbsp; {policy.data?.Airport?.name}
                            </Typography>
                            <Grid item xs={12} sx={{ display: { sm: 'flex', xs: 'block' }, justifyContent: 'space-between', mt: 1 }}>
                              <Grid item sm={1} xs={12}>
                                <Typography component="div" variant="h6">
                                  Hull
                                  <Typography component="span" variant="subtitle1">
                                    <TextField
                                      fullWidth
                                      value={policy.data?.airportHullAmount || 0}
                                      InputProps={{ readOnly: true, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                                    />
                                  </Typography>
                                </Typography>
                              </Grid>
                              <Grid item sm={1} xs={12}>
                                <Typography component="span" variant="h6">
                                  Liability
                                  <Typography component="span" variant="subtitle1">
                                    <TextField
                                      fullWidth
                                      value={policy.data?.airportLiabilityAmount || 0}
                                      InputProps={{ readOnly: true, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                                    />
                                  </Typography>
                                </Typography>
                              </Grid>
                              <Grid item sm={1} xs={12}>
                                <Typography component="span" variant="h6">
                                  Medical
                                  <Typography component="span" variant="subtitle1">
                                    <TextField
                                      fullWidth
                                      value={policy.data?.airportMedicalAmount || 0}
                                      InputProps={{ readOnly: true, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                                    />
                                  </Typography>
                                </Typography>
                              </Grid>
                              <Grid item sm={1} xs={12}>
                                <Typography component="span" variant="h6">
                                  Territory
                                  <Typography component="span" variant="subtitle1">
                                    <TextField
                                      fullWidth
                                      value={policy.data?.airportTerritoryAmount || 0}
                                      InputProps={{ readOnly: true, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                                    />
                                  </Typography>
                                </Typography>
                              </Grid>
                              <Grid item sm={1} xs={12}>
                                <Typography component="span" variant="h6">
                                  WarTria
                                  <Typography component="span" variant="subtitle1">
                                    <TextField
                                      fullWidth
                                      value={policy.data?.airportWarTriaAmount || 0}
                                      InputProps={{ readOnly: true, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                                    />
                                  </Typography>
                                </Typography>
                              </Grid>
                              <Grid item sm={2} xs={12}>
                                <Typography component="span" variant="h6">
                                  Total
                                  <Typography component="span" variant="subtitle1">
                                    <TextField
                                      fullWidth
                                      value={calculateTotal(policy, false).toFixed(2) || 0}
                                      InputProps={{ readOnly: true, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                                    />
                                  </Typography>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          aircrafts?.map((item) => (
                            <React.Fragment key={item.id}>
                              <Grid item xs={12}>
                                <Typography component="div" variant="h6">
                                  Registration Number : &nbsp; {item?.Aircraft?.registrationNo}
                                </Typography>
                                <Grid item xs={12} sx={{ display: { sm: 'flex', xs: 'block' }, justifyContent: 'space-between', mt: 1 }}>
                                  <Grid item sm={1} xs={12}>
                                    <Typography component="div" variant="h6">
                                      Hull
                                      <Typography component="span" variant="subtitle1">
                                        <TextField
                                          fullWidth
                                          value={item?.idHullAmount || 0}
                                          InputProps={{
                                            readOnly: true,
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                                          }}
                                        />
                                      </Typography>
                                    </Typography>
                                  </Grid>
                                  <Grid item sm={1} xs={12}>
                                    <Typography component="span" variant="h6">
                                      Liability
                                      <Typography component="span" variant="subtitle1">
                                        <TextField
                                          fullWidth
                                          value={item?.idLiabilityAmount || 0}
                                          InputProps={{
                                            readOnly: true,
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                                          }}
                                        />
                                      </Typography>
                                    </Typography>
                                  </Grid>
                                  <Grid item sm={1} xs={12}>
                                    <Typography component="span" variant="h6">
                                      Medical
                                      <Typography component="span" variant="subtitle1">
                                        <TextField
                                          fullWidth
                                          value={item?.idMedicalAmount || 0}
                                          InputProps={{
                                            readOnly: true,
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                                          }}
                                        />
                                      </Typography>
                                    </Typography>
                                  </Grid>
                                  <Grid item sm={1} xs={12}>
                                    <Typography component="span" variant="h6">
                                      Territory
                                      <Typography component="span" variant="subtitle1">
                                        <TextField
                                          fullWidth
                                          value={item?.idTerritoryAmount || 0}
                                          InputProps={{
                                            readOnly: true,
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                                          }}
                                        />
                                      </Typography>
                                    </Typography>
                                  </Grid>
                                  <Grid item sm={1} xs={12}>
                                    <Typography component="span" variant="h6">
                                      WarTria
                                      <Typography component="span" variant="subtitle1">
                                        <TextField
                                          fullWidth
                                          value={item?.idWarTriaAmount || 0}
                                          InputProps={{
                                            readOnly: true,
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                                          }}
                                        />
                                      </Typography>
                                    </Typography>
                                  </Grid>
                                  <Grid item sm={1} xs={12}>
                                    <Typography component="span" variant="h6">
                                      Taxes
                                      <Typography component="span" variant="subtitle1">
                                        <TextField
                                          fullWidth
                                          value={item?.taxesFees || 0}
                                          InputProps={{
                                            readOnly: true,
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                                          }}
                                        />
                                      </Typography>
                                    </Typography>
                                  </Grid>

                                  <Grid item sm={2} xs={12}>
                                    <Typography component="span" variant="h6">
                                      Total
                                      <Typography component="span" variant="subtitle1">
                                        <TextField
                                          fullWidth
                                          value={calculateTotal(item, false).toFixed(2) || 0}
                                          InputProps={{
                                            readOnly: true,
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                                          }}
                                        />
                                      </Typography>
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                          ))
                        )}
                      </Grid>
                      {!policy.data?.idAirport && policy.data?.idAirport === -1 && (
                        <Grid item xs={12} sx={{ mt: 5 }}>
                          <Typography component="div" variant="h5">
                            Total Premiums
                          </Typography>
                          <Grid
                            item
                            xs={12}
                            sx={{ mt: 1, display: { sm: 'flex', xs: 'block' }, justifyContent: 'space-between', alignContent: 'center' }}
                          >
                            <Grid item sm={1} xs={12}>
                              <Typography component="div" variant="h6">
                                Hull
                                <Typography component="span" variant="subtitle1">
                                  <TextField
                                    fullWidth
                                    value={calculateTotalPropertyPremium(aircrafts, 'idHullAmount')}
                                    InputProps={{ readOnly: true, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                                  />
                                </Typography>
                              </Typography>
                            </Grid>
                            <Grid item sm={1} xs={12}>
                              <Typography component="div" variant="h6">
                                Liability
                                <Typography component="span" variant="subtitle1">
                                  <TextField
                                    fullWidth
                                    value={calculateTotalPropertyPremium(aircrafts, 'idLiabilityAmount')}
                                    InputProps={{ readOnly: true, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                                  />
                                </Typography>
                              </Typography>
                            </Grid>
                            <Grid item sm={1} xs={12}>
                              <Typography component="div" variant="h6">
                                Medical
                                <Typography component="span" variant="subtitle1">
                                  <TextField
                                    fullWidth
                                    value={calculateTotalPropertyPremium(aircrafts, 'idMedicalAmount')}
                                    InputProps={{ readOnly: true, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                                  />
                                </Typography>
                              </Typography>
                            </Grid>
                            <Grid item sm={1} xs={12}>
                              <Typography component="div" variant="h6">
                                Territory
                                <Typography component="span" variant="subtitle1">
                                  <TextField
                                    fullWidth
                                    value={calculateTotalPropertyPremium(aircrafts, 'idTerritoryAmount')}
                                    InputProps={{ readOnly: true, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                                  />
                                </Typography>
                              </Typography>
                            </Grid>
                            <Grid item sm={1} xs={12}>
                              <Typography component="div" variant="h6">
                                WarTria
                                <Typography component="span" variant="subtitle1">
                                  <TextField
                                    fullWidth
                                    value={calculateTotalPropertyPremium(aircrafts, 'idWarTriaAmount')}
                                    InputProps={{ readOnly: true, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                                  />
                                </Typography>
                              </Typography>
                            </Grid>
                            <Grid item sm={1} xs={12}>
                              <Typography component="div" variant="h6">
                                Taxes
                                <Typography component="span" variant="subtitle1">
                                  <TextField
                                    fullWidth
                                    value={calculateTotalPropertyPremium(aircrafts, 'taxesFees')}
                                    InputProps={{ readOnly: true, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                                  />
                                </Typography>
                              </Typography>
                            </Grid>
                            <Grid item sm={2} xs={12}>
                              <Typography component="div" variant="h6">
                                Total Aircraft Premium
                                <Typography component="span" variant="subtitle1">
                                  <TextField
                                    fullWidth
                                    value={calculateTotalPremiumm(aircrafts) || 0}
                                    InputProps={{ readOnly: true, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                                  />
                                </Typography>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography component="div" variant="h5">
                          Policy Options Total Premium
                        </Typography>
                      </Grid>
                      {policy.options
                        ?.filter((item) => item.accept)
                        ?.map((item) => (
                          <React.Fragment key={item.id}>
                            <Grid item sm={6} xs={12}>
                              <Typography component="div" variant="subtitle1">
                                {item.description}
                                <Grid item sm={6} xs={12}>
                                  <Typography component="span" variant="subtitle2">
                                    <TextField
                                      fullWidth
                                      value={item.premium || 0}
                                      InputProps={{ readOnly: true, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                                    />
                                  </Typography>
                                </Grid>
                              </Typography>
                            </Grid>
                          </React.Fragment>
                        ))}
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 5, display: { sm: 'flex', xs: 'block' }, justifyContent: 'flex-end' }}>
                      <Typography component="div" variant="h6">
                        Total Polcy Option Premium : &nbsp;
                        <Typography component="span" variant="subtitle2">
                          <TextField
                            fullWidth
                            value={calculateTotalPremiumm(policy.options.filter((item) => item.accept)).toFixed(2)}
                            InputProps={{ readOnly: true, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                          />
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container spacing={gridSpacing} sx={{ display: { sm: 'flex', xs: 'block' }, justifyContent: 'flex-end' }}>
                  <Grid item sm={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography component="div" variant="h5">
                          Total Policy Premium
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography component="div" variant="subtitle1">
                              <Typography component="span" variant="subtitle2">
                                <TextField
                                  sx={{ mt: 1 }}
                                  fullWidth
                                  variant="outlined"
                                  value={calculateTotalPolicyPremium(
                                    policy.data?.idAirport && policy.data?.idAirport !== -1 ? [policy.data] : aircrafts,
                                    policy.options
                                  )}
                                  InputProps={{ readOnly: true, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                                />
                              </Typography>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Card>
              <Grid item xs={12}>
                <PolicyInvoices isLoading={isLoading} policyId={policy.data.idImmutable} />
              </Grid>
            </Card>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Card>
              <Grid item xs={12}>
                <PolicyClaims
                  isLoading={isLoading}
                  policyId={policy.data.id}
                  aircrafts={[...aircrafts]}
                  users={users}
                  customerOrgId={customerOrgId}
                  customerContacts={customerContacts?.filter((item) => item.idCustomerOrganization === policy.data?.idCustomerOrganization)}
                />
              </Grid>
            </Card>
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default PolicyDetails;
