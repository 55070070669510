import MainLayout from 'layout/MainLayout';
import Company from 'views/Admin/Company';
import RoleGuard from 'component/Auth/RoleGuard';
import AuthGuard from 'component/Auth/AuthGuard';
import CompanyUsers from 'views/Admin/Company/Users';
import Options from 'views/Admin/Options';
const AdminRoutes = {
  path: '/admin',
  element: (
    <AuthGuard>
      <RoleGuard roles={['SuperAdmin']}>
        <MainLayout />
      </RoleGuard>
    </AuthGuard>
  ),
  children: [
    { path: '/admin/company', element: <Company /> },
    { path: '/admin/company/:companyId/users', element: <CompanyUsers /> },
    { path: '/admin/options', element: <Options />}
  ]
};

export default AdminRoutes;
