/**
 * axios setup to use mock service
 */

import axios from 'axios';

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

const initializeAxios = (context) => {
  const instance = axios.create({
    baseUrl: process.env.REACT_APP_API_ENDPOINT
  });

  // interceptor for http
  instance.interceptors.response.use(
    (response) => {
      const newToken = response.headers['newtokenauth']?.split(' ')[1];
      if (newToken) {
        localStorage.setItem('jwt_token', newToken); // Update the token in local storage
      }
      return response;
    },
    async (error) => {
      if (error?.response?.status === 401) {
        await context.logout();
      } else if (error?.response?.status === 500) {
        await context.badRequest();
      }
      return Promise.reject();
    }
  );

  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('jwt_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      config.headers.Authorization = null;
    }
    return config;
  });

  return instance;
};

export default initializeAxios;
