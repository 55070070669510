import { Tooltip, IconButton } from '@mui/material';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

import CustomTable from 'component/CustomTable';

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import initializeAxios from 'utils/axios';
import useAuth from 'hooks/useAuth';
const columns = [
  {
    id: 'invoiceDate',
    label: 'Invoice Date'
  },
  {
    id: 'endDate',
    label: 'Invoice End Date'
  },
  {
    id: 'billDate',
    label: 'Bill Date'
  },
  {
    id: 'amount',
    label: 'Amount ( $ )'
  }
];

const PolicyInvoices = ({ isLoading, policyId }) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const [invoices, setInvoices] = useState([]);
  const { companyId } = useParams();

  const handleGoToDetail = (invoice) => {
    navigate(`/company/${companyId}/policy/${policyId}/invoice/${invoice.id}`);
  };

  useEffect(() => {
    const getInvoicesByPolicyImmutable = async () =>{
      const response = await axios.get(`/api/invoices/companyId/${companyId}/policy/${policyId}`);
      setInvoices([...response.data]);
    }

    getInvoicesByPolicyImmutable();
  }, []);

  return (
    <>
      <CustomTable isLoading={isLoading} columns={columns} data={invoices} hasPagination={false} title="Invoices" hasCreateButton={false}>
        {(row, index) => (
          <>
            <Tooltip title="Invoice Details">
              <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleGoToDetail(invoices[index])}>
                <TextSnippetIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </CustomTable>
    </>
  );
};

export default PolicyInvoices;
