import React, { useEffect, useState } from 'react';
import { Box, Stepper, Step, StepLabel, Button, Typography, MobileStepper, useTheme, useMediaQuery } from '@mui/material';

import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Breadcrumb from 'component/Breadcrumb';
import AgentQuote from '../../../component/AgentQuote';
import AgencyQuote from '../../../component/AgencyQuote';
import AircraftQuoteCustomerOrg from '../../../component/CustomerOrgQuote';
import AircraftQuoteCustomerContact from '../../../component/CustomerContactQuote';
import AircraftQuoteAircraft from '../AircraftQuoteComponent';
import AircraftQuotePilots from '../Pilot';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';
import AircraftQuoteLienHolder from '../OtherInterests';
import AircraftQuotePilotHours from '../PilotHours';
import AircraftQuotePolicy from '../../../component/PolicyQuote';
import AircraftQuoteCoverageOptions from '../CoverageOptions';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const steps = ['Agency', 'Agent', 'Customer Organization', 'Customer Contact', 'Aircraft', 'Policy', 'Coverage Options'];
const aircraftSubSteps = ['Other Interests', 'Pilots'];
const pilotSteps = ['Pilot Requirements'];

const AircraftQuoteStepper = () => {
  const theme = useTheme();
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [activeStep, setActiveStep] = useState(0);
  const [subActiveStep, setSubActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [isAircraftStep, setIsAircraftStep] = useState(false);
  const [isPilotStep, setIsPilotStep] = useState(false);
  const { companyId, quoteId } = useParams();

  const [selectedAgency, setSelectedAgency] = useState({});
  const [selectedAgent, setSelectedAgent] = useState({});
  const [selectedCustomerOrg, setSelectedCustomerOrg] = useState({});
  const [selectedCustomerContact, setSelectedCustomerContact] = useState({});
  const [selectedAircraft, setSelectedAircraft] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState({});
  const [selectedPilots, setSelectedPilots] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [activePilot, setActivePilot] = useState({});
  const [activeAircraft, setActiveAircraft] = useState({});
  const [activeQuoteId, setActiveQuoteId] = useState(null);
  const [aircraftRelations, setAircraftRelations] = useState([]);

  const [disableNext, setDisableNext] = useState([
    { id: 0, val: false, multi: false },
    { id: 1, val: false, multi: false },
    { id: 2, val: false, multi: false },
    { id: 3, val: false, multi: false },
    { id: 4, val: false, multi: false },
    { id: 5, val: false, multi: true },
    { id: 6, val: false, multi: false }
  ]);

  const [aircraftDisableNext] = useState([
    { id: 0, val: true, multi: false },
    { id: 1, val: true, multi: true }
  ]);

  const handleSelect = (emitActiveStep, row) => {
    const tempArray = [...disableNext];
    console.log('Handle Select', emitActiveStep, row);
    switch (emitActiveStep) {
      case 0: {
        setSelectedAgency(row);
        tempArray[emitActiveStep].val = true;
        handleNext();
        handleSelectSave(row.id, 'idAgency');
        break;
      }
      case 1: {
        setSelectedAgent(row);
        tempArray[emitActiveStep].val = true;
        handleSelectSave(row.id, 'idAgent');
        handleNext();
        break;
      }
      case 2: {
        setSelectedCustomerOrg(row);
        tempArray[emitActiveStep].val = true;
        handleSelectSave(row.id, 'idCustomerOrg');
        handleNext();
        break;
      }
      case 3: {
        setSelectedCustomerContact(row);
        tempArray[emitActiveStep].val = true;
        handleSelectSave(row.id, 'idCustomerContact');
        handleNext();
        break;
      }
      case 4: {
        if (selectedAircraft.findIndex((item) => item.id === row.id) !== -1) {
          const tempAircrafts = [...selectedAircraft];
          const tempRelations = [...aircraftRelations];
          const index = tempAircrafts.findIndex((item) => item.id === row.id);
          tempAircrafts.splice(index, 1);
          handleDeleteAircraftQuote(row);
          if (tempAircrafts?.length === 0) {
            tempArray[emitActiveStep].val = false;
          }
          setSelectedAircraft(tempAircrafts);
          if (tempRelations.findIndex((item) => item.aircraftId === row.id) !== -1) {
            const relationIndex = tempRelations.findIndex((item) => item.aircraftId === row.id);
            tempRelations.splice(relationIndex, 1);
            setAircraftRelations(tempRelations);
          }
        } else {
          setIsAircraftStep(true);
          setActiveAircraft(row);
          setSelectedAircraft([...selectedAircraft, row]);
          handleCreateAircraftQuote(row);
          setSelectedInterests([]);
          setSelectedPilots([]);
          tempArray[emitActiveStep].val = true;
        }
        break;
      }
      case 5: {
        setSelectedPolicy(row);
        break;
      }
      case 6: {
        tempArray[emitActiveStep].val = true;
      }
    }
    setDisableNext(tempArray);
  };

  const handleSelectSave = async (value, item) => {
    await axios.put(`/api/quotes/${quoteId}/companyId/${companyId}`, {
      quote: {
        [item]: value
      }
    });
  };

  const handleCreateAircraftQuote = async (row) => {
    const response = await axios.post(`/api/quotes/${quoteId}/aircraft/${row.id}/companyId/${companyId}`);
    setActiveQuoteId(response.data.id);
  };

  const handleDeleteAircraftQuote = async (row) => {
    await axios.delete(`/api/quotes/${quoteId}/aircraft/${row.id}/companyId/${companyId}`);
  };
  const handleDeleteRelationQuote = async (row, type) => {
    await axios.delete(`/api/quotes/${quoteId}/aircraft/${activeQuoteId}/${type}/${row.id}/companyId/${companyId}`);
  };

  const handleCreateRelationQuote = async (row, type) => {
    await axios.post(`/api/quotes/${quoteId}/aircraft/${activeQuoteId}/${type}/${row.id}/companyId/${companyId}`);
  };

  const handleCreateRelations = () => {
    aircraftRelations.forEach(async (relation) => {
      if (relation.otherInterests?.length > 0) {
        await axios.post(`/api/aircraft/${activeAircraft.id}/lienholder/companyId/${companyId}/multiple`, {
          data: relation.otherInterests.map((item) => ({ idAircraft: relation.aircraftId, idLienHolder: item.id }))
        });
      }
      if (relation.pilotRelations?.length > 0) {
        await axios.post(`/api/aircraft/${activeAircraft.id}/pilots/companyId/${companyId}/multiple`, {
          data: relation.pilotRelations.map((item) => ({ idAircraft: relation.aircraftId, idPilot: item.id }))
        });
      }
    });
  };

  const handleAircraftSubSelect = (emitActiveStep, row) => {
    const tempArray = [...aircraftDisableNext];
    switch (emitActiveStep) {
      case 0: {
        if (selectedInterests.findIndex((item) => item.id === row.id) !== -1) {
          const tempInterests = [...selectedInterests];
          const index = tempInterests.findIndex((item) => item.id === row.id);
          tempInterests.splice(index, 1);
          handleDeleteRelationQuote(row, 'other');
          setSelectedInterests(tempInterests);
        } else {
          handleCreateRelationQuote(row, 'other');
          setSelectedInterests([...selectedInterests, row]);
        }
        break;
      }
      case 1: {
        console.log('selectedPilots', selectedPilots);
        console.log('row', row);
        if (selectedPilots.findIndex((item) => item.id === row.id) !== -1) {
          const tempPilots = [...selectedPilots];
          const index = tempPilots.findIndex((item) => item.id === row.id);
          tempPilots.splice(index, 1);
          handleDeleteRelationQuote(row, 'pilot');
          if (tempPilots?.length === 0) {
            tempArray[emitActiveStep].val = false;
          }
          setSelectedPilots(tempPilots);
        } else {
          setIsPilotStep(true);
          setActivePilot(row);
          handleCreateRelationQuote(row, 'pilot');
          setSelectedPilots([...selectedPilots, row]);
          tempArray[emitActiveStep].val = true;
        }
        break;
      }
    }
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleFinishSaveQuote = async () => {
    await axios.delete(`/api/quotes/${quoteId}/companyId/${companyId}`);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep + 1 === 6) {
      handleCreateRelations();
      handleFinishSaveQuote();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleMultiNext = () => {
    const relationExitsIndex = aircraftRelations.findIndex((e) => e.aircraftId === activeAircraft.id);
    let tempRelations = [...aircraftRelations];
    tempRelations[relationExitsIndex] = { ...aircraftRelations[relationExitsIndex], pilotRelations: selectedPilots || [] };
    setAircraftRelations(tempRelations);
    setIsAircraftStep(false);
    setSubActiveStep(0);
  };

  const handleOtherNext = () => {
    const relationExitsIndex = aircraftRelations.findIndex((e) => e.aircraftId === activeAircraft.id);
    if (relationExitsIndex !== -1) {
      let tempRelations = [...aircraftRelations];
      tempRelations[relationExitsIndex] = { ...aircraftRelations[relationExitsIndex], otherInterests: selectedInterests };
      setAircraftRelations(tempRelations);
    } else {
      const tempRelation = { aircraftId: activeAircraft?.id, otherInterests: selectedInterests || [] };
      setAircraftRelations([...aircraftRelations, tempRelation]);
    }
  };

  const handleSubNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (subActiveStep === 1 && isAircraftStep && !isPilotStep) {
      handleMultiNext();
    } else if (subActiveStep === 1 && isAircraftStep && isPilotStep) {
      setIsPilotStep(false);
    } else {
      handleOtherNext();
      setSubActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubBack = () => {
    if (subActiveStep === 0 && isAircraftStep && !isPilotStep) {
      setIsAircraftStep(false);
    } else if (subActiveStep === 1 && isAircraftStep && isPilotStep) {
      setIsPilotStep(false);
    } else {
      setSubActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleFinish = () => {
    navigate(`/company/${companyId}/policy/${selectedPolicy.id}`);
  };

  useEffect(() => {
    const getSavedAircraftQuote = async () => {
      const response = await axios.get(`/api/quotes/${quoteId}/companyId/${companyId}`);
      console.log(response);

      const { Agency, Agent, CustomerOrganization, CustomerContact, AircraftQuote } = response.data;
      const tempNext = [...disableNext];
      if (Agency) {
        setSelectedAgency({ ...Agency });
        tempNext[0].val = true;
      }
      if (Agent) {
        setSelectedAgent({ ...Agent });
        tempNext[1].val = true;
      }
      if (CustomerOrganization) {
        setSelectedCustomerOrg({ ...CustomerOrganization });
        tempNext[2].val = true;
      }
      if (CustomerContact) {
        setSelectedCustomerContact({ ...CustomerContact });
        tempNext[3].val = true;
      }
      if (AircraftQuote?.length > 0) {
        setSelectedAircraft(AircraftQuote?.map((item) => item?.Aircraft));
        setAircraftRelations(
          AircraftQuote.map((item) => ({
            aircraftId: item.idAircraft,
            otherInterests: item.OtherInterestQuote?.length > 0 ? item?.OtherInterestQuote?.map((other) => other?.LienHolder) : [],
            pilotRelations: item.PilotQuote?.length > 0 ? item?.PilotQuote?.map((other) => other?.Pilot) : []
          }))
        );
        tempNext[4].val = true;
      }
      setDisableNext(tempNext);
    };

    getSavedAircraftQuote();
  }, []);

  return (
    <>
      <Breadcrumb sx={{ mt: { lg: 0, xs: 2 } }}>
        <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography
          component={Link}
          to={`/company/${companyId}/workflow/aircraft-quote/`}
          variant="subtitle2"
          color="inherit"
          className="link-breadcrumb"
        >
          Aircraft Quote
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Quote workflow
        </Typography>
      </Breadcrumb>
      <Box sx={{ width: '100%' }}>
        <Typography variant="h3" sx={{ mb: 2, display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
          Aircraft Quote
        </Typography>
        {!isMobile && (
          <>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {(isAircraftStep || isPilotStep) && (
              <Stepper activeStep={subActiveStep}>
                {aircraftSubSteps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            )}
            {isPilotStep && (
              <Stepper activeStep={1}>
                {pilotSteps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            )}
          </>
        )}
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box sx={{ mt: 4 }}>
              {activeStep === 0 && (
                <AgencyQuote
                  companyId={companyId}
                  selectedAgency={selectedAgency}
                  activeStep={activeStep}
                  emitSelectedAgency={handleSelect}
                  handleNext={handleNext}
                />
              )}
              {activeStep === 1 && (
                <AgentQuote
                  companyId={companyId}
                  selectedAgency={selectedAgency}
                  selectedAgent={selectedAgent}
                  activeStep={activeStep}
                  emitSelectedAgent={handleSelect}
                  handleNext={handleNext}
                />
              )}
              {activeStep === 2 && (
                <AircraftQuoteCustomerOrg
                  companyId={companyId}
                  selectedCustomerOrg={selectedCustomerOrg}
                  activeStep={activeStep}
                  emitSelectedCustomerOrg={handleSelect}
                  handleNext={handleNext}
                />
              )}
              {activeStep === 3 && (
                <AircraftQuoteCustomerContact
                  companyId={companyId}
                  selectedCustomerContact={selectedCustomerContact}
                  selectedCustomerOrg={selectedCustomerOrg}
                  activeStep={activeStep}
                  emitSelectedCustomerContact={handleSelect}
                  handleNext={handleNext}
                />
              )}
              {activeStep === 4 && !isAircraftStep && !isPilotStep && (
                <AircraftQuoteAircraft
                  companyId={companyId}
                  selectedAircraft={selectedAircraft}
                  selectedCustomerOrg={selectedCustomerOrg}
                  selectedCustomerContact={selectedCustomerContact}
                  activeStep={activeStep}
                  emitSelectedAircraft={handleSelect}
                  handleNext={handleNext}
                />
              )}
              {activeStep === 5 && !isAircraftStep && !isPilotStep && (
                <AircraftQuotePolicy
                  companyId={companyId}
                  selectedAgency={selectedAgency}
                  selectedAgent={selectedAgent}
                  selectedContact={selectedCustomerContact}
                  selectedCustomerOrg={selectedCustomerOrg}
                  handleNext={handleNext}
                  emitSelectedPolicy={handleSelect}
                  activeStep={activeStep}
                />
              )}
              {activeStep === 6 && !isAircraftStep && !isPilotStep && (
                <AircraftQuoteCoverageOptions
                  companyId={companyId}
                  selectedAircrafts={selectedAircraft}
                  selectedPolicy={selectedPolicy}
                  emitCoverage={handleSelect}
                />
              )}
              {subActiveStep === 1 && isAircraftStep && !isPilotStep && (
                <AircraftQuotePilots
                  companyId={companyId}
                  selectedPilots={selectedPilots}
                  activeStep={subActiveStep}
                  emitSelectedPilot={handleAircraftSubSelect}
                  selectedCustomerOrg={selectedCustomerOrg}
                  selectedAircraft={activeAircraft}
                />
              )}
              {subActiveStep === 0 && isAircraftStep && !isPilotStep && (
                <AircraftQuoteLienHolder
                  companyId={companyId}
                  selectedInterests={selectedInterests}
                  emitSelectedInterest={handleAircraftSubSelect}
                  activeStep={subActiveStep}
                  currentAircraft={activeAircraft}
                />
              )}
              {subActiveStep === 1 && isAircraftStep && isPilotStep && (
                <AircraftQuotePilotHours companyId={companyId} selectedPilot={activePilot} />
              )}
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              {isMobile ? (
                <MobileStepper
                  variant="progress"
                  steps={isAircraftStep ? (isPilotStep ? 1 : 2) : 7}
                  position="static"
                  activeStep={isAircraftStep ? (isPilotStep ? 1 : subActiveStep) : activeStep}
                  sx={{ maxWidth: 400, flexGrow: 1 }}
                  nextButton={
                    <Button
                      size="large"
                      onClick={isAircraftStep ? handleSubNext : activeStep === steps.length - 1 ? handleFinish : handleNext}
                      disabled={
                        isAircraftStep
                          ? !aircraftDisableNext?.find((item) => item.id === subActiveStep).val
                          : !disableNext.find((item) => item.id === activeStep).val
                      }
                    >
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                      {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </Button>
                  }
                  backButton={
                    <Button
                      size="large"
                      disabled={activeStep === 0 || activeStep === 6}
                      onClick={isAircraftStep || isPilotStep ? handleSubBack : handleBack}
                    >
                      {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                      Back
                    </Button>
                  }
                />
              ) : (
                <>
                  <Button
                    color="inherit"
                    size="large"
                    disabled={activeStep === 0 || activeStep === 6}
                    onClick={isAircraftStep || isPilotStep ? handleSubBack : handleBack}
                    sx={{ mr: 1, width: '200px', height: '60px', fontSize: '1.5rem' }}
                  >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight fontSize="large" /> : <KeyboardArrowLeft fontSize="large" />}
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {activeStep !== 5 && (
                    <Button
                      onClick={isAircraftStep ? handleSubNext : activeStep === steps.length - 1 ? handleFinish : handleNext}
                      size="large"
                      sx={{ width: '200px', height: '60px', fontSize: '1.5rem' }}
                      disabled={
                        isAircraftStep
                          ? !aircraftDisableNext?.find((item) => item.id === subActiveStep).val
                          : !disableNext.find((item) => item.id === activeStep).val
                      }
                    >
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                      {theme.direction === 'rtl' ? <KeyboardArrowLeft fontSize="large" /> : <KeyboardArrowRight fontSize="large" />}
                    </Button>
                  )}
                </>
              )}
            </Box>
          </React.Fragment>
        )}
      </Box>
    </>
  );
};

export default AircraftQuoteStepper;
