import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import initializeAxios from 'utils/axios';

// material-ui
import { Typography, IconButton, Tooltip } from '@mui/material';
import useAuth from 'hooks/useAuth';

// project import
import Breadcrumb from 'component/Breadcrumb';

// assets
import DeleteIcon from '@mui/icons-material/Delete';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import LoadingOverlay from 'component/LoadingOverlay';
import CustomTable from 'component/CustomTable';
import AgentDialog from 'component/AgentDialog';

// ==============================|| CONTACT LIST ||============================== //

const Agents = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const axios = initializeAxios(auth);

  const columns = [
    { id: 'displayName', label: 'Display Name' },
    { id: 'email', label: 'Email' }
  ];

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [agents, setAgents] = useState([]);
  const [agent, setAgent] = useState({});
  const [actionType, setActionType] = useState('');
  const [agentId, setAgentId] = useState(null);
  const { companyId, agencyId } = useParams();
  const handleClickOpendialog = () => {
    setActionType('Create');
    setOpen(true);
  };

  const handleClosedialog = () => {
    setOpen(false);
    setAgent({});
  };

  const handleSave = async (agentDialog) => {
    const displayName = `${agentDialog?.firstname} ${agentDialog?.lastname}`;
    switch (actionType) {
      case 'Create': {
        const response = await axios.post(`/api/agent/companyId/${companyId}`, {
          agent: {
            ...agentDialog,
            displayName,
            idAgency: +agencyId
          }
        });
        setAgents([...agents, response.data]);
        break;
      }

      case 'Edit': {
        const response = await axios.put(`/api/agent/${agentId}/companyId/${companyId}`, {
          agent: {
            displayName,
            email: agentDialog?.email,
            firstname: agentDialog?.firstname,
            lastname: agentDialog?.lastname
          }
        });
        const tempArray = [...agents];
        const index = tempArray.findIndex((e) => e.id === agentId);
        tempArray[index] = {
          ...response.data
        };
        setAgents(tempArray);
        break;
      }

      case 'Delete': {
        await axios.delete(`/api/agent/${agentId}/companyId/${companyId}`);
        const tempArray = [...agents];
        const index = tempArray.findIndex((e) => e.id === agentId);
        tempArray.splice(index, 1);
        setAgents(tempArray);
        break;
      }
    }
    handleClosedialog();
  };

  const handleEdit = (editAgent) => {
    setAgent({
      firstname: editAgent?.firstname,
      lastname: editAgent?.lastname,
      email: editAgent?.email
    });
    setActionType('Edit');
    setAgentId(editAgent.id);
    setOpen(true);
  };

  const handleDelete = (deleteAgent) => {
    setAgentId(deleteAgent.id);
    setActionType('Delete');
    setOpen(true);
  };

  const handleGoToDetail = (agentDetails) => {
    navigate(`/company/${companyId}/agency/${agencyId}/agents/${agentDetails.id}`);
  };

  useEffect(() => {
    const getAgents = async () => {
      setIsLoading(true);
      const result = await axios.get(`/api/agent/agencyId/${agencyId}/companyId/${companyId}`);
      setAgents([...result.data]);
      setIsLoading(false);
    };

    getAgents();
  }, [companyId]);

  return (
    <>
      <Breadcrumb title="Agents" sx={{ mt: { xs: 2, lg: 0 } }}>
        <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography component={Link} to={`/company/${companyId}/agency/`} variant="subtitle2" color="inherit" className="link-breadcrumb">
          Agencies
        </Typography>
        <Typography
          component={Link}
          to={`/company/${companyId}/agency/${agencyId}`}
          variant="subtitle2"
          color="inherit"
          className="link-breadcrumb"
        >
          Agency Details
        </Typography>
        <Typography variant="subtitle2" color="inherit" className="link-breadcrumb">
          Agents
        </Typography>
      </Breadcrumb>
      <AgentDialog agent={agent} actionType={actionType} handleClosedialog={handleClosedialog} handleAgentSave={handleSave} open={open} />
      <LoadingOverlay loading={isLoading} />
      <CustomTable
        isLoading={isLoading}
        title="Agents"
        data={agents}
        columns={columns}
        hasCreateButton={true}
        isDisabled={!auth.writePrivledge(auth.user, 'Agency_Adm')}
        handleOpenCreate={handleClickOpendialog}
      >
        {(row) => (
          <>
            <Tooltip title="Edit Option" placement="top">
              <span>
                <IconButton
                  color="primary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Agency_Adm')}
                  onClick={() => handleEdit(row)}
                >
                  <EditTwoToneIcon fontSize='large'/>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete Option" placement="top">
              <span>
                <IconButton
                  color="secondary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Agency_Adm')}
                  onClick={() => handleDelete(row)}
                >
                  <DeleteIcon fontSize='large'/>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Agent Details">
              <span>
                <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleGoToDetail(row)}>
                  <TextSnippetIcon fontSize='large'/>
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
      </CustomTable>
    </>
  );
};

export default Agents;
