/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// material-ui
import { Grid, Button, TextField, Slide, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';

// project import
import { gridSpacing } from 'config.js';

// assets
import { useParams } from 'react-router';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// eslint-disable-next-line react/prop-types
const PolicyOptionAircraftDialog = ({
  open,
  handleClosedialog,
  handlePolicyOptionAircraftSave,
  actionType,
  policyOptionAircraft,
  title
}) => {
  const [newPolicyOptionAircraft, setNewPolicyOptionAircraft] = useState({});
  const { companyId } = useParams();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewPolicyOptionAircraft({
      ...newPolicyOptionAircraft,
      [name]: value
    });
  };

  const handleSave = () => {
    const returnPolicyOptionAircraft = {
      policyOptionAircraft: {
        shortCode: newPolicyOptionAircraft.shortCode,
        description: newPolicyOptionAircraft.description,
        idCompany: +companyId
      }
    };

    return handlePolicyOptionAircraftSave(returnPolicyOptionAircraft);
  };

  useEffect(() => {
    if (policyOptionAircraft) {
      setNewPolicyOptionAircraft({ ...policyOptionAircraft });
    }
  }, [policyOptionAircraft, companyId]);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClosedialog}
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
            '& .MuiDialog-paper': {
              m: 0,
              borderRadius: 0,
              maxWidth: 450,
              minWidth: 200,
              maxHeight: '100%',
              height: '100vh',
              width: { xs: '100vw' }
            }
          }
        }}
      >
        <DialogTitle>
          {actionType === 'Create'
            ? `New ${title}`
            : actionType === 'Edit'
            ? `Edit ${title}`
            : actionType === 'Delete'
            ? `Delete ${title}`
            : ''}
        </DialogTitle>
        <DialogContent>
          {actionType !== 'Delete' ? (
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Short Code"
                  name="shortCode"
                  variant="outlined"
                  required
                  value={newPolicyOptionAircraft?.shortCode || ''}
                  error={!newPolicyOptionAircraft?.shortCode?.trim()}
                  helperText={!newPolicyOptionAircraft?.shortCode?.trim() ? 'Short cannot be empty' : ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Description"
                  name="description"
                  multiline
                  minRows={3}
                  maxRows={6}
                  value={newPolicyOptionAircraft?.description || ''}
                  variant="outlined"
                  error={!newPolicyOptionAircraft?.description?.trim()}
                  helperText={!newPolicyOptionAircraft?.description?.trim() ? 'Description Cannot be empty' : ''}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          ) : (
            <DialogContentText>Confirm if you want to delete the this Policy Option Aircraft</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={
              actionType !== 'Delete' ? !newPolicyOptionAircraft?.shortCode?.trim() || !newPolicyOptionAircraft?.description?.trim() : false
            }
          >
            {actionType !== 'Delete' ? 'Save' : 'Delete'}
          </Button>
          <Button variant="text" onClick={handleClosedialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PolicyOptionAircraftDialog;
