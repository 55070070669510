import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Button, Slide } from '@mui/material';
import { gridSpacing } from 'config.js';
import React, { useState, useEffect } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
const PilotOptionDialog = ({ open, handleClosedialog, handlePilotOptionSave, pilotOption, actionType, totalHours, tempTotalHours }) => {
  const [newPilotHour, setNewPilotHour] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewPilotHour({
      ...newPilotHour,
      [name]: value
    });
  };

  const handleClose = () => {
    setNewPilotHour({});
    handleClosedialog();
  };

  const handleSave = () => {
    handlePilotOptionSave(newPilotHour);
  };

  useEffect(() => {
    if (Object.values(pilotOption).length > 0) {
      const propPilotOption = { ...pilotOption };
      setNewPilotHour(propPilotOption);
    }
  }, [pilotOption]);
  const saveInput = React.useRef();
  
  return (
    <Dialog
      open={open}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          saveInput.current.click();
        }
      }}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'flex-end',
          width: '100%',
          '& .MuiDialog-paper': {
            m: 0,
            borderRadius: 0,
            maxWidth: 450,
            maxHeight: '100%',
            height: '100vh'
          }
        }
      }}
    >
      <DialogTitle>
        {actionType === 'Create' || actionType === 'CreateWorkflow'
          ? 'New Pilot Hour'
          : actionType === 'Edit'
          ? 'Edit Pilot Hour'
          : actionType === 'Delete'
          ? 'Delete Pilot Hour'
          : ''}
      </DialogTitle>
      <DialogContent>
        {actionType !== 'Delete' ? (
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                label="description"
                name="description"
                variant="outlined"
                value={newPilotHour?.description || ''}
                error={!newPilotHour?.description?.trim()}
                helperText={!newPilotHour?.description?.trim() ? 'Description cannot be empty' : ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Hours"
                id="filled-start-adornment1"
                name="hours"
                value={newPilotHour?.hours || ''}
                type="number"
                onChange={handleChange}
                variant="outlined"
                error={newPilotHour?.hours <= 0 || !newPilotHour?.hours || +newPilotHour?.hours + +tempTotalHours > totalHours}
                helperText={
                  ((newPilotHour?.hours <= 0 || !newPilotHour?.hours) && 'Hours Cannot Be Empty') ||
                  (+newPilotHour?.hours + +tempTotalHours > totalHours && 'Cannot Exceed total hours')
                }
              />
            </Grid>
          </Grid>
        ) : (
          <DialogContentText>Confirm if you want to delete the this Pilot Hour</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={
            actionType !== 'Delete'
              ? newPilotHour?.hours <= 0 ||
                !newPilotHour?.hours ||
                !newPilotHour?.description?.trim() ||
                +newPilotHour?.hours + +tempTotalHours > totalHours
              : false
          }
        >
          {actionType !== 'Delete' ? 'Save' : 'Delete'}
        </Button>
        <Button variant="text" onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PilotOptionDialog;
