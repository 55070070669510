import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Slide } from '@mui/material';
import React, { useEffect, useState } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const UpdatePilotHours = ({ open, handleClosedialog, handleSave, hours }) => {
  const [pilotTotalHours, setPilotTotalHours] = useState(0);

  const handleChange = (event) => {
    const { value } = event.target;
    setPilotTotalHours(value);
  }

  const handleHourSave = () => {
    handleSave(pilotTotalHours)
  }

  useEffect(() => {
    setPilotTotalHours(hours)
  }, [])

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClosedialog}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiDialog-paper': {
            borderRadius: 0,
            width: '350px'
          }
        }
      }}
    >
      <DialogTitle>Update Total Pilot Hours</DialogTitle>
      <DialogContent>
        <TextField
          sx={{ mt: 1 }}
          fullWidth
          type="number"
          label="Total Hours"
          name="totalHours"
          variant="outlined"
          value={pilotTotalHours || ''}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions className="actions">
        <Button variant="contained" color="primary" onClick={handleHourSave}>
          Save
        </Button>
        <Button variant="text" onClick={handleClosedialog} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdatePilotHours;
