export const calculateTotalPremiumm = (array) =>
  array?.map((item) => calculateTotal(item)).reduce((total, next) => total + (next ? next : 0), 0);

export const calculateTotalPolicyPremium = (aircrafts, policyOptions) => {
  const aircraftsPremium = calculateTotalPremiumm(aircrafts);
  const policyOptionsPremium = calculateTotalPremiumm(policyOptions);
  return (aircraftsPremium + policyOptionsPremium).toFixed(2);
};


export const calculateDeductible = (row) => {
  const calculate = [];
  for (let key in row) {
    if (key.includes('Deductible')) {
      calculate.push(+row[key]);
    }
  }

  return calculate.reduce((total, next) => total + (next ? next : 0), 0);
};

export const calculateTotalDeductible = (array) =>
  array?.map((item) => calculateDeductible(item)).reduce((total, next) => total + (next ? next : 0), 0);

export const calculateTotalPropertyPremium = (array, property) => {
  if (array.length) {
    return array.map((item) => +item?.[property]).reduce((total, next) => total + (next ? next : 0), 0);
  } else {
    return 0;
  }
};

export const calculateTotal = (row) => {
  const calculate = [];
  for (let key in row) {
    if (key.includes('Amount') || key === 'taxesFees' || key === 'premium') {
      calculate.push(+row[key]);
    }
  }

  return calculate.reduce((total, next) => total + (next ? next : 0), 0);
};
