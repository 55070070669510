import { CardContent, Grid, Typography, TextField, Divider, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { gridSpacing } from 'config.js';
import { DatePicker } from '@mui/x-date-pickers';
import React, { useEffect, useState } from 'react';
import { formatDate } from 'utils/FormatUtils';
const PilotPolicyDetails = ({ pilot }) => {
  const [propsPilot, setPropsPilot] = useState({});
  useEffect(() => {
    setPropsPilot({
      ...pilot,
      PilotCerts: pilot.PilotDetailsCerts.map((item) => item.Options.val).join(', '),
      PilotRatings: pilot.PilotDetailsRating.map((item) => item.Options.val).join(', ')
    });
  }, [pilot]);
  return (
    <>
      <CardContent>
        <Grid container spacing={gridSpacing}>
          <Grid item sm={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="div" variant="h5">
                  Pilot Information
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component="div" variant="subtitle1">
                  First Name : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="firstname"
                      value={propsPilot?.firstname || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component="div" variant="subtitle1">
                  Last Name : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="lastname"
                      value={propsPilot?.lastname || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography component="div" variant="subtitle1">
                  Cell Phone : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="cellphone"
                      value={propsPilot?.cellphone || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component="div" variant="subtitle1">
                  Phone Number: &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="phone"
                      value={propsPilot?.phone || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component="div" variant="subtitle1">
                  Email : &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="email"
                      value={propsPilot?.email || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component="div" variant="subtitle1">
                  Date of Birth: &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <DatePicker
                      disableToolbar
                      sx={{mt: 1}}
                      variant="inline"
                      margin="normal"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          readOnly: true
                        }
                      }}
                      value={formatDate(propsPilot?.dateofbirth) || null}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component="div" variant="subtitle1">
                  Age: &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField sx={{ mt: 1 }} fullWidth name="effectiveDate" value={`${propsPilot?.age} years` || ''} variant="outlined" />
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="div" variant="h5">
                  Pilot Certifications
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component="div" variant="subtitle1">
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      fullWidth
                      name="PilotCerts"
                      label="Pilot Certifications"
                      value={propsPilot.PilotCerts || ''}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component="div" variant="subtitle1">
                  <Typography component="span" variant="subtitle2">
                    <TextField
                      fullWidth
                      name="pilotRatings"
                      label="Pilot Ratings"
                      value={propsPilot.PilotRatings || ''}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Divider />
      <CardContent>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="div" variant="h5">
                  Pilot Requirements - Total Hours
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography component="div" variant="subtitle1">
                  Total Hours: &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField fullWidth sx={{ mt: 1 }} name="totalHours" value={propsPilot?.totalHours || ''} variant="outlined" />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography component="div" variant="subtitle1">
                 Multi-Engine: &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField fullWidth sx={{ mt: 1 }} name="totalHours" value={propsPilot?.multiEngine || ''} variant="outlined" />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography component="div" variant="subtitle1">
                  Retract Gear: &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField fullWidth sx={{ mt: 1 }} name="totalHours" value={propsPilot?.retractGear || ''} variant="outlined" />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography component="div" variant="subtitle1">
                  Make and Model: &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField fullWidth sx={{ mt: 1 }} name="totalHours" value={propsPilot?.makeAndModel || ''} variant="outlined" />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography component="div" variant="subtitle1">
                  Hours Flown in the last year: &nbsp;
                  <Typography component="span" variant="subtitle2">
                    <TextField fullWidth sx={{ mt: 1 }} name="lastYearTotal" value={propsPilot?.lastYearTotal || ''} variant="outlined" />
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container spacing={gridSpacing}>
          <Grid item sm zeroMinWidth>
            <Typography component="div" variant="h3">
              Pilot Hours
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>description</TableCell>
                  <TableCell>hours</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {propsPilot?.PilotDetailsOption?.map((option) => (
                  <React.Fragment key={option.id}>
                    <TableRow>
                      <TableCell>{option.description}</TableCell>
                      <TableCell>{option.hours}</TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};

export default PilotPolicyDetails;
