import {
  Grid,
  Card,
  CardHeader,
  Typography,
  Divider,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
  Box
} from '@mui/material';
import CustomTableCell from 'component/CustomTableCell';
import TablePaginationActions from 'component/TablePaginationActions';
import Add from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import { AccessTime } from '@mui/icons-material';
import { displayPhoneNumber, formatDate } from 'utils/FormatUtils';
import { useEffect, useState } from 'react';

const CustomTable = ({
  title,
  columns,
  data,
  isLoading,
  children,
  selectedRows = [],
  isSearch = false,
  hasActions = true,
  hasCreateButton = false,
  handleOpenCreate = () => {},
  hasUpdateHours = false,
  handleUpdateHours = () => {},
  hasPagination = false,
  hasClickableRows = false,
  emitClickedRow = () => {},
  pagination = {},
  isDisabled = null,
  handleChangePage = () => {},
  handleChangeRowsPerChange = () => {}
}) => {
  const [searchval, setSearchval] = useState('');
  const [rows, setRows] = useState(data);
  const [showSearch, setShowSearch] = useState(false);

  // Display Map
  const columnDisplayMap = {
    list: (row, title) => displayList(row, title),
    type: (row) => displayTypes(row),
    // Add more mappings for other columns
    customerOrganization: (row) => displayCustomerOrganizationName(row),
    agentDisplayName: (row, title) => displayAgentsName(row, title),
    agentName: (row) => displayAgentUserProfile(row),
    fullName: (row) => displayFullName(row),
    beginDate: (row) => formatDate(row.beginDate).format('MM/DD/YYYY'),
    endDate: (row) => formatDate(row.endDate).format('MM/DD/YYYY'),
    dateofbirth: (row) => formatDate(row.dateofbirth).format('MM/DD/YYYY'),
    claimDate: (row) => formatDate(row.claimDate).format('MM/DD/YYYY'),
    invoiceDate: (row) => formatDate(row.invoiceDate).format('MM/DD/YYYY'),
    billDate: (row) => formatDate(row.billDate).format('MM/DD/YYYY'),
    dateReceieved: (row) => formatDate(row.dateReceived).format('MM/DD/YYYY'),
    companyName: (row) => displayCompanyName(row),
    accept: (row) => displayAccept(row),
    policyStatus: (row) => displayPolicyStatus(row),
    customerContact: (row) => displayCustomerContactName(row),
    nestedPolicyNumber: (row) => displayPolicyNumber(row),
    amount: (row) => displayAmount(row.amount),
    nestedClaimStatus: (row) => displayNestedClaimStatus(row),
    agencyName: (row) => displayAgencyName(row),
    quoteUserProfile: (row) => displayQuoteUserProfile(row),
    quoteStatus: (row) => displayQuoteStatus(row),

  };

  // Display Functions
  const displayList = (list, type) => {
    return type !== 'Pilots'
      ? list?.['UserProfile_RoleAssignment']?.map((e) => e.Role.name).join(', ')
      : list?.PilotCerts.map((e) => e.Options.val).join(', ');
  };

  const displayTypes = (types) => {
    return types?.Options?.val;
  };

  const displayFullName = (row) => {
    return `${row?.firstname} ${row?.lastname}`;
  };

  const displayCustomerOrganizationName = (row) => {
    return row?.CustomerOrganization?.name;
  };

  const displayCompanyName = (row) => {
    return row?.Company?.name;
  };

  const displayAccept = (row) => {
    return row?.accept ? 'true' : 'false';
  };

  const displayAgentsName = (row, type) => {
    const typeText = type.includes('Aircraft')
      ? 'Aircraft'
      : type.includes('Policy')
      ? 'Policy'
      : type.includes('Claim')
      ? 'Claim'
      : 'Pilot';
    return row?.[`UserProfile_${typeText}_idCompanyUserToUserProfile`]?.displayName;
  };

  const displayPolicyStatus = (row) => {
    return row?.['Options_Policy_idPolicyStatusToOptions']?.val;
  };

  const displayNestedClaimStatus = (row) => {
    return row?.Options?.val;
  };

  const isPhoneNumberColumn = (columnId) => /phone|Phone/.test(columnId);

  const displayCustomerContactName = (row) => {
    return row?.CustomerContact?.name;
  };

  const displayPolicyNumber = (row) => {
    return row?.Policy?.policyNumber;
  };

  const displayAmount = (amount) => {
    const newAmount = +amount;
    return newAmount.toFixed(2);
  };

  const displayAgencyName = (row) => {
    return row?.Agency?.name;
  };

  const displayAgentUserProfile = (row) => {
    return row?.Agent?.displayName;
  };

  const displayQuoteUserProfile = (row) => {
    return row?.UserProfile?.displayName;
  };

  const displayQuoteStatus = (row) => {
    let count = 0;
    if (row?.Agency) count++;
    if (row?.Agent) count++;
    if (row?.CustomerOrganization) count++;
    if (row?.customerContact) count++;
    if (row?.AircraftQuote) count++;
    if (row?.Airport) count++;

    return `${count}/6`;
  };

  const handleSearch = (search) => {
    if (!search) {
      setSearchval(search);
      setRows(data);
    } else {
      const filteredRows = data?.filter((row) => {
        switch (title) {
          case 'Agency':
          case 'Other Interests':
          case 'Customer Organizations':
          case 'Contacts': {
            return row?.name?.toString()?.toLowerCase().includes(search?.toString()?.toLowerCase());
          }
          case 'Pilots': {
            return (
              row?.firstname?.toString()?.toLowerCase().includes(search?.toString()?.toLowerCase()) ||
              row?.lastname?.toString()?.toLowerCase().includes(search?.toString()?.toLowerCase())
            );
          }
          case 'Agents': {
            return row?.displayName?.toString()?.toLowerCase().includes(search?.toString()?.toLowerCase());
          }
          case 'Aircrafts': {
            return row?.registrationNo?.toString()?.toLowerCase().includes(search?.toString()?.toLowerCase());
          }
          case 'Airports': {
            return (
              row?.name?.toString()?.toLowerCase().includes(search?.toString()?.toLowerCase()) ||
              row?.code?.toString()?.toLowerCase().includes(search?.toString()?.toLowerCase())
            );
          }
        }
      });
      setSearchval(search);
      setRows(filteredRows);
    }
  };

  const cancelSearch = () => {
    setSearchval('');
    handleSearch('');
    setShowSearch(false);
  };
  const handleClickSearch = () => setShowSearch((prev) => !prev);

  useEffect(() => {
    setRows(data);
  }, [data]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              <>
                <Typography
                  component="div"
                  className="card-header"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between', // Space between title and icon group
                    alignItems: 'center',
                    padding: '0 16px',
                    width: '100%'
                  }}
                >
                  <Typography component="div" className="card-header">
                    {title}
                  </Typography>
                  <Typography component="div" sx={{ display: 'flex', flexDirection: 'row' }}>
                    {isSearch && (
                      <IconButton
                        color="primary"
                        aria-label="search"
                        size="large"
                        disabled={isDisabled ? isDisabled : false}
                        onClick={handleClickSearch}
                      >
                        {showSearch ? <CancelIcon fontSize="large" /> : <SearchIcon fontSize="large" />}
                      </IconButton>
                    )}
                    {hasCreateButton && (
                      <Tooltip title={`Add ${title}`} placement="top">
                        <span>
                          <IconButton
                            color="primary"
                            aria-label="Create"
                            size="large"
                            sx={{ mr: 'auto', display: 'inline-flex' }}
                            disabled={isDisabled ? isDisabled : false}
                            onClick={handleOpenCreate}
                          >
                            <Add fontSize="large" />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}
                    {hasUpdateHours && (
                      <Tooltip title={`Update Total Pilot Hours`} placement="top">
                        <span>
                          <IconButton
                            color="primary"
                            aria-label="Update Pilot Hours"
                            size="large"
                            sx={{ mr: 'auto' }}
                            onClick={handleUpdateHours}
                          >
                            <AccessTime fontSize="large" />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}
                  </Typography>
                </Typography>
              </>
            }
          />
          <Divider />
          {isSearch && showSearch && (
            <>
              <Box
                sx={{
                  backgroundColor: '#f0f4f8', // Light background color for distinction
                  padding: '8px 16px',
                  borderRadius: '8px', // Rounded corners // Space between search bar and table
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' // Subtle shadow for depth
                }}
              >
                <TextField
                  variant="outlined"
                  value={searchval}
                  fullWidth
                  sx={{ borderRadius: '-4px' }}
                  onChange={(search) => handleSearch(search.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: searchval && (
                      <InputAdornment position="end">
                        <IconButton onClick={cancelSearch}>
                          <CancelIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
              <Typography sx={{ borderBottom: '0.5px solid black' }} />
            </>
          )}
          <TableContainer>
            <Table size="medium" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align="right">
                      {column.label}
                    </TableCell>
                  ))}
                  {hasActions && <CustomTableCell align="right">Actions</CustomTableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  []
                ) : rows.length ? (
                  rows?.map((row, index) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        cursor: hasClickableRows ? 'pointer' : 'inherit',
                        '&:hover': { backgroundColor: hasClickableRows && 'rgba(128, 128, 128, 0.1)' },
                        backgroundColor: selectedRows.find((select) => select.id === row.id) ? 'rgba(51, 102, 255, 0.3)' : 'inherit'
                      }}
                      onClick={() => hasClickableRows && emitClickedRow(row)}
                    >
                      {columns.map((column) => (
                        <TableCell key={column.id} align="right">
                          {columnDisplayMap[column.id]
                            ? columnDisplayMap[column.id](row, title)
                            : isPhoneNumberColumn(column.id)
                            ? displayPhoneNumber(row)
                            : row[column.id]}
                        </TableCell>
                      ))}
                      {hasActions && <TableCell align="right">{children(row, index)}</TableCell>}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>{`Please add ${title} to table`}</TableCell>
                  </TableRow>
                )}
              </TableBody>
              {hasPagination && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[25, 50, 100]}
                      count={pagination?.count}
                      page={pagination?.page}
                      rowsPerPage={pagination?.rowsPerPage}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page'
                        },
                        native: true
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerChange}
                      ActionsComponent={TablePaginationActions}
                      sx={(theme) => ({
                        '.MuiTablePagination-spacer': { display: 'flex', [theme.breakpoints.down('sm')]: { display: 'none' } }
                      })}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CustomTable;
