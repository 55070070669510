import { CardContent, Grid, Typography, TextField } from '@mui/material';
import { gridSpacing } from 'config.js';
const LeinholderPolicyDetails = ({ other }) => {
  return (
    <CardContent>
      <Grid container spacing={gridSpacing}>
        <Grid item sm zeroMinWidth>
          <Typography component="div" variant="h5">
            Customer Organization
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item sm={4} xs={12}>
              <Typography variant="subtitle2">
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Name"
                  variant="outlined"
                  value={other?.name || ''}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Typography>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="subtitle2">
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Address 1"
                  variant="outlined"
                  value={other?.address1 || ''}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Typography>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="subtitle2">
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Address 2"
                  variant="outlined"
                  value={other?.address2 || ''}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Typography>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="subtitle2">
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="City"
                  variant="outlined"
                  value={other?.city || ''}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Typography>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="subtitle2">
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="State"
                  variant="outlined"
                  value={other?.state || ''}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Typography>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="subtitle2">
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Zipcode"
                  variant="outlined"
                  value={other?.zip || ''}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default LeinholderPolicyDetails;
