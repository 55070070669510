import { Chip } from '@mui/material';
import AirportDialog from 'component/AirportDialog';
import CustomTable from 'component/CustomTable';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
const columns = [
  {
    id: 'name',
    label: 'Airport Name'
  },
  {
    id: 'code',
    label: 'IATA Code'
  },
  {
    id: 'agencyName',
    label: 'Agency Name'
  },
  {
    id: 'agentName',
    label: 'Agent Display Name'
  }
];
const AirportQuoteComponent = ({ selectedAgency, selectedAgent, selectedAirport, activeStep, emitAirportSelect }) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [airports, setAirports] = useState([]);
  const { companyId } = useParams();

  const handleClickOpendialog = () => {
    setOpen(true);
  };
  const handleClosedialog = () => {
    setOpen(false);
  };

  const handleSave = async (airport) => {
    setIsLoading(true);
    const response = await axios.post(`/api/airport/companyId/${companyId}`, {
      airport: {
        idAgency: selectedAgency?.id,
        idAgent: selectedAgent?.id,
        idCompanyUser: airport?.idCompanyUser,
        code: airport?.code?.toUpperCase()
      },
      detailsFlag: false
    });
    emitAirportSelect(activeStep, response.data)
    setIsLoading(false);
  };

  const handleAirportSelect = (row) => {
    emitAirportSelect(activeStep, row)
  };

  useEffect(() => {
    const getAirports = async () => {
      setIsLoading(true);
      const result = await axios.get(`/api/airport/companyId/${companyId}`);
      setAirports(result.data?.airports);
      setIsLoading(false);
    };

    getAirports();
  }, [companyId]);

  return (
    <>
      <CustomTable
        title="Airports"
        isLoading={isLoading}
        data={airports}
        columns={columns}
        hasPagination={false}
        isSearch={true}
        selectedRows={[selectedAirport]}
        hasCreateButton={true}
        handleOpenCreate={handleClickOpendialog}
        hasClickableRows={true}
        emitClickedRow={handleAirportSelect}
      >
        {(row) => (
          <Chip
            label="Select"
            color="primary"
            variant={selectedAirport.id === row.id ? 'filled' : 'outlined'}
            sx={{ color: selectedAirport.id === row.id ? '#fff' : '#000' }}
            clickable
          />
        )}
      </CustomTable>
      <AirportDialog open={open} handleClosedialog={handleClosedialog} handleAirportSave={handleSave} actionType="Create" data={{}} isAirportQuote={true}/>
    </>
  );
};

export default AirportQuoteComponent;
