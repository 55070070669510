import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

// Mui Imports
import { Grid, Typography, Card, CardContent, TextField, MenuItem, Divider, Button, Tooltip, IconButton } from '@mui/material';
import Breadcrumb from 'component/Breadcrumb';
import LoadingOverlay from 'component/LoadingOverlay';
import CustomTable from 'component/CustomTable';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';
import { gridSpacing } from 'config.js';
import { validRegex, phoneNumberRegex } from 'utils/FormatUtils';

import TextSnippetIcon from '@mui/icons-material/TextSnippet';

const policyColumns = [
  {
    id: 'agentDisplayName',
    label: 'Agents Display Name'
  },
  {
    id: 'policyNumber',
    label: 'Policy Number'
  },
  {
    id: 'policyStatus',
    label: 'Policy Status'
  }
];

const claimColumns = [
  {
    id: 'agentDisplayName',
    label: 'Agents Display Name'
  },
  {
    id: 'claimDate',
    label: 'Claim Date'
  },
  {
    id: 'type',
    label: 'Claim Status'
  }
];

const ContactsDetails = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const axios = initializeAxios(auth);
  const { companyId, customerOrgId, contactId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [contact, setContact] = useState({});
  const [customerOrgs, setCustomerOrgs] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [claims, setClaims] = useState([]);

  const [pagination, setPagination] = useState({
    count: 0,
    page: 0,
    rowsPerPage: 25
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setContact({
      ...contact,
      [name]: value
    });
  };

  const handleSave = async () => {
    setIsLoading(true);
    const response = await axios.put(`/api/customer-contact/${contactId}/companyId/${companyId}`, {
      contact: {
        idCustomerOrganization: contact?.idCustomerOrganization,
        name: contact?.name,
        email: contact?.email,
        phone: contact?.phone,
        title: contact?.title
      }
    });
    setContact({ ...response.data });
    setIsLoading(false);
  };

  const handleGoToDetail = (item, claim = false) => {
    const url = claim ? `/company/${companyId}/policy/${item.idPolicy}/claim/${item.id}` : `/company/${companyId}/policy/${item.id}`;
    navigate(url);
  };

  const handleChangePage = async (event, page) => {
    setPagination({ ...pagination, page });
  };

  const handleChangeRowsPerChange = (event) => {
    setPagination({ ...pagination, rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  useEffect(() => {
    const getPolicies = async () => {
      setIsLoading(true);
      const response = await axios.get(`/api/policy/companyId/${companyId}/customerOrgId/${customerOrgId}/pagination`, {
        params: {
          page: pagination.page,
          rowsPerPage: pagination.rowsPerPage
        }
      });
      setPolicies(response.data.policies);
      setPagination({ ...pagination, count: response.data.count });
      setIsLoading(false);
    };

    getPolicies();
  }, [companyId, customerOrgId, pagination.rowsPerPage, pagination.page]);

  useEffect(() => {
    const getCustomerContact = async () => {
      setIsLoading(true);
      const response = await axios.get(`/api/customer-contact/${contactId}/companyId/${companyId}`);
      setContact({ ...response.data });
      setClaims(response.data.Claim);
      setIsLoading(false);
    };

    const getCustomerOrgs = async () => {
      const result = await axios.get(`/api/customer-org/companyId/${companyId}`);
      setCustomerOrgs(result.data);
    };

    getCustomerOrgs();
    getCustomerContact();
  }, []);
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumb title="Organization Contact Details" divider={false} sx={{ mt: { lg: 0, xs: 2 } }}>
            <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
              Home
            </Typography>
            <Typography
              component={Link}
              to={`/company/${companyId}/customer-org/`}
              variant="subtitle2"
              color="inherit"
              className="link-breadcrumb"
            >
              Customer Organization
            </Typography>
            <Typography
              component={Link}
              to={`/company/${companyId}/customer-org/${customerOrgId}`}
              variant="subtitle2"
              color="inherit"
              className="link-breadcrumb"
            >
              {contact?.CustomerOrganization?.name}
            </Typography>
            <Typography
              component={Link}
              to={`/company/${companyId}/customer-org/${customerOrgId}/contacts`}
              variant="subtitle2"
              color="inherit"
              className="link-breadcrumb"
            >
              Contacts
            </Typography>
            <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
              Contact Details
            </Typography>
          </Breadcrumb>
        </Grid>
      </Grid>
      <LoadingOverlay loading={isLoading} />
      <Grid item lg={8} xs={12}>
        <Grid container spacing={gridSpacing} xs={12} item sx={{ flexWrap: { lg: 'nowrap', sm: 'wrap' } }} className="grid-container">
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Card>
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm zeroMinWidth>
                    <Typography component="div" variant="h5">
                      Organizaition Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            name="title"
                            label="Title"
                            variant="outlined"
                            value={contact?.title || ''}
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'CustomerOrg_Adm')
                            }}
                            onChange={handleChange}
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        {customerOrgs?.length && (
                          <Typography variant="subtitle2">
                            <TextField
                              sx={{ mt: 1 }}
                              fullWidth
                              label="Customer Organization"
                              variant="outlined"
                              name="idCustomerOrganization"
                              value={contact?.idCustomerOrganization || ''}
                              select
                              InputProps={{
                                readOnly: !auth.writePrivledge(auth.user, 'CustomerOrg_Adm')
                              }}
                              onChange={handleChange}
                            >
                              {customerOrgs.map((type) => (
                                <MenuItem key={type.id} value={type.id}>
                                  {type.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm zeroMinWidth>
                    <Typography component="div" variant="h5">
                      Contact Information
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <Grid container spacing={3}>
                    <Grid item sm={4} xs={12}>
                      <Typography variant="subtitle2">
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          label="Name"
                          variant="outlined"
                          name="name"
                          value={contact?.name || ''}
                          error={!contact?.name}
                          helperText={!contact?.name ? 'Cannot Be Empty' : ''}
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'CustomerOrg_Adm')
                          }}
                          onChange={handleChange}
                        />
                      </Typography>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Typography variant="subtitle2">
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          label="email"
                          name="email"
                          variant="outlined"
                          value={contact?.email || ''}
                          error={contact?.email?.trim()?.length > 0 ? !contact?.email.match(validRegex) : false}
                          helperText={
                            contact?.email?.trim()?.length > 0 ? (!contact?.email.match(validRegex) ? 'Must be a valid Email' : '') : ''
                          }
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'CustomerOrg_Adm')
                          }}
                          onChange={handleChange}
                        />
                      </Typography>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Typography variant="subtitle2">
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          name="phone"
                          label="Phone Number"
                          variant="outlined"
                          value={contact?.phone || ''}
                          error={contact?.phone?.trim()?.length > 0 ? !contact?.phone.match(phoneNumberRegex) : false}
                          helperText={
                            contact?.phone?.trim()?.length > 0
                              ? !contact?.phone.match(phoneNumberRegex)
                                ? 'Must be a valid phone number'
                                : ''
                              : ''
                          }
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'CustomerOrg_Adm')
                          }}
                          onChange={handleChange}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between" spacing={gridSpacing}>
                      <Grid item sm={6} md={4}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSave}
                          disabled={
                            !contact?.name ||
                            !contact?.idCustomerOrganization ||
                            (contact?.email?.trim()?.length > 0 ? !contact?.email.match(validRegex) : false) ||
                            (contact?.phone?.trim()?.length > 0 ? !contact?.phone.match(phoneNumberRegex) : false) ||
                            !auth.writePrivledge(auth.user, 'CustomerOrg_Adm')
                          }
                        >
                          Save Changes
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <CustomTable
                  columns={policyColumns}
                  data={policies}
                  isLoading={isLoading}
                  title="Policy"
                  hasPagination={true}
                  pagination={pagination}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerChange={handleChangeRowsPerChange}
                >
                  {(row) => (
                    <>
                      <Tooltip title="Policy Details">
                        <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleGoToDetail(row)}>
                          <TextSnippetIcon fontSize='large'/>
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </CustomTable>
              </CardContent>
              <Divider />
              <CardContent>
                <CustomTable columns={claimColumns} data={claims} isLoading={isLoading} title="Claims">
                  {(row) => (
                    <>
                      <Tooltip title="Claim Details">
                        <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleGoToDetail(row, true)}>
                          <TextSnippetIcon fontSize='large'/>
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </CustomTable>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactsDetails;
