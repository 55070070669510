import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc)


export const formatDate = (row) => {
  if(row){
    return dayjs(row);
  } else{
    return null
  }
};

export const displayPhoneNumber = (row) => {
  const phone = row?.phone ? row?.phone : row?.registeredPhone ? row?.registeredPhone : '';
  return phone ? `(${phone?.toString().slice(0, 3)}) ${phone?.toString().slice(3, 6)}-${phone?.toString().slice(6)}` : '';
};

export const validRegex = new RegExp(/\S+@\S+\.\S+/);
export const phoneNumberRegex = /^\d{10}$/;
