import CustomTable from 'component/CustomTable';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';

import { Chip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import LoadingOverlay from 'component/LoadingOverlay';
import AircraftDialog from 'component/AircraftDialog';

const columns = [
  {
    id: 'registrationNo',
    label: 'Registration Number'
  },
  {
    id: 'airportCode',
    label: 'Airport Code'
  }
];

const AircraftQuoteComponent = ({
  companyId,
  activeStep,
  selectedAircraft,
  selectedCustomerOrg,
  selectedCustomerContact,
  emitSelectedAircraft
}) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [aircrafts, setAircrafts] = useState([]);

  const handleClosedialog = () => {
    setOpen(false);
  };

  const handleSave = async (aircraftDialog) => {
    setIsLoading(true);
    const aircraft = { ...aircraftDialog.aircraft, idCustomerOrganization: +selectedCustomerOrg.id };
    const response = await axios.post(`/api/aircraft/companyId/${companyId}`, {
      aircraft,
      detailsFlag: false
    });
    emitSelectedAircraft(activeStep, response.data);
    handleClosedialog();
    setIsLoading(false);
  };

  const handleClickOpendialog = () => {
    setOpen(true);
  };

  const handleAircraftSelect = (row) => {
    emitSelectedAircraft(activeStep, row);
  };

  useEffect(() => {
    const getAircrafts = async () => {
      setIsLoading(true);
      const result = await axios.get(`/api/aircraft/companyId/${companyId}/customerOrgId/${selectedCustomerOrg.id}`);
      setAircrafts(result.data);
      setIsLoading(false);
    };

    getAircrafts();
  }, [companyId]);

  return (
    <>
      <LoadingOverlay loading={isLoading} />
      <AircraftDialog
        open={open}
        handleAircraftSave={handleSave}
        handleClosedialog={handleClosedialog}
        aircraft={{}}
        actionType="CreateWorkflow"
        selectedCustomerOrg={selectedCustomerOrg}
        selectedContact={selectedCustomerContact}
      />
      <CustomTable
        isLoading={isLoading}
        columns={columns}
        data={aircrafts}
        isSearch={true}
        hasClickableRows={true}
        emitClickedRow={handleAircraftSelect}
        title="Aircrafts"
        hasPagination={false}
        selectedRows={[...selectedAircraft]}
        hasCreateButton={true}
        handleOpenCreate={handleClickOpendialog}
      >
        {(row) => (
          <Chip
            label="Select"
            color="primary"
            variant={selectedAircraft.find((item) => item.id === row.id) ? 'filled' : 'outlined'}
            sx={{ color: selectedAircraft.find((item) => item.id === row.id) ? '#fff' : '#000' }}
            clickable
            onClick={() => handleAircraftSelect(row)}
          />
        )}
      </CustomTable>
    </>
  );
};

export default AircraftQuoteComponent;
