//import PropTypes from 'prop-types';
import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

// third party
import jwtDecode from 'jwt-decode';

// project import
import axios from 'axios';
import Loader from 'component/Loader/Loader';

// eslint-disable-next-line no-unused-vars
import { ACCOUNT_INITIALISE, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

export const initialState = {
  isLoggedIn: false,
  isInitialised: false,
  user: null
};

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

const navigate = (url) => {
  window.location.href = url;
};

// eslint-disable-next-line no-unused-vars
const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }

  const decoded = jwtDecode(serviceToken);
  console.log('Decode token ', decoded.exp > Date.now() / 1000);
  console.log('Decoded', decoded);
  return decoded.exp > Date.now() / 1000;
};

export const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('jwt_token', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('jwt_token');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT ||============================== //

const JWTContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => {},
  badRequest: () => {},
  loginWithData: (user) => {
    console.log('jwtContext: loginWithData', user);
    return Promise.resolve();
  },
  writePrivledge: (user, type) => {
    return writePrivledge(user, type);
  }
});

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  const login = async () => {
    try {
      const response = await axios.post('/request');
      const { url } = await response.data;
      navigate(url);
    } catch (err) {
      console.log(err);
      if(err.code == 'ERR_NETWORK') {
        alert('Aircraft Service Not Available');
      }
    }
  };

  const loginWithData = async (user) => {
    console.log('jwtContext: loginWithData', user);
    dispatch({ type: ACCOUNT_INITIALISE, payload: { isLoggedIn: true, user } });
  };

  const logout = () => {
    console.log('Logout');
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const badRequest = () => {
    console.log("Bad Request");
    setSession(null);
    navigate('/pages/error/error2');
  }

  const writePrivledge = (user, type) => {
    return (
      user?.['UserProfile_RoleAssignment']?.some((item) => item.Role.name === type) ||
      user?.user?.['UserProfile_RoleAssignment']?.some((item) => item.Role.name === type)  ||
      user?.['UserProfile_RoleAssignment']?.some((item) => item.Role.name === 'SuperAdmin') ||
      user?.user?.['UserProfile_RoleAssignment']?.some((item) => item.Role.name === 'SuperAdmin')
    );
  };

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem('jwt_token');
        console.log('serviceToken JWTToken: ', serviceToken);
        if (serviceToken && verifyToken(serviceToken)) {
          console.log('jwtContext: verifyToke true');
          console.log('Im here in context');
          setSession(serviceToken);
          const response = await axios.get(`/api/user/profile`);
          const { user } = response.data;
          dispatch({ type: ACCOUNT_INITIALISE, payload: { isLoggedIn: true, user } });
        } else {
          console.log('jwtContext: verifyToke False');
          dispatch({ type: ACCOUNT_INITIALISE, payload: { isLoggedIn: false, user: null } });
        }
      } catch (err) {
        console.error(err);
        if(err.code == 'ERR_NETWORK') {
          alert('Aircraft Service Not Available');
        }
        dispatch({
          type: ACCOUNT_INITIALISE,
          payload: {
            isLoggedIn: false,
            user: null
          }
        });
      }
    };

    init();
  }, []);

  if (!state.isInitialised) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider value={{ ...state, user: state.user, login, logout, loginWithData, writePrivledge, badRequest }}>{children}</JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.array
};

export default JWTContext;
