import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import initializeAxios from 'utils/axios';

// material-ui
import { Typography, IconButton, Tooltip } from '@mui/material';
import useAuth from 'hooks/useAuth';
import CustomTable from 'component/CustomTable';

// project import
import Breadcrumb from 'component/Breadcrumb';

// assets
import DeleteIcon from '@mui/icons-material/Delete';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import LoadingOverlay from 'component/LoadingOverlay';
import CustomerContactDialog from 'component/CustomerContactDialog';

// ==============================|| CONTACT LIST ||============================== //

const Contacts = () => {
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const navigate = useNavigate();

  const columns = [
    {
      id: 'name',
      label: 'Name'
    },
    {
      id: 'email',
      label: 'Email'
    },
    {
      id: 'phone',
      label: 'Phone'
    },
    {
      id: 'title',
      label: 'Title'
    }
  ];

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [actionType, setActionType] = useState('');
  const [contactId, setContactId] = useState(null);
  const { companyId, customerOrgId } = useParams();
  const [contact, setContact] = useState({});

  const handleClickOpendialog = () => {
    setActionType('Create');
    setOpen(true);
  };
  const handleClosedialog = () => {
    setOpen(false);
    setContact({});
  };


  const handleSave = async (contactDialog) => {
    switch (actionType) {
      case 'Create': {
        const response = await axios.post(`/api/customer-contact/companyId/${companyId}`, {
          contact: {
            ...contactDialog,
            idCustomerOrganization: +customerOrgId
          }
        });
        const newContact = {
          ...response.data
        };
        setContacts([...contacts, newContact]);

        break;
      }

      case 'Edit': {
        const response = await axios.put(`/api/customer-contact/${contactId}/companyId/${companyId}`, {
          contact: {
            ...contactDialog
          }
        });
        const tempArray = [...contacts];
        const index = contacts.findIndex((e) => e.id === contactId);
        tempArray[index] = {
          ...response.data
        };
        setContacts(tempArray);

        break;
      }

      case 'Delete': {
        await axios.delete(`/api/customer-contact/${contactId}/companyId/${companyId}`);
        const tempArray = [...contacts];
        const index = tempArray.findIndex((e) => e.id === contactId);
        tempArray.splice(index, 1);
        setContacts(tempArray);
        break;
      }
    }
    handleClosedialog();
  };

  const handleEdit = (editContact) => {
    setContact({
      name: editContact.name,
      title: editContact.title,
      email: editContact.email,
      phone: editContact.phone
    });
    setActionType('Edit');
    setContactId(editContact.id);
    setOpen(true);
  };

  const handleDelete = (deleteUser) => {
    setContactId(deleteUser.id);
    setActionType('Delete');
    setOpen(true);
  };

  const handleGoToDetail = (detail) => {
    navigate(`/company/${companyId}/customer-org/${customerOrgId}/contacts/${detail.id}`);
  };

  useEffect(() => {
    const getCustomerOrg = async () => {
      setIsLoading(true);
      const result = await axios.get(`/api/customer-contact/customerOrgId/${customerOrgId}/companyId/${companyId}`);
      setContacts([...result.data]);
      setIsLoading(false);
    };

    getCustomerOrg();
  }, [customerOrgId, companyId]);

  return (
    <>
      <Breadcrumb title="Contacts" sx={{ mt: { lg: 0, xs: 2 } }}>
        <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography
          component={Link}
          to={`/company/${companyId}/customer-org`}
          variant="subtitle2"
          color="inherit"
          className="link-breadcrumb"
        >
          Customer Organizaitions
        </Typography>
        <Typography
          component={Link}
          to={`/company/${companyId}/customer-org/${customerOrgId}`}
          variant="subtitle2"
          color="inherit"
          className="link-breadcrumb"
        >
          Customer Organizaition Details
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Contacts
        </Typography>
      </Breadcrumb>
      <CustomerContactDialog
        open={open}
        actionType={actionType}
        handleClosedialog={handleClosedialog}
        contact={contact}
        handleContactSave={handleSave}
      />
      <LoadingOverlay loading={isLoading} />
      <CustomTable title="Contacts" isLoading={isLoading} data={contacts} columns={columns} hasCreateButton={true} handleOpenCreate={handleClickOpendialog} isDisabled={!auth.writePrivledge(auth.user, 'CustomerOrg_Adm')}>
        {(row, index) => (
          <>
            <Tooltip title="Edit Contact">
              <span>
                <IconButton
                  color="primary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'CustomerOrg_Adm')}
                  onClick={() => handleEdit(contacts[index])}
                >
                  <EditTwoToneIcon fontSize='large'/>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete Contact">
              <span>
                <IconButton
                  color="secondary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'CustomerOrg_Adm')}
                  onClick={() => handleDelete(contacts[index])}
                >
                  <DeleteIcon fontSize='large'/>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Contact Details">
              <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleGoToDetail(row)}>
                <TextSnippetIcon fontSize='large'/>
              </IconButton>
            </Tooltip>
          </>
        )}
      </CustomTable>
    </>
  );
};

export default Contacts;
