import React, { useState } from 'react';
//import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Typography, Grid, Button, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Box } from '@mui/material';

// project import
//import AuthLogin from './GoogleLogin';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
// assets
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';
import { useDispatch } from 'react-redux';
import * as actionTypes from 'store/actions';

const PasswordHandler = ({ password = false, isReset = false, isCreate = false, token = '', email = '', emitIsPassword }) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const dispatchSnack = useDispatch();
  const passwordValid = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$#!^%*?&])[A-Za-z\d@$#!^%*?&]{6,}$/;

  const [newUser, setNewUser] = useState({});
  const [error, setError] = useState({
    oldPassword: 'Old Password is Required',
    password: 'Password is Required',
    confirmPassword: 'Password is Required'
  });

  const [passwordError, setPasswordError] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateMatch = (type, item) => {
    let validation = '';
    switch (type) {
      case 'confirmPassword': {
        if (item !== newUser.password) {
          validation = 'Confirm Password does not match Password';
        } else if (!item) {
          validation = 'Confirm Password is Required';
        }
      }
    }
    setError({
      ...error,
      [type]: validation
    });
  };

  const validatePassword = (password) => {
    let validate = '';
    let newErrors = {
      length: password.length < 6,
      uppercase: !/(?=.*[A-Z])/.test(password),
      lowercase: !/(?=.*[a-z])/.test(password),
      number: !/(?=.*\d)/.test(password),
      specialChar: !/(?=.*[@$!^#%*?&])/.test(password)
    };

    if (!password.trim()) {
      validate = 'Password is Required';
      newErrors = {
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        specialChar: false
      };
    }
    setError({
      ...error,
      password: validate,
      confirmPassword: error.confirmPassword === '' ? 'Confirm Password does not match Password' : error.confirmPassword
    });
    setPasswordError(newErrors);
  };

  const validateOld = (password) => {
    let validate = '';
    if (!password.trim()) {
      validate = 'Password is Required';
    }
    setError({
      ...error,
      oldPassword: validate
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'password': {
        validatePassword(value);
        break;
      }
      case 'confirmPassword': {
        validateMatch('confirmPassword', value);
        break;
      }
      case 'oldPassword': {
        validateOld(value);
        break;
      }
    }

    setNewUser({
      ...newUser,
      [name]: value
    });
  };

  const handleReqister = async () => {
    validateMatch('confirmPassword', newUser.confirmPassword);
    let message = {};
    if (error.confirmPassword === '') {
      if (isReset) {
        console.log('im in the correct route');

        message = await axios.put(`/api/user/update/password`, {
          token,
          password: newUser?.password
        });
      } else if (isCreate) {
        message = await axios.post(`/api/user/create/password`, {
          token,
          password: newUser?.password
        });
      } else if (password) {
        message = await axios.put(`/api/user/update/password`, {
          oldPassword: newUser?.oldPassword || '',
          password: newUser.password,
          email
        });
      } else {
        message = await axios.post('/api/user/create/password', {
          password: newUser?.password,
          email
        });
      }
      if (message.data.code === 'error') {
        setError({
          ...error,
          oldPassword: message.data.msg
        });
      } else {
        dispatchSnack({
          type: actionTypes.SNACKBAR_OPEN,
          open: true,
          message: message.data.msg,
          anchorOrigin: { vertical: 'top', horizontal: 'center' }
        });
        if (message.data.code !== 'expired') emitIsPassword(false);
      }
    }
  };
  return (
    <Grid container direction="column" spacing={4} justifyContent="center" alignContent="center">
      <Grid item xs={12}>
        <Grid container justifyContent="center" sx={{ gap: 4 }}>
          {password && (
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-old-password">Old Password</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="outlined-adornment-old-password"
                  name="oldPassword"
                  type="password"
                  value={newUser?.oldPassword || ''}
                  error={!newUser.oldPassword?.trim()}
                  onChange={handleChange}
                  label="Old Password"
                />
                <Box sx={{ mt: 0.5, ml: 2 }}>
                  {error.oldPassword.length > 0 && (
                    <Typography variant="body2" color="error" sx={{ fontSize: '8.16px' }}>
                      {error.oldPassword}
                    </Typography>
                  )}
                </Box>
              </FormControl>
            </Grid>
          )}
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              fullWidth
              id="outlined-adornment-password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={newUser?.password || ''}
              error={!newUser.password?.trim() || !passwordValid.test(newUser.password)}
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            <Box sx={{ mt: 0.5, ml: 2, fontSize: '6px' }}>
              {error.password.length > 0 && (
                <Typography variant="body2" color="error" sx={{ fontSize: '8.16px' }}>
                  {error.password}
                </Typography>
              )}
              {passwordError.length && (
                <Typography variant="body2" color="error" sx={{ fontSize: '8.16px' }}>
                  Password must be at least 6 characters long
                </Typography>
              )}
              {passwordError.uppercase && (
                <Typography variant="body2" color="error" sx={{ fontSize: '8.16px' }}>
                  Password must contain at least one uppercase letter
                </Typography>
              )}
              {passwordError.lowercase && (
                <Typography variant="body2" color="error" sx={{ fontSize: '8.16px' }}>
                  Password must contain at least one lowercase letter
                </Typography>
              )}
              {passwordError.number && (
                <Typography variant="body2" color="error" sx={{ fontSize: '8.16px' }}>
                  Password must contain at least one number
                </Typography>
              )}
              {passwordError.specialChar && (
                <Typography variant="body2" color="error" sx={{ fontSize: '8.16px' }}>
                  Password must contain at least one special character (@, $, !, %, *, ^, #, ?, &)
                </Typography>
              )}
            </Box>
          </FormControl>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
              <OutlinedInput
                fullWidth
                id="outlined-adornment-confirm-password"
                name="confirmPassword"
                type="password"
                value={newUser?.confirmPassword || ''}
                error={!newUser.confirmPassword?.trim() || newUser?.confirmPassword !== newUser?.password}
                onChange={handleChange}
                label="Confirm Password"
              />
              <Box sx={{ mt: 0.5, ml: 2 }}>
                {error.confirmPassword.length > 0 && (
                  <Typography variant="body2" color="error" sx={{ fontSize: '8.16px' }}>
                    {error.confirmPassword}
                  </Typography>
                )}
              </Box>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={4} justifyContent="center" alignContent="center">
        <Grid item xs={12}>
          <Grid container spacing={4} sx={{ p: '0 10px 0 40px', mt: 0.1 }}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleReqister}
                disabled={
                  !newUser.confirmPassword?.trim()?.length > 0 ||
                  newUser?.confirmPassword !== newUser?.password ||
                  !newUser.password?.trim()?.length > 0 ||
                  !passwordValid.test(newUser.password) ||
                  (password.length > 0 && !newUser?.oldPassword)
                }
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PasswordHandler;
