import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

const AdminGuard = ({ children, roles }) => {
  const newLocal = useAuth();
  const { user, isLoggedIn } = newLocal;
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedIn) {
      const userRoles = user['UserProfile_RoleAssignment']?.map((e) => e?.Role?.name) || user?.roles.map((e) => e?.name);
      const failed = userRoles.some((role) => roles.includes(role));
      if (!failed) {
        navigate('/dashboard/default');
      }
    }
  }, [isLoggedIn, navigate]);

  return children;
};

AdminGuard.propTypes = {
  children: PropTypes.object
};

export default AdminGuard;
