import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

// Mui Imports
import { Grid, Typography, Card, CardContent, TextField, Divider, Button } from '@mui/material';
import Breadcrumb from 'component/Breadcrumb';
import LoadingOverlay from 'component/LoadingOverlay';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';
import { gridSpacing } from 'config.js';
import PasswordHandler from 'component/PasswordHandler';
import GoogleLogin from 'views/Login/GoogleLogin';

const UserSettings = () => {
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const { userId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({});
  const [isPassword, setIsPassword] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser({
      ...user,
      [name]: value
    });
  };

  const handleSave = async () => {
    setIsLoading(true);
    const response = await axios.put(`/api/user/${userId}/update`, {
      userProfile: {
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        displayName: `${user.firstname} ${user.lastname}`
      }
    });
    setUser({ ...response.data });
    setIsLoading(false);
  };

  const handleIsPassword = (value) => {
    setIsPassword(value);
  };

  useEffect(() => {
    const getUser = async () => {
      setIsLoading(true);
      const response = await axios.get(`/api/user/profile`);
      setUser({ ...response.data.user });
      setIsLoading(false);
    };

    getUser();
  }, [userId]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumb title="User Settings" divider={false} sx={{ mt: { lg: 0, xs: 2 } }}>
            <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
              Home
            </Typography>
            <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
              Settings
            </Typography>
          </Breadcrumb>
        </Grid>
      </Grid>
      <LoadingOverlay loading={isLoading} />
      <Grid item lg={8} xs={12}>
        <Grid container spacing={gridSpacing} xs={12} item sx={{ flexWrap: { lg: 'nowrap', sm: 'wrap' } }} className="grid-container">
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Card>
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12}>
                    {!isPassword ? (
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="subtitle2">
                            <TextField
                              sx={{ mt: 1 }}
                              fullWidth
                              name="firstname"
                              label="First Name"
                              variant="outlined"
                              value={user?.firstname || ''}
                              error={!user?.firstname}
                              helperText={!user?.firstname ? 'Cannot be Empty' : ''}
                              onChange={handleChange}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="subtitle2">
                            <TextField
                              sx={{ mt: 1 }}
                              fullWidth
                              name="lastname"
                              label="Last Name"
                              variant="outlined"
                              value={user?.lastname || ''}
                              error={!user?.lastname}
                              helperText={!user?.lastname ? 'Cannot be Empty' : ''}
                              onChange={handleChange}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle2">
                            <TextField
                              sx={{ mt: 1 }}
                              fullWidth
                              name="email"
                              label="Email"
                              variant="outlined"
                              value={user?.email || ''}
                              disabled
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <PasswordHandler password={user.password || ''} email={user.email} emitIsPassword={handleIsPassword} />
                    )}
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid
                      container
                      justifyContent="space-between"

                      sx={{
                        sm: {
                          maxHeight: '40px',
                          flexWrap: 'nowrap'
                        }
                      }}
                      spacing={3}
                    >
                      <Grid item sm={4} xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSave}
                          sx={{width: '100%'}}
                          disabled={!user?.firstname || !user?.lastname}
                        >
                          Save Changes
                        </Button>
                      </Grid>
                      {user.idGoogle === null && (
                        <Grid item sm={4} xs={12}>
                          <GoogleLogin isLinkGoogle={true} />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={4}>
                        <Button sx={{width: '100%'}} variant="contained" color="primary" onClick={() => setIsPassword((prev) => !prev)}>
                          {!isPassword ? (user.password?.length > 0 ? 'Update Password' : 'Create Password') : 'Back'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UserSettings;
