import { Document, Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { formatDate } from 'utils/FormatUtils';

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  mainHeader: {
    fontSize: 20,
    marginBottom: 20,
    textAlign: 'center',
    fontWeight: 'bold'
  },
  invoiceContent: {
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    padding: '20px 40px'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    gap: 4
  },
  table: {
    display: 'table',
    width: 'auto',
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: 'row',
  },
  headerTableRow: {
    flexDirection: 'row',
    color: '#d3d3d3',
    borderBottomWidth: 2,
    borderBottomColor: '#d3d3d3'
  },
  tableCell: {
    flex: 1,
    margin: 5,
    padding: 5,
    fontSize: 12,
  },
  bigFont: {
    fontSize: '16px'
  },
  dueAmount: {
    fontSize: '16px',
    color: 'red'
  }
});

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
});

const InvoicePDF = ({ invoice }) => (
  <Document>
    <Page size="LETTER" style={styles.body} wrap>
      <View style={styles.mainHeader}>
        <Text>Invoice</Text>
      </View>
      <View style={styles.invoiceContent}>
        <View style={styles.content} />
        <View style={styles.content}>
          <Text>Customer Name: {invoice?.Policy?.CustomerOrganization?.name}</Text>
          <Text>Invoice Id: {invoice?.id}</Text>
          <Text>Invoice Date: {new Date().toLocaleDateString()}</Text>
          <Text>Invoice Due Date: {formatDate(invoice?.billDate)}</Text>
        </View>
      </View>
      <View style={styles.table}>
        <View style={styles.headerTableRow}>
          <View style={styles.tableCell}>
            <Text>Policy Number</Text>
          </View>
          <View style={styles.tableCell}>
            <Text>Date Range</Text>
          </View>
          <View style={styles.tableCell}>
            <Text>Amount</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCell}>
            <Text>{invoice?.Policy?.policyNumber}</Text>
          </View>
          <View style={styles.tableCell}>
            <Text>
              {formatDate(invoice?.invoiceDate)}-{formatDate(invoice?.endDate)}
            </Text>
          </View>
          <View style={styles.tableCell}>
            <Text>${invoice?.amount}</Text>
          </View>
        </View>
      </View>
      <View style={[styles.table, {marginTop: '100px'}]}>
        <View style={styles.headerTableRow}>
          <View style={styles.tableCell}>
            <Text>Due By</Text>
          </View>
          <View style={styles.tableCell} />
          <View style={styles.tableCell}>
            <Text>Total Due</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCell, styles.bigFont]}>
            <Text>{formatDate(invoice?.billDate)}</Text>
          </View>
          <View style={styles.tableCell} />
          <View style={[styles.tableCell, styles.dueAmount]}>
            <Text>${invoice?.amount}</Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default InvoicePDF;
