import React, { useEffect, useState } from 'react';

// material-ui
import { Chip } from '@mui/material';

// project import
import initializeAxios from 'utils/axios';

// assets
import useAuth from 'hooks/useAuth';
import LoadingOverlay from 'component/LoadingOverlay';
import CustomTable from 'component/CustomTable';
import CustomerContactDialog from 'component/CustomerContactDialog';

// ==============================|| CONTACT LIST ||============================== //

const AircraftQuoteCustomerContact = ({
  companyId,
  activeStep,
  selectedCustomerOrg,
  selectedCustomerContact,
  emitSelectedCustomerContact
}) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const columns = [
    {
      id: 'name',
      label: 'Name'
    },
    {
      id: 'email',
      label: 'Email'
    }
  ];

  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contacts, setContacts] = useState([]);

  const handleClickOpendialog = () => {
    setOpen(true);
  };

  const handleClosedialog = () => {
    setOpen(false);
  };

  const handleCustomerContactSelect = (row) => {
    emitSelectedCustomerContact(activeStep, row);
  };

  const handleSave = async (contactDialog) => {
    setIsLoading(true);
    const response = await axios.post(`/api/customer-contact/companyId/${companyId}`, {
      contact: {
        ...contactDialog,
        idCustomerOrganization: +selectedCustomerOrg.id
      }
    });

    emitSelectedCustomerContact(activeStep, response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    const getContacts = async () => {
      setIsLoading(true);
      const response = await axios.get(`/api/customer-contact/customerOrgId/${selectedCustomerOrg.id}/companyId/${companyId}`);
      setContacts([...response.data]);
      setIsLoading(false);
    };

    getContacts();
  }, [companyId]);

  return (
    <>
      <CustomerContactDialog
        open={open}
        handleClosedialog={handleClosedialog}
        actionType="Create"
        contact={{}}
        handleContactSave={handleSave}
      />
      <LoadingOverlay loading={isLoading} />
      <CustomTable
        isLoading={isLoading}
        data={contacts}
        columns={columns}
        title="Contacts"
        isSearch={true}
        selectedRows={[selectedCustomerContact]}
        hasCreateButton={true}
        hasClickableRows={true}
        emitClickedRow={handleCustomerContactSelect}
        handleOpenCreate={handleClickOpendialog}
      >
        {(row) => (
          <>
            <Chip
              label="Select"
              color="primary"
              variant={selectedCustomerContact.id === row.id ? 'filled' : 'outlined'}
              sx={{ color: selectedCustomerContact.id === row.id ? '#fff' : '#000' }}
              clickable
            />
          </>
        )}
      </CustomTable>
    </>
  );
};

export default AircraftQuoteCustomerContact;
