import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// project import
import { useNavigate, useLocation } from 'react-router';
import * as actionTypes from 'store/actions';

// ==============================|| GUEST GUARD ||============================== //

const InviteGuard = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatchSnack = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  useEffect(() => {
    try {
      if (token) {
        const decoded = jwtDecode(token);
        const now = Math.floor(Date.now() / 1000);
        const timeRemaining = decoded.exp - now;
        if (timeRemaining <= 0) {
          dispatchSnack({
            type: actionTypes.SNACKBAR_OPEN,
            open: true,
            message: 'The token has Expired. Please contact your supervisor.',
            anchorOrigin: { vertical: 'top', horizontal: 'center' }
          });
          navigate('/');
        }
      }
    } catch (err) {
      console.error('Invalid Token');
      navigate('/');
    }
  }, [token, navigate]);

  return children;
};

InviteGuard.propTypes = {
  children: PropTypes.object
};

export default InviteGuard;
