export const EndDateSelections = [
  {
    value: -1,
    label: 'None'
  },
  {
    value: 3,
    label: '3 Months'
  },
  {
    value: 6,
    label: '6 Months'
  },
  {
    value: 9,
    label: '9 Months'
  },
  {
    value: 12,
    label: '1 Year'
  },
  {
    value: 0,
    label: 'Custom'
  }
];
