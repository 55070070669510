import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as actionTypes from 'store/actions';
import { useDispatch } from 'react-redux';

// ==============================|| NAVIGATION SCROLL ||============================== //
let type;
let adminUsers = false;

const NavigationMenu = ({ children }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    switch (pathname.split('/').length) {
      case 0: {
        break;
      }
      case 1: {
        break;
      }
      case 2: {
        break;
      }
      case 3: {
        type = pathname.split('/')[2];
        break;
      }
      case 4: {
        type = pathname.split('/')[3];
        adminUsers = false;
        break;
      }
      default: {
        const path = pathname.split('/');
        type = path[4];
        if (!isNaN(type)) {
          type = path[3];
        }
        adminUsers = true;
        break;
      }
    }
    switch (type) {
      case 'policy': {
        dispatch({ type: actionTypes.MENU_OPEN, isOpen: 'policy' });
        break;
      }
      case 'aircrafts': {
        dispatch({ type: actionTypes.MENU_OPEN, isOpen: 'aircrafts' });
        break;
      }
      case 'pilots': {
        dispatch({ type: actionTypes.MENU_OPEN, isOpen: 'pilots' });
        break;
      }
      case 'customer-org': {
        dispatch({ type: actionTypes.MENU_OPEN, isOpen: 'customer-organizations' });
        break;
      }
      case 'agency': {
        dispatch({ type: actionTypes.MENU_OPEN, isOpen: 'agency' });
        break;
      }
      case 'users': {
        if (!adminUsers) {
          dispatch({ type: actionTypes.MENU_OPEN, isOpen: 'company-users' });
        } else {
          dispatch({ type: actionTypes.MENU_OPEN, isOpen: 'company-users-super'})
        }
        break;
      }
      case 'policyOptionAircraft': {
        dispatch({ type: actionTypes.MENU_OPEN, isOpen: 'policyOptions' });
        break;
      }
      case 'leinholder': {
        dispatch({ type: actionTypes.MENU_OPEN, isOpen: 'leinholder' });
        break;
      }
      case 'aircraft-quote': {
        dispatch({ type: actionTypes.MENU_OPEN, isOpen: 'aircraftQuote' });
        break;
      }

      case 'airport-quote':{
        dispatch({ type: actionTypes.MENU_OPEN, isOpen: 'airportQuote'});
        break;
      }
      case 'company': {
        dispatch({ type: actionTypes.MENU_OPEN, isOpen: 'list-of-company' });
        break;
      }
      case 'options': {
        dispatch({ type: actionTypes.MENU_OPEN, isOpen: 'options' });
        break;
      }
      case 'airports': {
        dispatch({ type: actionTypes.MENU_OPEN, isOpen: 'airport'});
        break;
      }
      case 'insurance': {
        dispatch({ type: actionTypes.MENU_OPEN, isOpen: 'insurance'});
        break;
      }
    }
  }, [pathname]);

  return children || null;
};

NavigationMenu.propTypes = {
  children: PropTypes.node
};

export default NavigationMenu;
