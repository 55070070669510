import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Button, Input, Slide } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { validRegex } from 'utils/FormatUtils';
import { gridSpacing } from 'config.js';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AgentDialog = ({ actionType, open, handleClosedialog, handleAgentSave, agent }) => {
  const [newAgent, setNewAgent] = useState({});
  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewAgent({
      ...newAgent,
      [name]: value
    });
  };

  const handleSave = () => {
    handleAgentSave(newAgent);
  };

  const handleClose = () => {
    setNewAgent({});
    handleClosedialog();
  };

  const saveInput = React.createRef();

  useEffect(() => {
    if (Object.values(agent).length > 0) {
      const propAgent = { ...agent };
      setNewAgent(propAgent);
    }
  }, [agent]);

  return (
    <Dialog
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          saveInput.current.click();
        }
      }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'flex-end',
          height: '100vh',
          '& .MuiDialog-paper': {
            m: 0,
            borderRadius: 0,
            maxWidth: 450,
            maxHeight: '100%',
            height: '100vh'
          }
        }
      }}
    >
      <DialogTitle>
        {actionType === 'Create' ? 'New Agent' : actionType === 'Edit' ? 'Edit Agent' : actionType === 'Delete' ? 'Delete Agent' : ''}
      </DialogTitle>
      <DialogContent>
        {actionType !== 'Delete' ? (
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                error={!newAgent.firstname}
                helperText={!newAgent.firstname ? 'First Name can not be empty' : ''}
                label="First Name"
                name="firstname"
                variant="outlined"
                value={newAgent.firstname || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Last Name"
                error={!newAgent.lastname}
                helperText={!newAgent.lastname ? 'Last Name can not be empty' : ''}
                name="lastname"
                variant="outlined"
                value={newAgent.lastname || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="E-mail"
                name="email"
                variant="outlined"
                input={<Input />}
                value={newAgent?.email || ''}
                error={(newAgent?.email?.length > 0 ? !newAgent?.email?.match(validRegex) : false) || !newAgent.email}
                helperText={
                  (newAgent.email?.length > 0 ? (!newAgent?.email?.match(validRegex) ? 'Invalid Emial' : '') : '') ||
                  (!newAgent.email ? 'Email can not be empty' : '')
                }
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        ) : (
          <DialogContentText>Confirm if you want to delete the this Agent</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          ref={saveInput}
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={
            actionType !== 'Delete'
              ? !newAgent?.firstname ||
                !newAgent?.lastname ||
                (newAgent.email?.length > 0 ? !newAgent?.email?.match(validRegex) : false) ||
                !newAgent.email
              : false
          }
        >
          {actionType !== 'Delete' ? 'Save' : 'Delete'}
        </Button>
        <Button variant="text" onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AgentDialog;
