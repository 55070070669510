import { styled } from '@mui/material/styles';
import { TableCell } from '@mui/material';

const CustomTableCell = styled((props) => <TableCell {...props} />)(() => ({
  padding: '16px 42px',
  width: '200px',
  fontWeight: 'bold'
}));

export default CustomTableCell;
