import { CardContent, Grid, Typography, TextField } from '@mui/material';
import { gridSpacing } from 'config.js';

const AirportPolicyDetails = ({ policy }) => {
  return (
    <>
      <CardContent>
        <Grid container spacing={gridSpacing}>
          <Grid item sm zeroMinWidth>
            <Typography component="div" variant="h5">
              Address
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Country Name"
                  variant="outlined"
                  value={policy?.airportCountryName || ''}
                  disabled
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <TextField sx={{ mt: 1 }} fullWidth label="State" variant="outlined" value={policy?.airportStateCode || ''} disabled />
              </Grid>
              <Grid item lg={4} xs={12}>
                <TextField sx={{ mt: 1 }} fullWidth label="City Name" variant="outlined" disabled value={policy?.airportCityName || ''} />
              </Grid>
              <Grid item lg={4} xs={12}>
                <TextField sx={{ mt: 1 }} fullWidth label="County Name" variant="outlined" disabled value={policy?.airportCounty || ''} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <Grid container spacing={gridSpacing}>
          <Grid item sm zeroMinWidth>
            <Typography component="div" variant="h5">
              Geographic Location
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item lg={6} xs={12}>
                <TextField sx={{ mt: 1 }} fullWidth label="Longitude" variant="outlined" disabled value={policy?.airportLongitude || ''} />
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField sx={{ mt: 1 }} fullWidth label="Latitude" variant="outlined" disabled value={policy?.airportLatitude || ''} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <Grid container spacing={gridSpacing}>
          <Grid item sm zeroMinWidth>
            <Typography component="div" variant="h5">
              Manager Information
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item lg={4} xs={12}>
                <TextField sx={{ mt: 1 }} fullWidth label="Manager Name" variant="outlined" disabled value={policy?.airportManager || ''} />
              </Grid>
              <Grid item lg={4} xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Manager Phone Number"
                  variant="outlined"
                  disabled
                  value={policy?.airportManagerPhone || ''}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Effective Date"
                  variant="outlined"
                  disabled
                  value={policy?.airportEffectiveDate || ''}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};

export default AirportPolicyDetails;
