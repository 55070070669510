import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

const CompanyGuard = ({ children }) => {
  const newLocal = useAuth();
  const { user, isLoggedIn } = newLocal;
  const navigate = useNavigate();
  const { companyId, userId } = useParams();
  useEffect(() => {
    if (isLoggedIn) {
      const roles = user['UserProfile_RoleAssignment']?.map((e) => e?.Role?.name) || user?.roles?.map((e) => e?.name);
      const userIdCompany = user?.idCompany || user?.user?.idCompany;
      const idUser = user?.id || user?.user?.id;
      if (!roles?.includes('SuperAdmin') && +companyId !== userIdCompany && companyId) {
        navigate('/dashboard/default');
      }
      if (userId && +userId !== idUser && !roles?.includes('SuperAdmin')) {
        navigate('/pages/error/error1');
      }
    } else {
      navigate('/');
    }
  }, [isLoggedIn, navigate]);

  return children;
};

CompanyGuard.propTypes = {
  children: PropTypes.object
};

export default CompanyGuard;
