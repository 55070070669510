import CustomTable from 'component/CustomTable';

import initializeAxios from 'utils/axios';
import useAuth from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';
import PilotOptionDialog from 'component/PilotOptionDialog';
import UpdatePilotHours from 'component/UpdatePilotHours';

const columns = [
  {
    id: 'description',
    label: 'Description'
  },
  {
    id: 'hours',
    label: 'Hours'
  }
];

const AircraftQuotePilotHours = ({ companyId, selectedPilot }) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const [isLoading, setIsLoading] = useState(false);
  const [pilotOptions, setPilotOptions] = useState([]);
  const [recordedTotalHours, setRecordedTotalHours] = useState(0);
  const [tempTotalHours, setTempTotalHours] = useState(0);
  const [open, setOpen] = useState(false);
  const [openHour, setOpenHour] = useState(false);
  const [totalHours, setTotalHours] = useState(selectedPilot?.totalHours);

  const handleClickOpenDialog = () => {
    setOpen(true);
    setTempTotalHours(recordedTotalHours);
  };

  const handleClosedialog = () => {
    setOpen(false);
    setOpenHour(false);
  };

  const handleClickOpenHoursDialog = () => {
    setOpenHour(true);
  };

  const handleSave = async (dialog) => {
    const response = await axios.post(`/api/pilotOption/companyId/${companyId}`, {
      pilotOption: {
        description: dialog?.description,
        hours: +dialog?.hours,
        idPilot: +selectedPilot.id
      }
    });
    setPilotOptions([...pilotOptions, response.data]);
    handleClosedialog();
  };

  const handleTotalHourSave = async (dialogHours) => {
    setIsLoading(true);
    const { PilotCerts, PilotRating } = selectedPilot;
    await axios.put(`/api/pilots/${selectedPilot.id}/companyId/${companyId}`, {
      pilot: {
        totalHours: +dialogHours
      },
      pilotCerts: PilotCerts.map((item) => item.idOption),
      pilotRatings: PilotRating.map((item) => item.idOption)
    });
    setTotalHours(dialogHours);
    handleClosedialog();
    setIsLoading(false);
  };

  useEffect(() => {
    const getPilotHours = async () => {
      setIsLoading(true);
      const response = await axios.get(`/api/pilotOption/pilotId/${selectedPilot.id}/companyId/${companyId}`);
      setPilotOptions([...response.data]);
      setRecordedTotalHours(response.data.reduce((total, next) => total + (next?.hours ? +next?.hours : 0), 0));
      setIsLoading(false);
    };
    getPilotHours();
  }, [selectedPilot, companyId]);

  return (
    <>
      <CustomTable
        isLoading={isLoading}
        columns={columns}
        data={pilotOptions}
        hasActions={false}
        hasPagination={false}
        title={`Pilot Hours - ${selectedPilot?.firstname} ${selectedPilot?.lastname} : ${totalHours || 0} hours total`}
        hasCreateButton={true}
        handleOpenCreate={handleClickOpenDialog}
        hasUpdateHours={true}
        handleUpdateHours={handleClickOpenHoursDialog}
      />
      <PilotOptionDialog
        open={open}
        handleClosedialog={handleClosedialog}
        tempTotalHours={tempTotalHours}
        totalHours={totalHours || 0}
        handlePilotOptionSave={handleSave}
        actionType="CreateWorkflow"
        pilotOption={{}}
      />
      <UpdatePilotHours open={openHour} hours={selectedPilot.totalHours} handleClosedialog={handleClosedialog} handleSave={handleTotalHourSave} />
    </>
  );
};

export default AircraftQuotePilotHours;
