// import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// For right now have to use this instead of the the initialized one since this one adds a a needed
import axiosInstance from 'utils/axios';
import useAuth from 'hooks/useAuth';
import jwtDecode from 'jwt-decode';
import * as actionTypes from 'store/actions';

let code;

const GoogleLinkCallBack = () => {
  const navigate = useNavigate();
  const jwtContext = useAuth();
  const dispatchSnack = useDispatch();
  const axios = axiosInstance(jwtContext);
  const getAuthTokens = async () => {
    const queryParameters = new URLSearchParams(window.location.search);
    code = queryParameters.get('code') || '';
    console.log('code: ', code);
    if (code !== '') {
      const response = await axios.get(`/api/user/googleLink/callback?code=${code}`);

      console.log('response: ', response);
      const { token, msg } = response.data;
      const decoded = jwtDecode(token);
      console.log('decoded', decoded);
      if (token && decoded && decoded.user && decoded.user.email) {
        localStorage.setItem('jwt_token', token);
        //Calling login with data
        console.log('Calling update google with data');
        await jwtContext.loginWithData(decoded);
        dispatchSnack({
          type: actionTypes.SNACKBAR_OPEN,
          open: true,
          message: msg,
          anchorOrigin: { vertical: 'top', horizontal: 'center' }
        });
        navigate(`/user/${decoded.user.id}/settings`);
      } else {
        navigate('/pages/error/error1', { state: { message } });
      }
    } else {
      navigate('/', { replace: true });
    }
  };
  getAuthTokens();
};

export default GoogleLinkCallBack;
