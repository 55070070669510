const { View, Text, StyleSheet, Font } = require('@react-pdf/renderer');

// PDF styles

const styles = StyleSheet.create({
  header: {
    fontSize: 16,
    textAlign: 'center',
    backgroundColor: '#d3d3d3'
  },
  divider: {
    borderTop: '3px solid black'
  }
});

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
});

export const SubjectHeader = ({ title }) => {
  return (
    <View style={styles.header}>
      <Text style={styles.divider} />
      <Text style={styles.boldText}>{title}</Text>
      <Text style={styles.divider} />
    </View>
  );
};
