import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
  Slide,
  MenuItem,
  Button
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { states } from 'utils/states';
import { gridSpacing } from 'config.js';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
const CustomerOrgDialog = ({ companyId, actionType, open, handleClosedialog, handleCustomerSave, customer }) => {
  const [newCustomer, setNewCustomer] = useState({});
  const [listOfTypes, setListOfTypes] = useState([]);
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewCustomer({
      ...newCustomer,
      [name]: value
    });
  };

  const handleSave = () => {
    handleCustomerSave(newCustomer);
  };

  const handleClose = () => {
    setNewCustomer({});
    handleClosedialog();
  };
  const saveInput = React.createRef();

  useEffect(() => {
    if (Object.values(customer).length > 0) {
      const propCustomer = { ...customer };
      setNewCustomer(propCustomer);
    }
  }, [customer]);

  useEffect(() => {
    axios.get(`api/options/companyId/${companyId}?category=OrgType`).then((response) => {
      setListOfTypes(response.data);
    });
  }, [companyId]);
  return (
    <Dialog
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          saveInput.current.click();
        }
      }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'flex-end',
          '& .MuiDialog-paper': {
            m: 0,
            borderRadius: 0,
            maxWidth: 450,
            maxHeight: '100%',
            height: '100vh'
          }
        }
      }}
    >
      <DialogTitle>
        {actionType === 'Create'
          ? 'New Customer/Org'
          : actionType === 'Edit'
          ? 'Edit Customer/Org'
          : actionType === 'Delete'
          ? 'Delete Customer/Org'
          : ''}
      </DialogTitle>
      <DialogContent>
        {actionType !== 'Delete' ? (
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                name="name"
                label="Company Name"
                variant="outlined"
                onChange={handleChange}
                value={newCustomer.name || ''}
                error={!newCustomer?.name}
                helperText={!newCustomer?.name ? 'Cannot be Empty' : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                value={newCustomer.orgType || ''}
                select
                onChange={handleChange}
                name="orgType"
                label="Organization Types"
                error={!newCustomer.orgType}
              >
                {listOfTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.val}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <DialogTitle>Add Address</DialogTitle>
            <Grid item xs={12} className="textFieldUnderTitle">
              <TextField
                fullWidth
                name="address1"
                label="Address 1"
                variant="outlined"
                value={newCustomer?.address1 || ''}
                onChange={handleChange}
              />
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                name="address2"
                label="Address 2"
                variant="outlined"
                value={newCustomer.address2 || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <TextField
                  sx={{ mt: 1, ml: 3 }}
                  fullWidth
                  name="city"
                  label="City"
                  variant="outlined"
                  value={newCustomer.city || ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  sx={{ mt: 1, ml: 1.5 }}
                  fullWidth
                  value={newCustomer.state || ''}
                  onChange={handleChange}
                  select
                  name="state"
                  label="state"
                >
                  {states.map((state, index) => (
                    <MenuItem key={index} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  name="zip"
                  label="Zipcode"
                  variant="outlined"
                  value={newCustomer.zip || ''}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <DialogContentText>Confirm if you want to delete this Customer or Organizaition</DialogContentText>
        )}
      </DialogContent>
      <DialogActions className="actions">
        <Button
          ref={saveInput}
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={actionType !== 'Delete' ? !newCustomer?.name || !newCustomer.orgType : false}
        >
          {actionType !== 'Delete' ? 'Save' : 'Delete'}
        </Button>
        <Button variant="text" onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerOrgDialog;
