// react Imports
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Mui Imports
import { Grid, Typography, Card, Box, Tab, Tabs, IconButton, Menu, MenuItem, Hidden } from '@mui/material';
import Breadcrumb from 'component/Breadcrumb';
import { styled } from '@mui/material/styles';

// Local Imports
import { gridSpacing } from 'config.js';

//Icons
import LiabilityIcon from '@mui/icons-material/HealthAndSafety';
import HullIcon from '@mui/icons-material/Flight';
import MedicalIcon from '@mui/icons-material/MedicalServices';
import TerritoryIcon from '@mui/icons-material/Public';
import WarTriaIcon from '@mui/icons-material/Security';
import UseIcon from '@mui/icons-material/FlightTakeoff';
import PolicyOptionAircraftComponent from 'component/PolicyOptionAircraft';
import MenuIcon from '@mui/icons-material/Menu';

const AccountTabs = styled((props) => <Tabs {...props} />)(() => ({
  marginBottom: '24px',
  borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
  '.MuiButtonBase-root': {
    minWidth: '120px',
    minHeight: 'auto',
    '.MuiTouchRipple-root': {
      flexDirection: 'row',
      '.MuiSvgIcon-root': {
        marginRight: '10px',
        marginBottom: '0 !important'
      }
    }
  }
}));

function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const PolicyOptionAircraft = () => {
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDrawerOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDrawerClose = () => {
    setAnchorEl(null);
  };

  const handleMenuChange = (value) => {
    setValue(value);
    setAnchorEl(null);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumb title="Policy Option Aircraft" divider={false} sx={{ mt: { lg: 0, xs: 2 } }}>
            <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
              Home
            </Typography>
            <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
              Policy Option Aircraft
            </Typography>
          </Breadcrumb>
        </Grid>
      </Grid>
      <Grid item lg={8} xs={12}>
        <Grid container spacing={gridSpacing} xs={12} item sx={{ flexWrap: { lg: 'nowrap', sm: 'wrap' } }} className="grid-container">
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Hidden smUp>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start">
                  <MenuIcon sx={{ fontSize: '1.75rem' }} />
                </IconButton>
              </Grid>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleDrawerClose}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  horizontal: 'center',
                  vertical: 'top'
                }}
                PaperProps={{
                  style: {
                    width: '200px'
                  }
                }}
                sx={{ mt: 1 }}
              >
                <MenuItem sx={{ gap: '8px', fontSize: '12px' }} selected={value === 0} onClick={() => handleMenuChange(0)}>
                  <HullIcon /> Hull
                </MenuItem>
                <MenuItem sx={{ gap: '8px', fontSize: '12px' }} selected={value === 1} onClick={() => handleMenuChange(1)}>
                  <LiabilityIcon /> Liability
                </MenuItem>
                <MenuItem sx={{ gap: '8px', fontSize: '12px' }} selected={value === 2} onClick={() => handleMenuChange(2)}>
                  <MedicalIcon /> Medical
                </MenuItem>
                <MenuItem sx={{ gap: '8px', fontSize: '12px' }} selected={value === 3} onClick={() => handleMenuChange(3)}>
                  <TerritoryIcon /> Territory
                </MenuItem>
                <MenuItem sx={{ gap: '8px', fontSize: '12px' }} selected={value === 4} onClick={() => handleMenuChange(4)}>
                  <UseIcon /> Use
                </MenuItem>
                <MenuItem sx={{ gap: '8px', fontSize: '12px' }} selected={value === 5} onClick={() => handleMenuChange(5)}>
                  <WarTriaIcon /> WarTria
                </MenuItem>
              </Menu>
            </Hidden>

            <Hidden smDown>
              <AccountTabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                aria-label="simple tabs example"
              >
                <Tab icon={<HullIcon />} iconPosition="start" label="Hull" {...a11yProps(0)} />
                <Tab icon={<LiabilityIcon />} iconPosition="start" label="Liability" {...a11yProps(1)} />
                <Tab icon={<MedicalIcon />} iconPosition="start" label="Medical" {...a11yProps(2)} />
                <Tab icon={<TerritoryIcon />} iconPosition="start" label="Territory" {...a11yProps(3)} />
                <Tab icon={<UseIcon />} iconPosition="start" label="Use" {...a11yProps(0)} />
                <Tab icon={<WarTriaIcon />} iconPosition="start" label="WarTria" {...a11yProps(5)} />
              </AccountTabs>
            </Hidden>
            <TabPanel value={value} index={0}>
              <Card>
                <PolicyOptionAircraftComponent option="Hull" />
              </Card>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Card>
                <PolicyOptionAircraftComponent option="Liability" />
              </Card>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Card>
                <PolicyOptionAircraftComponent option="Medical" />
              </Card>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Card>
                <PolicyOptionAircraftComponent option="Territory" />
              </Card>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Card>
                <PolicyOptionAircraftComponent option="Use" />
              </Card>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Card>
                <PolicyOptionAircraftComponent option="WarTria" />
              </Card>
            </TabPanel>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PolicyOptionAircraft;
