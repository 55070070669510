import { CardContent, Grid, Typography, TextField } from "@mui/material";
import { gridSpacing } from "config.js";
const CompanyUserDetails = ({ policy }) => {
  return (
    <CardContent>
      <Grid container spacing={gridSpacing}>
        <Grid item sm zeroMinWidth>
          <Typography component="div" variant="h5">
            Company User
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item lg={6} xs={12}>
              <Typography variant="subtitle2">
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Display Name"
                  variant="outlined"
                  value={policy?.companyUserDispayName || ''}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Typography>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Typography variant="subtitle2">
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Email"
                  variant="outlined"
                  value={policy?.companyUserEmail || ''}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Typography>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Typography variant="subtitle2">
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="First Name"
                  variant="outlined"
                  value={policy?.companyUserFirstName || ''}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Typography>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Typography variant="subtitle2">
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Last Name"
                  variant="outlined"
                  value={policy?.companyUserLastName || ''}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default CompanyUserDetails;
