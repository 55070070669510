import React, { useState } from 'react';
import { useNavigate } from 'react-router';
// material-ui
import { useTheme } from '@mui/material/styles';
import { alpha, Box, Button, Popper, Fade, Card, CardContent, Grid, Autocomplete, TextField, InputAdornment } from '@mui/material';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';

// assets
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';

const PopperMy = function (props) {
  return (<Popper {...props} sx={{width: '100%'}} placement='bottom-start' disablePortal={true} />)
}
// ==============================|| SEARCH SECTION ||============================== //

const SearchSection = () => {
  const theme = useTheme();
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [mobileInput, setMobileInput] = useState('');
  const [abortController, setAbortController] = useState(null);

  const fetchData = async (newValue, controller) => {
    try {
      const response = await axios.get('/api/search', {
        signal: controller.signal,
        params: {
          search: newValue
        }
      });
      const policies = response.data[0]?.map((item) => ({ optionType: 'Policy', ...item }));
      const aircrafts = response.data[1]?.map((item) => ({ optionType: 'Aircraft', ...item }));
      const pilots = response.data[2]?.map((item) => ({ optionType: 'Pilot', ...item }));
      const customerOrg = response.data[3]?.map((item) => ({ optionType: 'Organization', ...item }));
      if (policies.length > 0 || aircrafts.length > 0 || pilots.length > 0 || customerOrg.length > 0) {
        setOptions([...aircrafts, ...policies, ...pilots, ...customerOrg]);
      }
    } catch (error) {
      if (error?.name === 'AbortError') {
        console.log('Request aborted:', error.message);
      } else {
        console.log(error);
        console.error('Request Aborted');
      }
    }
  };

  const handleChange = async (event, newValue) => {
    try {
      if (newValue?.length === 3) {
        const controller = new AbortController();
        fetchData(newValue, controller);
        setAbortController(controller);
      } else if (newValue?.length > 3) {
        if (abortController) {
          abortController.abort();
        }
        const controller = new AbortController();
        fetchData(newValue, controller);
        setAbortController(controller);
      } else if (newValue?.length < 3) {
        setOptions([]);
      }
      setInputValue(newValue);
    } catch (err) {
      console.log(err);
    }
  };

  const handleMobileChange = async (event, newValue) => {
    try {
      if (newValue?.length === 3) {
        const controller = new AbortController();
        fetchData(newValue, controller);
        setAbortController(controller);
      } else if (newValue?.length > 3) {
        if (abortController) {
          abortController.abort();
        }
        const controller = new AbortController();
        fetchData(newValue, controller);
        setAbortController(controller);
      } else if (newValue?.length < 3) {
        setOptions([]);
      }
      setMobileInput(newValue);
    } catch (err) {
      console.log(err);
    }
  };

  const handleBlur = () => {
    setOptions([]);
  };

  const handleClick = (value) => {
    switch (value.optionType) {
      case 'Policy': {
        navigate(`/company/${value?.CustomerOrganization?.idCompany}/policy/${value.idImmutable}`);
        break;
      }
      case 'Aircraft': {
        navigate(`/company/${value?.CustomerOrganization?.idCompany}/aircrafts/${value.id}`);
        break;
      }
      case 'Pilot': {
        navigate(`/company/${value?.CustomerOrganization?.idCompany}/pilots/${value.id}`);
        break;
      }
      case 'Organization': {
        navigate(`/company/${value?.idCompany}/customer-org/${value.id}`);
        break;
      }
      default: {
        navigate('/dashboard/default');
        break;
      }
    }
  };
  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: 1,
        backgroundColor: { sm: alpha(theme.palette.common.white, 0.15), xs: 'transparent' },
        '&:hover': {
          backgroundColor: { sm: alpha(theme.palette.common.white, 0.25), xs: 'transparent' }
        },
        ml: { sm: theme.spacing(1), xs: 0 },
        mr: { sm: theme.spacing(2), xs: 0 },
        width: 'auto'
      }}
    >
      <Box sx={{ display: { sm: 'none', xs: 'block' } }}>
        <PopupState variant="popper" popupId="demo-popup-popper">
          {(popupState) => (
            <>
              <Button
                sx={{
                  minWidth: { xs: 35 }
                }}
                aria-haspopup="true"
                {...bindToggle(popupState)}
                color="inherit"
              >
                <SearchTwoToneIcon sx={{ fontSize: '1.5rem' }} />
              </Button>
              <Popper
                {...bindPopper(popupState)}
                transition
                sx={{ zIndex: 1201, width: '100%', top: '100%', p: 0 }}
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 10]
                    }
                  },
                  {
                    name: 'preventOverflow',
                    options: {
                      altAxis: true
                    }
                  }
                ]}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Card
                      sx={{
                        borderRadius: 0,
                        background: theme.palette.primary[200],
                        border: { sm: 0 },
                        boxShadow: { sm: 'none' },
                      }}
                    >
                      <CardContent sx={{ p: 1.5, backgroundColor: theme.palette.secondary.main }}>
                        <Grid container alignItems="center" justifyContent="space-between">
                          <Grid item xs>
                            <Box display="flex" p={0}>
                              <Autocomplete
                                fullWidth
                                options={options}
                                getOptionLabel={(option) =>
                                  `${option.optionType}: ${option.id}-${
                                    (option.optionType === 'Policy' && option?.policyNumber) ||
                                    (option.optionType === 'Aircraft' && option?.registrationNo) ||
                                    (option.optionType === 'Pilot' && `${option.firstname} ${option.lastname}`) ||
                                    (option.optionType === 'Organization' && `${option.name}`)
                                  }`
                                }
                                value={null} // Set the default value
                                inputValue={mobileInput || ''}
                                onInputChange={(event, newInputValue) => handleMobileChange(event, newInputValue)}
                                onChange={(e, value) => handleClick(value)}
                                onBlur={handleBlur} // Set the default value
                                PopperComponent={PopperMy}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    inputProps={{
                                      ...params.inputProps,
                                      min: 0,
                                      step: 0.01,
                                      style: {
                                        color: 'white'
                                      }
                                    }}
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <SearchTwoToneIcon />
                                        </InputAdornment>
                                      ),
                                      sx: {
                                        width: '100%',
                                      }
                                    }}
                                    placeholder="Search...."
                                  />
                                )}
                              />
                              <Box
                                sx={{
                                  cursor: 'pointer',
                                  p: theme.spacing(1.25),
                                  alignItems: 'center',
                                  justifyContent: 'flex-end',
                                  display: 'flex',
                                  color: theme.palette.grey[100]
                                }}
                                {...bindToggle(popupState)}
                              >
                                <CloseTwoToneIcon color="inherit" />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Fade>
                )}
              </Popper>
            </>
          )}
        </PopupState>
      </Box>
      <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
        <Autocomplete
          options={options}
          getOptionLabel={(option) =>
            `${option.optionType}: ${option.id}-${
              (option.optionType === 'Policy' && option?.policyNumber) ||
              (option.optionType === 'Aircraft' && option?.registrationNo) ||
              (option.optionType === 'Pilot' && `${option.firstname} ${option.lastname}`) ||
              (option.optionType === 'Organization' && `${option.name}`)
            }`
          }
          value={null} // Set the default value
          inputValue={inputValue || ''}
          onInputChange={(event, newInputValue) => handleChange(event, newInputValue)}
          onChange={(e, value) => handleClick(value)}
          onBlur={handleBlur}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                min: 0,
                step: 0.01
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                ),
                sx: {
                  '& .MuiInputBase-root': {
                    color: 'inherit'
                  },
                  '& .MuiInputBase-input': {
                    padding: 1,
                    paddingLeft: `calc(1em + ${1})`,
                    transition: 'width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
                    color: '#fff',
                    width: '100%',
                    '&:focus': {
                      width: 225
                    }
                  }
                }
              }}
              placeholder="Search...."
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default SearchSection;
