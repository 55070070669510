export const deactiveArrayUtil = (array, item, type = 'id') => {

  // Check if the array contains an item with the same type value
  const itemExists = array.some((obj) => obj[type] === item[type]);

  if (!itemExists && item.id !== undefined) {
    const newArray = [...array, item];
    return newArray;
  }

  // Check if all items in the array have the 'idActive' property
  const allHaveIdActive = array.every((item) => 'idActive' in item);

  if (allHaveIdActive) {
    const filteredArray = array.filter((item) => item.idActive);
    return filteredArray;
  }

  // Return the original array
  return array;
};