import { Dialog, DialogActions, DialogTitle, Grid, DialogContent, DialogContentText, TextField, Button, Input, Slide } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { validRegex, phoneNumberRegex } from 'utils/FormatUtils';
import { gridSpacing } from 'config.js';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const CustomerContactDialog = ({ open, handleClosedialog, actionType, contact, handleContactSave }) => {
  const [newContact, setNewContact] = useState({});
  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewContact({
      ...newContact,
      [name]: value
    });
  };

  const handleClose = () => {
    setNewContact({});
    handleClosedialog();
  };

  const handleSave = () => {
    handleContactSave(newContact);
  };
  const saveInput = React.createRef();

  useEffect(() => {
    if (Object.values(contact).length > 0) {
      const propContact = { ...contact };
      setNewContact(propContact);
    }
  }, [contact]);
  
  return (
    <Dialog
      onKeyUp={(e) => {if (e.key === 'Enter') { saveInput.current.click();}}}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'flex-end',
          '& .MuiDialog-paper': {
            m: 0,
            borderRadius: 0,
            maxWidth: 450,
            maxHeight: '100%',
            height: '100vh'
          }
        }
      }}
    >
      <DialogTitle>
        {actionType === 'Create' ? 'New Contact' : actionType === 'Edit' ? 'Edit Contact' : actionType === 'Delete' ? 'Delete Contact' : ''}
      </DialogTitle>
      <DialogContent>
        {actionType !== 'Delete' ? (
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                label="Name"
                name="name"
                variant="outlined"
                value={newContact.name || ''}
                error={!newContact?.name}
                helperText={!newContact?.name ? 'Cannot be Empty' : ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                variant="outlined"
                input={<Input />}
                value={newContact.email || ''}
                error={newContact?.email?.length > 0 ? !newContact?.email?.match(validRegex) : false}
                helperText={newContact?.email?.length > 0 ? (!newContact?.email?.match(validRegex) ? 'Invalid Emial' : '') : ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Phone"
                name="phone"
                variant="outlined"
                value={newContact.phone || ''}
                error={newContact?.phone?.length > 0 ? !newContact?.phone?.match(phoneNumberRegex) : false}
                helperText={
                  newContact?.phone?.length > 0 ? (!newContact?.phone?.match(phoneNumberRegex) ? 'Invalid Phone Number' : '') : ''
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="Title" name="title" variant="outlined" value={newContact.title || ''} onChange={handleChange} />
            </Grid>
          </Grid>
        ) : (
          <DialogContentText>Confirm if you want to delete the this Contact</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
        ref={saveInput}
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={
            actionType !== 'Delete'
              ? (newContact?.email?.length > 0 ? !newContact?.email?.match(validRegex) : false) ||
                (newContact?.phone?.length > 0 ? (!newContact?.phone?.match(phoneNumberRegex) ? 'Invalid Phone Number' : '') : '') ||
                !newContact?.name
              : false
          }
        >
          {actionType !== 'Delete' ? 'Save' : 'Delete'}
        </Button>
        <Button variant="text" onClick={handleClosedialog} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerContactDialog;
