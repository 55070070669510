import React from 'react';
import { lazy } from 'react';

// project import
import Loadable from 'component/Loadable';
import GuestGuard from 'component/Auth/GuestGuard';
import InviteGuard from 'component/Auth/InviteGuard';

import NavMotion from 'layout/NavMotion';
import MinimalLayout from 'layout/MinimalLayout';
import ConfirmForgotPassword from 'views/ConfirmForgotPassword';

const HomeScreen = Loadable(lazy(() => import('../views/Home')));
const InviteReqister = Loadable(lazy(() => import('../views/InviteView')));
// const AuthRegister = Loadable(lazy(() => import('../views/Register')));
const AuthForgotPassword = Loadable(lazy(() => import('../views/ForgotPassword')));
const GoogleCallBack = Loadable(lazy(() => import('../views/Login/googleCallBack')));

// ==============================|| LOGIN ROUTES ||============================== //

const LoginRoutes = {
  path: '/',
  element: (
    <NavMotion>
      <GuestGuard>
        <InviteGuard>
          <MinimalLayout />
        </InviteGuard>
      </GuestGuard>
    </NavMotion>
  ),
  children: [
    {
      path: '/',
      element: <HomeScreen />
    },
    {
      path: '/invite',
      element: <InviteReqister />
    },
    // {
    //   path: '/register',
    //   element: <AuthRegister />
    // },
    { path: '/auth/google/callback', element: <GoogleCallBack /> },

    {
      path: '/forgot-password',
      element: <AuthForgotPassword />
    },
    {
      path: '/confirm-forgot-password',
      element: <ConfirmForgotPassword />
    }
  ]
};

export default LoginRoutes;
