import AircraftDialog from 'component/AircraftDialog';
import CustomTable from 'component/CustomTable';
import LoadingOverlay from 'component/LoadingOverlay';
import useAuth from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';
import initializeAxios from 'utils/axios';
const columns = [
  {
    id: 'registrationNo',
    label: 'Registration Number'
  },
  {
    id: 'airportCode',
    label: 'Airport Code'
  }
];
const AircraftQuoteCoverageOptions = ({ companyId, selectedAircrafts, selectedPolicy, emitCoverage}) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPolicyAircraft, setIsPolicyAircraft] = useState(false);
  const [activeAircraft, setActiveAircraft] = useState({});
  const [aircrafts, setAircrafts] = useState([]);

  const handleSave = async (aircraftDialog) => {
    setIsLoading(true);
    const tempAircraft = { ...aircraftDialog.aircraft, idAircraft: activeAircraft.id };
    await axios.post(`/api/policy/${selectedPolicy.id}/aircraft/companyId/${companyId}`, {
      aircraft: {
        ...tempAircraft
      }
    });
    const tempArray = [...aircrafts];
    const index = tempArray.findIndex((e) => e.id === activeAircraft.id);
    tempArray.splice(index, 1);
    if (tempArray?.length === 0) {
      emitCoverage(6, true);
    }
    setAircrafts(tempArray);
    handleClosedialog();
    setIsLoading(false);
  };

  const handleClosedialog = () => {
    setOpen(false);
    setActiveAircraft({});
  };

  const handleSelect = (row) => {
    setActiveAircraft({ ...row });
    setOpen(true);
  };

  useEffect(() => {
    setAircrafts(selectedAircrafts);
    setIsPolicyAircraft(true);
  }, [selectedAircrafts]);
  return (
    <>
      <LoadingOverlay loading={isLoading} />
      <AircraftDialog
        isPolicyAircraft={isPolicyAircraft}
        actionType="Details"
        aircraft={{idAircraft: activeAircraft.id, actionType: 'Edit'}}
        open={open}
        handleClosedialog={handleClosedialog}
        handleAircraftSave={handleSave}
      />
      <CustomTable
        title="Coverage Options"
        columns={columns}
        data={aircrafts}
        isLoading={false}
        hasClickableRows
        emitClickedRow={handleSelect}
        hasActions={false}
      />
    </>
  );
};

export default AircraftQuoteCoverageOptions;
